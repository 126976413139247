<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.componentTypeId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un type de composant
                            </h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Type de composant</legend>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom<abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.name.$error}"
                                                       v-model='detail.name' >
                                                <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                    un nom</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ABBREVIATION -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Abbréviation</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.shortName' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select class='form-control'
                                                        v-model='detail.type'
                                                        required>
                                                    <option :value="null"></option>
                                                    <option value="INT">INT</option>
                                                    <option value="EXT">EXT</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- NOM ANGLAIS -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom anglais</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.englishName' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMMENTAIRE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Commentaire</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.comment' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- QUALIREPARE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Qualirepare</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='qualirepare'
                                                               :id="'qualirepare_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.isQualirepare'>
                                                        <label class='custom-control-label'
                                                               :for="'qualirepare_'+s.value"
                                                               :class="detail.isQualirepare === s.value ? s.class : ''">{{ s.label2 }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid'>
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-end'>
                                    <div class='col-auto'>
                                        <button v-if='!loading && detail.componentId && modification'
                                                class='btn--color-success'
                                                @click='createComponentType'>
                                            <span>Créer un nouveau type de composant</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-add />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-add />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ComponentTypeDetail',
    components: {
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            placeTypes: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des types de composant', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des types de composant', 'Lecture');
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/component-type');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
        if (!this.lecture)
            this.$router.push('/admin/component-type');
    },
    methods: {
        createNewDetail() {
            this.detail = {
                componentTypeId: null,
                name: null,
                shortName: null,
                type: null,
                englishName: null,
                status: true,
                comment: null,
                isQualirepare: false
            };
        },
        createComponentType() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/componentType/${this.$route.params.id}`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/componentType`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.componentTypeId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                name: { required },
                status: { required }
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
