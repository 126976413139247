<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card h-100 shadow-sm">
                <div class="card-body d-flex flex-column h-100">
                    <h3 class="card-title"
                        v-if='lecture'>Liste des composants</h3>
                    <div class="flex-fill"
                         :hidden="!lecture">
                        <table
                            ref="table"
                            class="table table-striped table-hover table-handyRows"></table>
                    </div>
                    <notif type="error"
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if="!lecture"/>
                </div>
                <div class="card-footer">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <notif type="error"
                                   text="Un problème est survenu pendant la création du composant"
                                   v-if="error"/>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto is-animated d-flex">
                            <button v-if="!loading && modification"
                                    class="btn--color-success"
                                    @click="createComponent">
                                <span>Créer un nouveau composant</span>
                                <svg-add/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { DateTime } from 'luxon';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-datetime/dist/dataTables.dateTime';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';


export default {
    name: 'ComponentList',
    components: {
        ...svgComponent,
        notif,
    },
    data() {
        return {
            columns: [
                {
                    data: 'componentId',
                    title: 'id',
                }, {
                    data: 'ugs',
                    title: 'UGS',
                },{
                    data: 'manufacturerRef',
                    title: 'Référence fabricant',
                },{
                    data: 'image',
                    title: 'Image face',
                    render: (data) => {
                        return data ? `<img src="${data}" style="max-height: 48px"/>` : null;
                    }
                }, {
                    data: 'componentTypeName',
                    title: 'Type composant',
                },{
                    data: 'componentQualityName',
                    title: 'Qualité',
                }, {
                    data: 'models',
                    title: 'Modèle',
                    render: (data) => {
                        let res = '';
                        data.forEach(mod => {
                            if (mod && mod.name)
                                res += `<span class="multiselect__tag" style="font-size: 12px">${mod.name}</span>`;
                        });
                        return res;
                    }
                }, {
                    data: 'price',
                    title: 'Prix HT',
                    render: (data) =>{
                        return data === null ? 0 : data;
                    }
                },
                {
                    data: 'supplierPrice',
                    title: 'Prix fournisseur',
                    render: (data) =>{
                        return data === null ? 0 : data;
                    }
                },{
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class="${ data ? this.status[data].class : this.status['false'].class}">${ data ? this.status[data].label : this.status['false'].label}</span>`;
                    }
                }, {
                    data: 'colorName',
                    title: 'Couleur',
                }
                , {
                    data: 'capacity',
                    title: 'Capacité',
                },{
                    data: 'tools',
                    title: 'Outillage',
                    render: (data) => {
                        return `<span class="${ data ? this.status[data].class : this.status['false'].class}">${ data ? this.status[data].label2 : this.status['false'].label2}</span>`;
                    }
                },{
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    }
                }
            ],
            rows: [],
            status: {
                'true': {
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success'
                },
                'false': {
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger'
                }
            },
            error: false,
            loading: false,

            table: null,
            lecture: false,
            modification: false,
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des composants', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des composants', 'Lecture');
        await this.getList();
    },
    methods: {
        async getList() {
            try {
                this.loader.show('load');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/components`
                );
                this.rows = res.data;
                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
                this.error = true;
            } finally {
                this.loader.hide('load');
            }
        },
        goTo(component) {
            this.$router.push(`component/${component.componentId}`);
        },
        createComponent() {
            this.$router.push("component/create");
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                buttons: ['searchBuilder'],
                deferRender: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageResize: true,
                pageLength: 100,
                scrollY: 100,
                scrollX: true,
                stateSave: true,
                draw: 'page',
                order: [[13, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bfltpi',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo(row);
                        }
                    });
                },
            };
            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            authStore : useAuthStore(),
            loader: useLoader()
        };
    }
};
</script>

<style scoped>
/*.table-hover tr {*/
/*    cursor: pointer;*/
/*}*/
</style>
