<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-8'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.goodiesId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>Création d'un goodies</h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Goodies</legend>
                                    <!-- TYPE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    :class="{'is-invalid': v$.detail.goodiesTypeId.$error}"
                                                    v-model='detail.goodiesTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(goodiesType, key) in goodiesTypes'
                                                        :key='key'
                                                        :value='goodiesType.goodiesTypeId'>
                                                    {{ goodiesType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.goodiesTypeId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de goodies
                                            </p>
                                        </div>
                                    </div>
                                    <!-- SEXE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Sexe</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.gender'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option :value="'Femme'">Femme</option>
                                                <option :value="'Homme'">Homme</option>
                                                <option :value="'Mixte'">Mixte</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    :class="{'is-invalid': v$.detail.sign.$error}"
                                                    v-model='detail.sign'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option :value="'BeeMyPhone'">BeeMyPhone</option>
                                                <option :value="'C&R'">C&R</option>
                                            </select>
                                            <p v-if='v$.detail.sign.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner une marque
                                            </p>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Couleur</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.genericColorId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(genericColor, key) in genericColors'
                                                        :key='key'
                                                        :value='genericColor.genericColorId'>
                                                    {{ genericColor.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- TAILLE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Taille</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.size'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option :value="'XS'">XS</option>
                                                <option :value="'S'">S</option>
                                                <option :value="'M'">M</option>
                                                <option :value="'L'">L</option>
                                                <option :value="'XL'">XL</option>
                                                <option :value="'XXL'">XXL</option>
                                                <option :value="'XXXL'">XXXL</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- LOT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Lot <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='batch'
                                                               :class="{'is-invalid': v$.detail.batch.$error}"
                                                               :id="'batch_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.batch'>
                                                        <label class='custom-control-label'
                                                               :for="'batch_'+s.value"
                                                               :class="detail.batch === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.batch.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez indiquer si le goodies se vend par lot
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- QUANTITE / LOT -->
                                    <div class='row form-group' v-if="detail.batch">
                                        <label class='col-3 col-form-label'>Quantité par lot</label>
                                        <div class='col-6'>
                                            <div class="col-sm-12 p-0">
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.batchQuantity' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SECONDE VIE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Seconde vie <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='second_life'
                                                               :class="{'is-invalid': v$.detail.secondLife.$error}"
                                                               :id="'secondLife_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.secondLife'>
                                                        <label class='custom-control-label'
                                                               :for="'secondLife_'+s.value"
                                                               :class="detail.secondLife === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.secondLife.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez indiquer si le goodies est de seconde vie
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- UGS -->
                                    <div class="row form-group">
                                        <label class='col-3 col-form-label'>UGS</label>
                                        <div class='col-6'>
                                            <div class="col-sm-12 p-0">
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.ugs' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PRIX -->
                                    <div class="row form-group">
                                        <label class='col-3 col-form-label'>Prix <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class="col-sm-12 p-0">
                                                <input type='text'
                                                       :class="{'is-invalid': v$.detail.price.$error}"
                                                       class='form-control'
                                                       v-model='detail.price' >
                                                <p v-if='v$.detail.price.$error' class='invalid-feedback'>
                                                    Veuillez saisir un prix</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class="col-auto">
                                        <button v-if="!loading && modification"
                                                class="btn--color-success"
                                                @click="createGoodies">
                                            <span>Créer un nouveau goodies</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image && !image.error'>
                                    <img class='img-fluid' :src='detail.image' >
                                </div>
                                <div class='col-auto' v-if='!detail.image || image.error'>
                                    <notif type='error'
                                           :text='image.errorText'/>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-end'>
                                <div class='col-auto is-animated d-flex'>
                                    <input type='file' id='fileUpload' @change='handleImage' hidden >
                                    <button v-if='!image.loading && modification'
                                            class='btn--outline'
                                            @click='selectFile'>
                                        <span>Modifier l'image</span>
                                        <svg-download />
                                    </button>
                                    <button v-if='image.loading'
                                            class=''
                                            type='button'
                                            disabled>
                                        <span>Upload en cours...</span>
                                        <figure class='spin--infinite ml-2'>
                                            <svg-download />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>
                                <small>Format: JPG/PNG - Max: 300Ko</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import notif from '@/components/Notif.vue';
import svgComponent from '../../_svg/svgComponents';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/stores/auth.store';

export default defineComponent({
    name: 'GoodiesDetail',
    components: { notif, ...svgComponent },
    data() {
        return {
            detail: null,
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            goodiesTypes: [],
            genericColors: [],
            image: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
            },
            stockModal: false,
            movement: {},
            movementTypes: [{ id: 'in', label: 'Entrée' }, { id: 'out', label: 'Sortie' }],
            locations: [],
            columns: [
                {
                    data: 'stockId',
                    title: 'Id',
                    render: function(data) {
                        return data ? '<a href="/admin/stock/' + data + '">' + data + '</a>' : '';
                    }
                }, {
                    data: 'placeName',
                    title: 'Lieu',
                }, {
                    data: 'physicalLocationName',
                    title: 'Emplacement Physique',
                },{
                    data: 'quantity',
                    title: 'Quantité',
                }, {
                    data: 'bookingQuantity',
                    title: 'Réservé',
                }
            ],
            table: null,
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des goodies', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des goodies', 'Lecture');
        await this.getGoodiesType();
        await this.getGenericColor();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/goodies');
            this.createNewDetail();
        } else {
            await this.getDetail();

        }

        if(!this.lecture)
            this.$router.push("/admin/goodies");
    },
    methods: {
        createNewDetail() {
            this.detail = {
                goodiesTypeId: null,
                gender: null,
                batch: false,
                batchQuantity: null,
                size: null,
                secondLife: false,
                sign: null,
                ugs: null,
                price: null,
                genericColorId: null,
                status: true,
                image: null
            };
        },
        createGoodies() {
            this.createNewDetail();
            this.$router.push("create");
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goodies?goodiesId=${this.$route.params.id}`,
                );
                this.detail = res.data[0];
                this.detail.name = `${this.detail.goodiesTypeName} ${this.detail.gender? this.detail.gender :''}
                    ${this.detail.genericColorName ? this.detail.genericColorName : ''}
                    ${this.detail.sign} ${this.detail.size ? ' - '+this.detail.size : ''} ${this.detail.batch ? '(Lot de '+ this.detail.batchQuantity + ')' : ''}`;
            } catch (err) {
                console.log(err);
            }
        },
        async getGoodiesType() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goodiesType`,
                );
                this.goodiesTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGenericColor() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/genericColor`,
                );
                this.genericColors = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        selectFile() {
            document.getElementById('fileUpload').click();
        },
        handleImage(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
            }

            if (img.size > 300000) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadImage(e.target.result);
            };

            reader.readAsDataURL(img);
        },
        async uploadImage(image) {
            this.image.loading = true;
            this.image.error = false;

            try {
                const data = {
                    newImage: image,
                    oldImage: this.detail.image,
                };

                const res = await this.axios.post(`${window.location.origin}/api/crd/goodies/${this.detail.goodiesId}/image`, data);
                this.image.loading = false;
                this.detail.image = res.data.image;
            } catch (err) {
                this.image.error = true;
                this.image.loading = false;
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/goodies`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.detail.goodiesId = res.data.goodiesId;
                    this.goTo(res.data.goodiesId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
            authStore: useAuthStore()
        };
    },
    validations() {
        return {
            detail: {
                $autoDirty: true,
                goodiesTypeId: { required },
                status: { required },
                batch: { required },
                secondLife: { required },
                price: { required },
                sign: { required }
            },
        };
    }
});
</script>

<style scoped>

</style>
