<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.modelId'>
                                {{ detail.name2 }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un model
                            </h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Modèle</legend>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom<abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.name.$error}"
                                                       v-model='detail.name' >
                                                <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                    un nom</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.brandId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(brand, key) in brands'
                                                            :key='key'
                                                            :value='brand.brandId'>
                                                        {{ brand.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.brandId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner une marque
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.productTypeId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(productType, key) in productTypes'
                                                            :key='key'
                                                            :value='productType.productTypeId'>
                                                        {{ productType.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.productTypeId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner un Type d'appareil
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- REF FOURNISSEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Référence fournisseur</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.manufacturerRef' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- DATE SORTIE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Date de Sortie (mois année) <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='month'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.releaseDate.$error}"
                                                       v-model='detail.releaseDate' >
                                                <p style='color: gray'>{{ detail.releaseDate }} </p>
                                                <p v-if='v$.detail.releaseDate.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez saisir la date de sortie du modèle
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- COMMENTAIRE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Commentaire</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.comment' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- NFC -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>NFC</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='nfc'
                                                               :id="'nfc_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.nfc'>
                                                        <label class='custom-control-label'
                                                               :for="'nfc_'+s.value"
                                                               :class="detail.nfc === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- JACK -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Prise jack</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='jack'
                                                               :id="'jack_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.jack'>
                                                        <label class='custom-control-label'
                                                               :for="'jack_'+s.value"
                                                               :class="detail.jack === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PROCESSEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Processeur</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.processor' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TAILLE ECRAN -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Taille écran (pouces)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.screenSize' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- RESOLUTION -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Résolution écran (px)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.screenResolution' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CAMERA AVANT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Caméra avant (MP)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.cameraFront' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CAMERA ARRIERE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Caméra arrière (MP)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.cameraBack' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BATTERIE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Batterie (mAh)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.battery' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- DAS -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>DAS (W/kg)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.das' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- RESEAUX -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Réseaux</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.network' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ESIM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>eSIM</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='esim'
                                                               :id="'esim_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.isEsim'>
                                                        <label class='custom-control-label'
                                                               :for="'esim_'+s.value"
                                                               :class="detail.isEsim === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE CHARGEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type de Chargeur</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select class='form-control'
                                                        v-model='detail.chargerType'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(chargerType, key) in chargerTypes'
                                                            :key='key'
                                                            :value='chargerType.id'>
                                                        {{ chargerType.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- GROUPE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Groupe</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select class='form-control'
                                                        v-model='detail.groupId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(group, key) in groups'
                                                            :key='key'
                                                            :value='group.groupId'>
                                                        {{ group.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- META DESC -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Méta description</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.metaDescription' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BENCHMARK -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Benchmark AnTuTu</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.benchmarkAntutu' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BENCHMARK -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Benchmark DXOMARK photo</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.benchmarkDxomarkPhoto' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BENCHMARK -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Benchmark DXOMARK écran</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.benchmarkDxomarkEcran' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CAPTEUR PHOTO -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nombre de capteur photo</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.photoSensor' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OLED SCREEN -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Ecran OLED</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='oled'
                                                               :id="'oled_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.oledScreen'>
                                                        <label class='custom-control-label'
                                                               :for="'oled_'+s.value"
                                                               :class="detail.oledScreen === s.value ? s.class : ''">{{ s.label2 }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- RAFRAICHISSEMENT ECRAN -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Rafraîchissement écran (Hz)</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.refreshRate' >
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class='col-auto'>
                                        <button v-if='!loading && detail.modelId && modification'
                                                class='btn--color-success'
                                                @click='createModel'>
                                            <span>Créer un nouveau modèle</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'ModelDetail',
    components: {
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            repairTypes: [],
            capacities: [],
            productTypes: [],
            componentTypes: [],
            componentQualities: [],
            groups: [],
            colors: [],
            units: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            chargerTypes :[
                { id:'lightning', label:'Lightning' },
                { id:'usb-c', label:'USB C' },
                { id:'micro-usb', label:'Micro USB' },
                { id:'magnetique', label:'Chargeur magnétique' },
            ]
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des modèles', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des modèles', 'Lecture');
        if (!this.lecture)
            this.$router.push('/admin/model');
        await this.getBrands();
        await this.getProductTypes();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/model');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
        await this.getGroups();
    },
    methods: {
        createNewDetail() {
            this.detail = {
                modelId: null,
                name: null,
                manufacturerRef: null,
                releaseDate: null,
                comment: null,
                status: true,
                brandId: null,
                productTypeId: null,
                processor: null,
                screenSize: null,
                screenResolution: null,
                cameraFront: null,
                cameraBack: null,
                nfc: null,
                jack: null,
                battery: null,
                das: null,
                network: null,
                metaDescription: null,
                isEsim: false,
                chargerType: null,
                groupId: null,
                benchmarkAntutu: null,
                photoSensor: null,
                oledScreen: null,
                refreshRate: null,
                benchmarkDxomarkPhoto: null,
                benchmarkDxomarkEcran: null
            };
        },
        createModel() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/model/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
                this.detail.name2 = (this.detail.brandName + ' ' + this.detail.name).toLowerCase();
                this.detail.name2 = this.detail.name2.charAt(0).toUpperCase() + this.detail.name2.slice(1);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getGroups() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/group`,
                );
                this.groups = res.data.reduce((curr, group) => {
                    if(group.brandId === this.detail.brandId)
                        curr.push(group);
                    return curr;
                },[]);
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });
                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/model`,
                        this.detail,
                    );
                    this.detail.modelId= res.data.modelId;
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.modelId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                name: { required },
                brandId: { required },
                productTypeId: { required },
                status: { required },
                releaseDate: { required }
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
