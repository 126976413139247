import svgIcon from '../components/SvgIcon.vue';
import svgList from './svgList';
import _ from 'lodash';

const componentify = () => {
    return Object.entries(svgList).reduce((comps, [name, svg]) => {
        comps[name] = {
            componentName: _.kebabCase(name),
            raw: svg,
            components: {
                svgIcon
            },
            template: `<svg-icon :icon="svg"></svg-icon>`,
            data() {
                return { svg };
            }
        };

        return comps;
    }, {});
};

export default componentify();
