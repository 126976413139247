<template>
    <div class='backoffice-layout theme-bookstore h-100'>
        <section id='mainContent' class="h-100">
            <div class='container-fluid h-100'>
                <div class="card h-100 shadow-sm">
                    <div class="card-body d-flex flex-column h-100">
                        <h3 class="card-title">Liste des composants </h3>
                        <div class="flex-fill">
                            <table
                                ref="table"
                                class="table table-striped table-hover table-handyRows"></table>
                        </div>
                    </div>
                    <div class="card-footer"  v-if='error'>
                        <div class='row justify-content-center'>
                            <div class='col-auto'>
                                <notif type='success'
                                       text='Sauvegarde effectué'
                                       v-if='success'/>
                                <notif type='error'
                                       text='Un problème est survenu durant la sauvegarde'
                                       :message='msgError'
                                       v-if='error'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal mouvement de stock -->
            <modal title="Mouvement de stock" :display="stockModal" @close="closeModal" >
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <div class="col-12">
                                <fieldset>
                                    <legend>Informations</legend>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Type</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='movement.type'
                                                    required
                                                    disabled>
                                                <option v-for='(movementType, key) in movementTypes'
                                                        :key='key'
                                                        :value='movementType.id'>
                                                    {{ movementType.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Lieu</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='movement.place'
                                                    required
                                                    disabled>
                                                <option v-for='(place, key) in places'
                                                        :key='key'
                                                        :value='place.placeId'>
                                                    {{ place.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!--                                    <div class="form-group row" v-if="movement.place === '2'">-->
                                    <!--                                        <label class='col-3 col-form-label'>Emplacement</label>-->
                                    <!--                                        <div class='col-6'>-->
                                    <!--                                            <select class='form-control'-->
                                    <!--                                                    v-model='movement.location'-->
                                    <!--                                                    required>-->
                                    <!--                                                <option v-for='(location, key) in locations'-->
                                    <!--                                                        :key='key'-->
                                    <!--                                                        :value='location.physicalLocationId'>-->
                                    <!--                                                    {{ location.name }}-->
                                    <!--                                                </option>-->
                                    <!--                                            </select>-->
                                    <!--                                        </div>-->
                                    <!--                                    </div>-->
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Motif <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    :class="{'is-invalid': v$.movement.comment.$error,}"
                                                    v-model='movement.comment'
                                                    required>
                                                <option :value="'Vente'">Vente</option>
                                                <option :value="'SAV'">SAV</option>
                                                <option :value="'Casse'">Casse</option>
                                            </select>
                                            <p v-if='v$.movement.comment.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un motif pour la sortie du composant
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Quantité <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <input class='form-control'
                                                   type="number"
                                                   min="0"
                                                   v-model='movement.quantity'
                                                   required>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn--outline btn--color--danger" @click="closeModal" >
                        <svg-close />
                        <span>Annuler</span>
                    </button>
                    <button type="button" class="btn--color-danger" @click="addMovement" :disabled='v$.$error'>
                        <span>Enregistrer</span>
                        <svg-floppy-disk />
                    </button>
                </div>
            </modal>
        </section>
    </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import notif from '../../components/Notif.vue';
import modal from '../../components/Modal.vue';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import svgComponent from '../../_svg/svgComponents';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    name: 'model-components',
    components: {
        ...svgComponent,
        notif,
        modal,
    },
    data() {
        return {
            columns: [
                {
                    data: 'componentId',
                    title: 'id',
                }, {
                    data: 'ugs',
                    title: 'UGS',
                },{
                    data: 'manufacturerRef',
                    title: 'Référence fabricant',
                }, {
                    data: 'componentTypeName',
                    title: 'Type composant',
                },{
                    data: 'componentQualityName',
                    title: 'Qualité',
                }, {
                    data: 'models',
                    title: 'Modèle',
                    render: (data) => {
                        let res = '';
                        data.forEach(mod => {
                            if (mod && mod.name)
                                res += `<span class="multiselect__tag" style="font-size: 12px">${mod.name}</span>`;
                        });
                        return res;
                    }
                }, {
                    data: 'price',
                    title: 'Prix HT',
                }, {
                    data: 'supplierPrice',
                    title: 'Prix fournisseur',
                    render: (data) =>{
                        return data === null ? 0 : data;
                    }
                },{
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class="${ data ? this.status[data].class : this.status['false'].class}">${ data ? this.status[data].label : this.status['false'].label}</span>`;
                    }
                }, {
                    data: 'colorName',
                    title: 'Couleur',
                }
                , {
                    data: 'capacity',
                    title: 'Capacité',
                }, {
                    data: "stocks",
                    title: "Stock Magasin",
                    render: (data) => {
                        if(data.length) {
                            for (const stock of data) {
                                if(stock.placeId === this.placeId)
                                    return stock.quantity;
                            }
                        }
                        return 0;
                    }
                }, {
                    data: "stocks",
                    title: "Stock CRD",
                    render: (data) => {
                        if(data.length) {
                            for (const stock of data) {
                                if(stock.placeId === '2')
                                    return stock.quantity;
                            }
                        }
                        return 0;
                    }
                }
            ],
            table: null,
            components: [],
            repairId: null,
            placeId: null,
            error: null,
            success: false,
            msgError: null,
            loading: false,
            status: {
                'true': {
                    label: 'Actif',
                    class: 'text-success'
                },
                'false': {
                    label: 'Inactif',
                    class: 'text-danger'
                }
            },
            stockModal: false,
            movement: { type: 'out' },
            movementTypes: [{ id: 'in', label: 'Entrée' }, { id: 'out', label: 'Sortie' }],
            locations: [],
        };
    },
    mounted() {
        this.getQueries();
        this.getPlaces();
    },
    watch: {
        'repairId' :async function (val, oldVal) {
            if(val !== oldVal) {
                await this.getComponentsForModel();
                this.movement.repairId = this.repairId;
            }
        },
        // 'movement.place': async function (val, oldVal){
        //     if (val !== oldVal && val === '2')
        //         await this.getPhysicalLocations();
        // },
    },
    methods: {
        async getQueries() {
            localStorage.removeItem('jwt');
            localStorage.removeItem('user');
            this.repairId =  this.$route.query.repairId;
            this.placeId = this.$route.query.id.replace(/^(0+)/g, '');
        },
        async getComponentsForModel() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/repair/${this.repairId}/components`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$route.query.token}`
                        }
                    }
                );
                this.components = res.data.filter(c => c.status === true);
                this.table = this.initDt(this.columns, this.components);
            } catch (err) {
                console.log(err);
            }
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$route.query.token}`
                        }
                    }
                );
                this.places = res.data.filter(p =>p.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getPhysicalLocations() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/physicalLocations/place/${this.movement.place}`,
                );
                this.locations = res.data;
            } catch (err) {
                console.log(err);
            }
        },openStockModal() {
            this.stockModal = true;
            this.movement.repairId = this.repairId;
        },
        closeModal() {
            this.stockModal = false;
            this.movement = { type: 'out' };
        },
        async addMovement() {
            try {
                const res = await this.axios.post(
                    `${window.location.origin}/api/crd/stock/component/${this.movement.componentId}`,
                    this.movement,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$route.query.token}`
                        }
                    }
                );
                this.components.map(component => {
                    if(component.componentId === this.movement.componentId) {
                        component.stocks = res.data;
                    }
                });
                this.table.clear();
                this.table.destroy();
                this.table = this.initDt(this.columns, this.components);
                this.success = true;
            } catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.closeModal();
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                deferRender: true,
                ordering: true,
                info: false,
                filter: false,
                rowReorder: {
                    enabled: true
                },
                language: {
                    url: "https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json",
                },
                responsive: true,
                scrollResize: true,
                pageResize: true,
                paging: false,
                scrollY: 100,
                order: [[3, 'asc']],
                select: {
                    style: "api",
                    toggleable: false,
                },
                dom: 't',
                initComplete: () => {
                    const vm = this;
                    vm.$emit("initComplete", vm.table);

                    $(vm.$refs.table).on('click', 'td', function() {
                        const row = vm.table.row(this).data();
                        const cell = vm.table.cell(this).data();
                        if (row && row.componentId !== cell) {
                            let physicalLocationId = row.stocks.reduce((curr, stock) => {
                                if(stock.placeId === vm.placeId)
                                    curr = stock.physicalLocationId;
                                return curr;
                            }, null);
                            vm.movement.place = parseInt(vm.placeId);
                            vm.movement.location = physicalLocationId;
                            vm.movement.componentId = row.componentId;
                            vm.movement.quantity = 1;
                            vm.openStockModal(row);
                            console.log(row);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    validations() {
        let base = {
            movement: {
                $autoDirty: true,
                comment: { required }
            },
        };
        return base;
    },
};
</script>

<style scoped>
.backoffice-layout {
    grid-template-columns: 0;
    display : block;
}
</style>
