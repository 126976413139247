import { defineStore } from 'pinia';

export const useSearchStore = defineStore({
    id: 'search',
    state: () => ({
        search: {}
    }),
    getters:{
        getSearch: (state) => {
            return (field) =>{
                console.log(state.search[field]);
                return state.search[field];
            };
        }
    },
    actions: {
        set(name,data) {
            this.search[name] = data;
        }
    }
});
