<template>
    <section id='mainContent' class='h-100'>
        <div class='container-fluid h-100'>
            <div class='card h-100 shadow-sm'>
                <div class='card-body d-flex flex-column h-100'>
                    <h3 class='card-title' v-if='lecture'>Liste des marques</h3>
                    <div class='flex-fill' :hidden='!lecture'>
                        <table
                            ref='table'
                            class='table table-striped table-hover table-handyRows w-100'></table>
                    </div>
                    <notif :type="'error'"
                           :text="'Vous n\'êtes pas autorisé à consulter ce module.'"
                           v-if='!lecture'/>
                </div>
                <div class='card-footer'>
                    <div class='row justify-content-center'>
                        <div class='col-auto'>
                            <notif type='error'
                                   text='Un problème est survenu pendant la création de la marque'
                                   v-if='error'/>
                        </div>
                    </div>
                    <div class='row justify-content-end'>
                        <div class='col-auto is-animated d-flex'>
                            <button v-if='!loading && modification'
                                    class='btn--color-success'
                                    @click='createBrand'>
                                <span>Créer une nouvelle marque</span>
                                <svg-add />
                            </button>
                            <button v-if='loading'
                                    class='btn--color-success'
                                    disabled>
                                <figure class='spin--infinite mr-2'>
                                    <svg-add />
                                </figure>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';

import notif from '@/components/Notif.vue';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/stores/auth.store';
import svgComponents from '../../_svg/svgComponents';

export default {
    name: 'BrandList',
    components: { notif, ...svgComponents },
    data() {
        return {
            columns: [
                {
                    data: 'brandId',
                    title: 'Id',
                },
                {
                    data: 'name',
                    title: 'Nom',
                },
                {
                    data: 'comment',
                    title: 'Commentaire',
                },
                {
                    data: 'image',
                    title: 'Image',
                    render: (data) => {
                        return data ? `<img src="${data}" style="max-height: 48px"/>` : null;
                    }
                },
                {
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class='${data ? this.status[data].class : this.status['false'].class}'>${data ? this.status[data].label : this.status['false'].label}</span>`;
                    },
                },
                {
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    },
                },
            ],
            rows: [],
            table: null,
            error: false,
            loading: false,
            status: {
                'true': {
                    label: 'Actif',
                    class: 'text-success',
                },
                'false': {
                    label: 'Inactif',
                    class: 'text-danger',
                },
            },
            init: false,
            modification: false,
            lecture: false,

        };
    },
    async mounted() {
        await this.getList();
        this.modification = await this.authStore.moduleRight('Administration des marques', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des marques', 'Lecture');
    },
    methods: {
        async getList() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.rows = res.data;

                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
            }
        },
        goTo(brand) {
            this.$router.push(`brand/${brand.brandId}`);
        },
        createBrand() {
            this.$router.push('brand/create');
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                stateSave: true,
                buttons: ['searchBuilder'],
                deferRender: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageLength: 100,
                scrollY: 100,
                scrollX: true,
                order: [[5, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bflpti',
                stateLoadParams: function(settings, data) {
                    if (!this.init) {
                        var state = this.table.state.loaded();
                        data.searchBuilder = state.searchBuilder;
                    }
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);
                    // Restore state saved values
                    var state = vm.table.state.loaded();
                    if (state) {
                        console.log(state);
                    }

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo(row);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        },
    },
    setup() {
        return {
            authStore: useAuthStore(),
        };
    },
};
</script>

<style scoped></style>
