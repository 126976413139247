<template>
    <div
        class="is-animated"
        v-if="display">
        <div
            class="modal modal--danger modal--active"
            tabindex="-1"
            role="dialog">
            <div
                class="modal-dialog"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button
                            type="button"
                            @click="$emit('close')"
                            class="btn--outline btn--icon btn--small btn--color-gray-300"
                            data-dismiss="modal"
                            aria-label="Close">
                            <svg-close />
                        </button>
                    </div>
                    <slot ></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import svgComponent from '../_svg/svgComponents';

// Creates a modal window, with a close button, when enabled
export default {
    name: "CbModal",
    components: {
        ...svgComponent,
    },
    props: {
        title: String,
        display: Boolean,
        onCloseModal: Function,
    }
};
</script>
