<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.userId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>Création d'un utilisateur</h3>
                            <form class='mt-6'>
                                <div class="row">
                                    <div class="col-md-12">
                                        <fieldset>
                                            <legend>Informations</legend>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <!-- LOGIN -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Login <abbr>*</abbr></label>
                                                        <div class='col-6'>
                                                            <input type='text'
                                                                   class='form-control'
                                                                   :class="{'is-invalid': v$.detail.login.$error}"
                                                                   v-model='detail.login'
                                                                   :readonly="!modification">
                                                            <p v-if='v$.detail.login.$error' class='invalid-feedback'>Veuillez saisir un
                                                                login</p>
                                                        </div>
                                                    </div>
                                                    <!-- PRENOM -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Prénom</label>
                                                        <div class='col-6'>
                                                            <input type='text'
                                                                   class='form-control'
                                                                   v-model='detail.firstName'>
                                                        </div>
                                                    </div>
                                                    <!-- NOM -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Nom</label>
                                                        <div class='col-6'>
                                                            <input type='text'
                                                                   class='form-control'
                                                                   v-model='detail.lastName'>
                                                        </div>
                                                    </div>
                                                    <!-- ETAT -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Etat <abbr>*</abbr></label>
                                                        <div class='col-6'>
                                                            <div class='row'>
                                                                <div class='col'>
                                                                    <div class='custom-control custom-radio custom-control-inline'
                                                                         v-for='s in status'
                                                                         :key='s.value'>
                                                                        <input class='custom-control-input'
                                                                               type='radio'
                                                                               name='status'
                                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                                               :id="'status_'+s.value"
                                                                               :value='s.value'
                                                                               v-model='detail.status'>
                                                                        <label class='custom-control-label'
                                                                               :for="'status_'+s.value"
                                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class='row' v-if='v$.detail.status.$error'>
                                                                <div class='col'>
                                                                    <input type='hidden' class='form-control is-invalid' >
                                                                    <p class='invalid-feedback'>
                                                                        Veuillez sélectionner un état
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Lieux -->
                                                    <div class='row form-group' v-if="physicalPerson">
                                                        <label class='col-6 col-form-label'>Lieux</label>
                                                        <div class='col-6'>
                                                            <VueMultiselect v-model='detail.places'
                                                                            :options='places'
                                                                            :multiple="true"
                                                                            :taggable="true"
                                                                            placeholder="Chercher un lieu"
                                                                            label="name"
                                                                            track-by="id"/>
                                                        </div>
                                                    </div>
                                                    <!-- MOT DE PASSE -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Nouveau mot de passe</label>
                                                        <div class='col-3'>
                                                            <input type='text'
                                                                   class='form-control'
                                                                   v-model='detail.newPassword'
                                                                   readonly>
                                                        </div>
                                                        <div class='col-3'>
                                                            <button type="button" class="btn btn--color-bookstore" @click="generatePassword">Générer un mot de passe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" v-if="physicalPerson">
                                                    <!-- INITIALE -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Initiale</label>
                                                        <div class='col-6'>
                                                            <input type='text'
                                                                   class='form-control'
                                                                   v-model='detail.initial'>
                                                        </div>
                                                    </div>
                                                    <!-- CODE -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Code</label>
                                                        <div class='col-3'>
                                                            <input type='number'
                                                                   class='form-control'
                                                                   v-model='detail.code'
                                                                   readonly>
                                                        </div>
                                                        <div class='col-3'>
                                                            <button type="button" class="btn btn--color-bookstore" @click="generateCode">Générer un code</button>
                                                        </div>
                                                    </div>
                                                    <!-- PROFIL -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Profil utilisateur</label>
                                                        <div class='col-6'>
                                                            <select class='form-control'
                                                                    v-model='detail.userProfilId'>
                                                                <option :value=null>Sélectionner un profil</option>
                                                                <option v-for='(profil, key) in profils'
                                                                        :key='key'
                                                                        :value='profil.userProfilId'>
                                                                    {{ profil.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <!-- ETAT -->
                                                    <div class='row form-group'>
                                                        <label class='col-6 col-form-label'>Propriétaire</label>
                                                        <div class='col-6'>
                                                            <div class='row'>
                                                                <div class='col'>
                                                                    <div class='custom-control custom-radio custom-control-inline'
                                                                         v-for='s in status'
                                                                         :key='s.value'>
                                                                        <input class='custom-control-input'
                                                                               type='radio'
                                                                               name='storeOwner'
                                                                               :id="'storeOwner_'+s.value"
                                                                               :value='s.value'
                                                                               v-model='detail.storeOwner'>
                                                                        <label class='custom-control-label'
                                                                               :for="'storeOwner_'+s.value"
                                                                               :class="detail.storeOwner === s.value ? s.class : ''">{{ s.label2 }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="physicalPerson">
                                    <div class="col-md-12">
                                        <fieldset>
                                            <legend>Droits</legend>
                                            <div class="form-group row"
                                                 v-for='(module, key) in modules'
                                                 :key='key'>
                                                <label class="col-3 col-form-label">{{ module.name }}</label>
                                                <div class="col-9">
                                                    <div class="row">
                                                        <div class="col"
                                                             v-for='(right, index, key) in rights'
                                                             :key="key">
                                                            <div class="custom-control custom-checkbox">
                                                                <input class="custom-control-input"
                                                                       type="checkbox"
                                                                       :id='"gridCheck"+index+right.rightId+module.moduleId'
                                                                       @change="toggleRight(module.moduleId, right.rightId)"
                                                                       :value="module.name+'|'+right.name"
                                                                       v-model="checkedRights">
                                                                <label class="custom-control-label" :for='"gridCheck"+index+right.rightId+module.moduleId'>{{ right.name }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class="col-auto">
                                        <button v-if="!loading && modification"
                                                class="btn--color-success"
                                                @click="createUser">
                                            <span>Créer un nouvel utilisateur</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import notif from '@/components/Notif.vue';
import svgComponent from '../../_svg/svgComponents';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/stores/auth.store';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
    name: 'UserDetail',
    components: { notif, ...svgComponent, VueMultiselect },
    data() {
        return {
            detail: null,
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            modules: [],
            rights: [],
            checkedRights: [],
            places: [],
            profils: []
        };
    },
    computed :{
        physicalPerson() {
            return this.detail.firstName !== null && this.detail.lastName !== null && this.detail.firstName !== '' && this.detail.lastName !== '' ;
        }
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des utilisateurs', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des utilisateurs', 'Lecture');
        if(!this.lecture)
            this.$router.push("/admin/user");
        await this.getModules();
        await this.getRight();
        await this.getPlaces();
        await this.getUserProfils();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/user');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                userId: null,
                firstName: null,
                lastName: null,
                login: null,
                password: null,
                status: true,
                initial: null,
                code: null,
                userProfilId: null,
                storeOwner: false,
                modules: [],
                places: []
            };
            this.modules.forEach(module => {
                this.detail.modules.push({
                    id: module.moduleId,
                    name: module.name,
                    rights: []
                });
            });
            this.detail.modules.map(module => {
                for(const right of this.rights) {
                    module.rights.push({
                        id: right.rightId,
                        name: right.name,
                        value: false
                    });
                }
            });
            this.checkedRights = [];
            console.log(this.detail);
        },
        createUser() {
            this.createNewDetail();
            this.$router.push("create");
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/user/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
                this.detail.name = this.detail.firstName ? this.detail.firstName + ' ' + this.detail.lastName : this.detail.login;
                this.detail.name = this.detail.name.charAt(0).toUpperCase() + this.detail.name.slice(1);
                this.detail.modules.forEach(mod => {
                    mod.rights.forEach(right => {
                        if(right.value) {
                            this.checkedRights.push(mod.name+'|'+right.name);
                        }
                    });
                });
            } catch (err) {
                console.log(err);
            }
        },
        async getModules() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/modules`,
                );
                this.modules = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getRight() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/rights`,
                );
                this.rights = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                for(const place of res.data) {
                    if(place.status) {
                        this.places.push({
                            id: place.placeId,
                            name: place.name
                        });
                    }

                }
            } catch (err) {
                console.log(err);
            }
        },
        async getUserProfils() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/user/profils`,
                );
                this.profils = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                this.detail.firstName = this.detail.firstName === '' ? null : this.detail.firstName;
                this.detail.lastName = this.detail.lastName === '' ? null : this.detail.lastName;
                this.detail.initial = this.detail.initial === '' ? null : this.detail.initial;
                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/user`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    if(this.authStore.getUser.userId === res.data.userId)
                        await this.authStore.set(res.data);
                    this.goTo(res.data.userId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        generatePassword() {
            const tableauMinuscule = ["a","z","e","r","t","y","u","i","o","p","q","s","d","f","g","h","j","k","l","m","w","x","c","v","b","n"];
            const tableauMajuscule = ["A","Z","E","R","T","Y","U","I","O","P","Q","S","D","F","G","H","J","K","L","M","W","X","C","V","B","N"];
            const tableauNumero = [1,2,3,4,5,6,7,8,9,0];
            const tableauSymbole=["$","%","^","&","!","@","#","*","-","|","?","_","=","+"];


            const tableauxRegroupe = [].concat (
                tableauMinuscule, tableauMajuscule, tableauNumero, tableauSymbole
            );

            const passwordLength = 16;
            this.detail.newPassword = '';
            for (let i = 0; i < passwordLength; i++) {
                this.detail.newPassword+= tableauxRegroupe[Math.floor(Math.random() * tableauxRegroupe.length)];
            }
        },
        generateCode() {
            const tableauNumero = [1,2,3,4,5,6,7,8,9,0];
            const tableauxRegroupe = [].concat (tableauNumero);

            const codeLength = 4;
            this.detail.code = '';
            for (let i = 0; i < codeLength; i++) {
                this.detail.code+= tableauxRegroupe[Math.floor(Math.random() * tableauxRegroupe.length)];
            }
        },
        toggleRight(moduleId, rightId) {
            console.log(moduleId, rightId, this.checkedRights);
            let module = this.detail.modules.find(mod => mod.id === moduleId);
            let right = module.rights.find(right => right.id === rightId);
            if(right.name === 'Modification' && right.value === false) {
                if(this.checkedRights.indexOf(module.name+'|Lecture') === -1)
                    this.checkedRights.push(module.name+'|Lecture');
                let read = module.rights.find(right => right.name === 'Lecture');
                read.value = true;
            }
            if(right.name === 'Lecture' && right.value === true) {
                if(this.checkedRights.indexOf(module.name+'|Modification') !== -1) {
                    const index = this.checkedRights.indexOf(module.name+'|Modification');
                    this.checkedRights.splice(index, 1);
                }
                let write = module.rights.find(right => right.name === 'Modification');
                write.value = false;
            }
            right.value = !right.value;
            console.log(this.detail);
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
            authStore: useAuthStore()
        };
    },
    validations() {
        return {
            detail: {
                $autoDirty: true,
                login: { required },
                status: { required },
            },
        };
    }
});
</script>

<style scoped>

</style>
