<template>
    <section id='mainContent'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>
                                Entrée en stock
                            </h3>
                            <notif type="error"
                                   text="Vous n'êtes pas autorisé à consulter ce module."
                                   v-if="!lecture"/>
                            <form v-else>
                                <fieldset>
                                    <legend>Données</legend>
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type d'entrée</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='entryType'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type de données</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='d in datas'
                                                         :key='d.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='datas'
                                                               :id="'datas_'+d.value"
                                                               :value='d.value'
                                                               v-model='entryData'>
                                                        <label class='custom-control-label'
                                                               :for="'datas_'+d.value"
                                                               :class="datas === d.value ? d.class : ''">
                                                            {{ d.label }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='row form-group' v-if="entryData === 'composant'">
                                        <label class='col-3 col-form-label'>Type de recherche</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in search'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='search'
                                                               :id="'search_'+s.value"
                                                               :value='s.value'
                                                               v-model='entrySearch'>
                                                        <label class='custom-control-label'
                                                               :for="'search_'+s.value"
                                                               :class="search === s.value ? s.class : ''">
                                                            {{ s.label }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset v-if="entryData === 'composant'">
                                    <legend>Composant</legend>
                                    <div class='row form-group' v-if="entrySearch === 'name'">
                                        <label class='col-3 col-form-label'>Composant</label>
                                        <div class='col-6'>
                                            <VueMultiselect v-model='detail.component'
                                                            :options='allComponents'
                                                            placeholder="Chercher un composant"
                                                            label="name"
                                                            track-by="componentId"/>
                                        </div>
                                        <div v-if='detail.component.componentId'
                                             class='col-3'>
                                            {{ quantity }} en stock
                                        </div>
                                    </div>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Type produit <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.component ? v$.detail.component.productTypeId.$error : false}"
                                                    class='form-control'
                                                    v-model='detail.component.productTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(productType, key) in productTypes'
                                                        :key='key'
                                                        :value='productType.productTypeId'>
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.component ? v$.detail.component.productTypeId.$error : false'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de produit
                                            </p>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Marque <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.component ? v$.detail.component.brandId.$error : false}"
                                                    class='form-control'
                                                    v-model='detail.component.brandId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(brand, key) in brands'
                                                        :key='key'
                                                        :value='brand.brandId'>
                                                    {{ brand.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.component.brandId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner une marque
                                            </p>
                                        </div>
                                    </div>
                                    <!-- MODELE -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Modèle <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.component.modelId.$error}"
                                                    class='form-control'
                                                    v-model='detail.component.modelId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(model, key) in models'
                                                        :key='key'
                                                        :value='model.modelId'>
                                                    {{ model.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.component.modelId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un model
                                            </p>
                                        </div>
                                    </div>
                                    <!-- TYPE COMPOSANT -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Type composant <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.component ? v$.detail.component.componentTypeId.$error : false}"
                                                    class='form-control'
                                                    v-model='detail.component.componentTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(componentType, key) in componentTypes'
                                                        :key='key'
                                                        :value='componentType.componentTypeId'>
                                                    {{ componentType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.component.componentTypeId.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de composant
                                            </p>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Couleur</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.component.colorId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(color, key) in colors'
                                                        :key='key'
                                                        :value='color.colorId'>
                                                    {{ color.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- COMPOSANT -->
                                    <div class='row form-group' v-if="entrySearch === 'filter'">
                                        <label class='col-3 col-form-label'>Composant <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select
                                                v-model='detail.component.componentId'
                                                class='form-control'
                                                required
                                                @change="displayStock">
                                                <option :value='undefined' ></option>
                                                <option
                                                    v-for='(component, key) in components'
                                                    :key='key'
                                                    :value='component.componentId'>
                                                    {{ component.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div v-if='detail.component.componentId'
                                             class='col-3'>
                                            {{ quantity }} en stock
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset v-if="entryData === 'goodies'">
                                    <legend>Goodies</legend>
                                    <!-- TYPE GOODIES -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type goodies <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select :class="{'is-invalid': v$.detail.goodies ? v$.detail.goodies.goodiesTypeId.$error : false,}"
                                                    class='form-control'
                                                    v-model='detail.goodies.goodiesTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(goodiesType, key) in goodiesTypes'
                                                        :key='key'
                                                        :value='goodiesType.goodiesTypeId'>
                                                    {{ goodiesType.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.goodies ? v$.detail.goodies.goodiesTypeId.$error : false'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un type de goodies
                                            </p>
                                        </div>
                                    </div>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Marque <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    :class="{'is-invalid': v$.detail.goodies ? v$.detail.goodies.sign.$error: false}"
                                                    v-model='detail.goodies.sign'
                                                    required>
                                                <option :value="null"></option>
                                                <option :value="'BeeMyPhone'">BeeMyPhone</option>
                                                <option :value="'C%26R'">C&R</option>
                                            </select>
                                            <p v-if='v$.detail.goodies ? v$.detail.goodies.sign.$error: false'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner une marque
                                            </p>
                                        </div>
                                    </div>
                                    <!-- SEXE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Sexe</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.goodies.gender'
                                                    required>
                                                <option :value="null"></option>
                                                <option :value="'Femme'">Femme</option>
                                                <option :value="'Homme'">Homme</option>
                                                <option :value="'Mixte'">Mixte</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- TAILLE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Taille</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.goodies.size'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option :value="'XS'">XS</option>
                                                <option :value="'S'">S</option>
                                                <option :value="'M'">M</option>
                                                <option :value="'L'">L</option>
                                                <option :value="'XL'">XL</option>
                                                <option :value="'XXL'">XXL</option>
                                                <option :value="'XXXL'">XXXL</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- COULEUR -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Couleur</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.goodies.genericColorId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(genericColor, key) in genericColors'
                                                        :key='key'
                                                        :value='genericColor.genericColorId'>
                                                    {{ genericColor.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- GOODIES -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Goodies <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select
                                                v-model='detail.goodies.goodiesId'
                                                :class="{'is-invalid': v$.detail.goodies ? v$.detail.goodies.goodiesId.$error : false}"
                                                class='form-control'
                                                @change="displayStock"
                                                required>
                                                <option :value='undefined' ></option>
                                                <option
                                                    v-for='(g, key) in goodies'
                                                    :key='key'
                                                    :value='g.goodiesId'>
                                                    {{ g.name }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.goodies ? v$.detail.goodies.goodiesId.$error : false'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un goodies
                                            </p>
                                        </div>
                                        <div v-if='detail.goodies.goodiesId'
                                             class='col-3'>
                                            {{ quantity }} en stock
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Informations</legend>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Lieu</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model="detail.place"
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(place, key) in places'
                                                        :key='key'
                                                        :value='place.placeId'>
                                                    {{ place.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Quantité <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <input
                                                :class="{'is-invalid': v$.detail.quantity.$error,}"
                                                class='form-control'
                                                type="number"
                                                min="0"
                                                v-model='detail.quantity'
                                                required>
                                            <p v-if='v$.detail.quantity.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner une quantité
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="detail.action === 'add'">
                                        <label class='col-3 col-form-label'>Fournisseur <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.supplier'
                                                    :class="{'is-invalid': v$.detail.supplier.$error,}"
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(supplier, key) in suppliers'
                                                        :key='key'
                                                        :value='supplier.id'>
                                                    {{ supplier.value }}
                                                </option>
                                            </select>
                                            <p v-if='v$.detail.supplier.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un fournisseur
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group row" v-if="detail.action === 'add'">
                                        <label class='col-3 col-form-label'>Prix unitaire HT <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <input class='form-control'
                                                   type="text"
                                                   :class="{'is-invalid': v$.detail.unitPrice.$error,}"
                                                   v-model='detail.unitPrice'
                                                   required>
                                            <p v-if='v$.detail.unitPrice.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner un Prix unitaire HT
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class='col-3 col-form-label'>Action <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='detail.action'
                                                    required>
                                                <option :value="'add'">Ajouter</option>
                                                <option :value="'erase'">Ecraser</option>
                                            </select>
                                            <p v-if='v$.detail.action.$error'
                                               class='invalid-feedback'>
                                                Veuillez sélectionner une action
                                            </p>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class="row is-animated d-flex justify-content-end">
                                <div class='col-auto '>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error || (!detail.component.componentId && !detail.goodies.goodiesId)'>
                                        <span>Ajouter</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Ajout en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Stock</h3>
                            <div class='row justify-content-center'>
                                <div class='col-12' >
                                    <table
                                        ref="table"
                                        class="table table-striped table-hover table-handyRows"></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-buttons/js/buttons.html5';
import { useAuthStore } from '@/stores/auth.store';
import { DateTime } from 'luxon';
import { useLoader } from '@/stores/loader';
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'StockInventory',
    components: {
        ...svgComponent,
        notif,
        VueMultiselect
    },
    data() {
        return {
            detail: {
                component: {
                    componentTypeId: null,
                    brandId: null,
                    modelId: null,
                    productTypeId: null,
                    colorId: null,
                    componentId: null,
                },
                goodies:{
                    goodiesTypeId: null,
                    goodiesId: null,
                    genericColorId: null,
                    sign: null,
                    gender: null,
                    size: null
                },
                place: null,
                type: 'in',
                comment: 'Depuis module stock / Ajouter',
                action: 'add',
                quantity: null,
                supplier: null,
                unitPrice: null
            },
            allComponents: [],
            componentTypes: [],
            colors: [],
            brands: [],
            productTypes: [],
            components: [],
            models: [],
            goodiesTypes: [],
            genericColors: [],
            places: [],
            stocks: [],
            goodies:[],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            status: [
                {
                    value: 'livraison',
                    label: 'Livraison',
                    class: 'text-info',
                },
                {
                    value: 'inventaire',
                    label: 'Inventaire',
                    class: 'text-info',
                },
            ],
            datas: [
                {
                    value: 'composant',
                    label: 'Composant',
                    class: 'text-info',
                },
                {
                    value: 'goodies',
                    label: 'Goodies',
                    class: 'text-info',
                },
            ],
            search: [
                {
                    value: 'name',
                    label: 'Par nom',
                    class: 'text-info',
                },
                {
                    value: 'filter',
                    label: 'Par filtres',
                    class: 'text-info',
                },
            ],
            entryType: 'livraison',
            entryData:'composant',
            entrySearch: 'name',
            columns: [
                {
                    data: 'stockId',
                    title: 'Id',
                    render: function(data) {
                        return data ? '<a href="/admin/stock/' + data + '">' + data + '</a>' : '';
                    }
                },{
                    data: 'ugs',
                    title: 'UGS'
                }, {
                    data: 'name',
                    title: 'Nom',
                },  {
                    data: 'placeName',
                    title: 'Lieu',
                }, {
                    data: 'quantity',
                    title: 'Quantité',
                }, {
                    data: 'bookingQuantity',
                    title: 'Réservé',
                },{
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    }
                }
            ],
            suppliers: [
                { id:'MOBILAX', value:'MOBILAX' },
                { id:'UTOPYA', value:'UTOPYA' },
                { id:'EBAY', value:'EBAY' },
                { id:'MACINFO', value:'MACINFO' },
                { id:'CRD', value:'CRD' },
                { id:'AUTRE', value:'AUTRE' }
            ],
            table: null,
            lecture: false,
            modification: false,
            create: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des stocks', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des stocks', 'Lecture');
        // this.places = await this.authStore.getUser.places;
        // this.detail.place = this.places[0].id;
        if(this.lecture) {
            await this.getPlaces();
            await this.getDatas();
        }
    },
    watch: {
        'detail.component.brandId': async function (val, oldVal) {
            if(val !== oldVal && this.detail.component.productTypeId && this.entrySearch === 'filter')
                await this.getModels();
        },
        'detail.component.productTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.component.brandId && this.entrySearch === 'filter')
                await this.getModels();
        },
        'detail.component.modelId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.component.componentTypeId && this.detail.component.brandId
                && this.detail.component.productTypeId && this.detail.component.modelId && this.entrySearch === 'filter')
                await this.getComponents();
        },
        'detail.component.colorId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.component.modelId && this.entrySearch === 'filter')
                await this.getComponents();
        },
        'detail.component.componentId' : async function (val, oldVal) {
            if (val !== oldVal)
                await this.displayStock();
        },
        'detail.place' : async function (val, oldVal) {
            console.log(val);
            if (val !== oldVal && val !== undefined) {
                if (this.entryData === 'composant') {
                    await this.getStockComponentsByPlace();
                } else {
                    await this.getStockGoodiesByPlace();
                }
            }
        },
        'detail.action' : async function (val, oldVal) {
            if (val !== oldVal)
                this.detail.comment = this.detail.action === 'add' ? 'Depuis module stock / Ajouter' : 'Depuis module stock / Ecraser';
        },
        'detail.component.componentTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.detail.component.modelId && this.detail.component.brandId && this.detail.component.productTypeId && this.entrySearch === 'filter')
                await this.getComponents();
        },
        'entryType': async function(val, oldVal) {
            if(val !== oldVal && val === 'livraison') {
                this.detail.action = 'add';
            }else{
                this.detail.action = 'erase';
            }
        },
        'entryData': async function(val, oldVal) {
            if(val !== oldVal) {
                await this.getDatas();
            }
        },
        'entrySearch': async function(val, oldVal) {
            if(val !== oldVal) {
                this.detail.component = {
                    componentTypeId: null,
                    brandId: null,
                    modelId: null,
                    productTypeId: null,
                    colorId: null,
                    componentId: null
                };
                this.detail.supplier = null;
                this.detail.quantity = null;
                this.detail.unitPrice = null;
                await this.getDatas();
            }
        },
        'detail.goodies.goodiesTypeId' : async function(val, oldVal) {
            if(val !== oldVal && this.detail.goodies.sign)
                await this.getGoodies();
        },
        'detail.goodies.sign' : async function(val, oldVal) {
            if(val !== oldVal && this.detail.goodies.goodiesTypeId)
                await this.getGoodies();
        },
        'detail.goodies.gender' : async function(val, oldVal) {
            if(val !== oldVal && this.detail.goodies.goodiesTypeId && this.detail.goodies.sign)
                await this.getGoodies();
        },
        'detail.goodies.size' : async function(val, oldVal) {
            if(val !== oldVal && this.detail.goodies.goodiesTypeId && this.detail.goodies.sign)
                await this.getGoodies();
        },
        'detail.goodies.genericColorId' : async function(val, oldVal) {
            if(val !== oldVal && this.detail.goodies.goodiesTypeId && this.detail.goodies.sign)
                await this.getGoodies();
        }
    },
    methods: {
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['6', '999', '3'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id === '2') {
                    this.detail.place = '2';
                } else {
                    this.places = this.authStore.getUser.places;
                    this.places.map(p => {
                        p.placeId = p.id;
                    });
                    this.detail.place = this.places[0].id;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getComponents() {
            try {
                let url = `${window.location.origin}/api/crd/component?componentTypeId=${this.detail.component.componentTypeId}&modelId=${this.detail.component.modelId}`;
                if(this.detail.colorId) {
                    url += `&colorId=${this.detail.colorId}`;
                }
                const res = await this.axios.get(url);
                this.components = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.detail.component.brandId}/productType/${this.detail.component.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentType`,
                );
                this.componentTypes = res.data.filter(ct => ct.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data.filter(b => b.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getGoodiesTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goodiesType`,
                );
                this.goodiesTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGenericColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/genericColor`,
                );
                this.genericColors = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGoodies() {
            this.detail.goodies.sign.replace('&', '%26');
            let url = `${window.location.origin}/api/crd/goodies/query?goodiesTypeId=${this.detail.goodies.goodiesTypeId}&sign=${this.detail.goodies.sign}`;
            if(this.detail.goodies.gender) url+= `&gender=${this.detail.goodies.gender}`;
            if(this.detail.goodies.size) url+= `&size=${this.detail.goodies.size}`;
            if(this.detail.goodies.genericColorId) url+= `&genericColorId=${this.detail.goodies.genericColorId}`;
            try {
                const res = await this.axios.get(url);
                this.goodies = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getAllComponents() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/components`,
                );
                this.allComponents = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getDatas() {
            try {
                this.loader.show('load');
                if (this.entryData === 'composant') {
                    if(this.entrySearch === 'filter') {
                        await this.getBrands();
                        await this.getProductTypes();
                        await this.getComponentTypes();
                        await this.getColors();
                        await this.getStockComponentsByPlace();
                    }else {
                        await this.getAllComponents();
                    }
                } else {
                    await this.getGoodiesTypes();
                    await this.getGenericColors();
                    await this.getStockGoodiesByPlace();
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                let url = this.entryData === 'composant' ? `${window.location.origin}/api/crd/stock/component/${this.detail.component.componentId}`
                    : `${window.location.origin}/api/crd/stock/goodies/${this.detail.goodies.goodiesId}`;
                let data = this.entryData === 'composant' ? this.detail.component : this.detail.goodies;
                data.type = this.detail.type;
                data.action = this.detail.action;
                data.comment = this.detail.comment;
                data.place = this.detail.place;
                data.quantity = this.detail.quantity;
                data.supplier = this.detail.supplier;
                data.unitPrice = this.detail.unitPrice;
                try {
                    await this.axios.post(url, data);
                    this.success = true;
                    this.loading = false;
                    if(this.entryData === 'composant') {
                        await this.getStockComponentsByPlace();
                    }else{
                        await this.getStockGoodiesByPlace();
                    }
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        async getStockComponentsByPlace() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/components/place/${this.detail.place}`,
                );
                this.stocks = res.data;
                if(this.table) {
                    this.table.clear();
                    this.table.destroy();
                }
                this.table = this.initDt(this.columns, this.stocks);
            } catch (err) {
                console.log(err);
            }
        },
        async getStockGoodiesByPlace() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/goodies/place/${this.detail.place}`,
                );
                this.stocks = res.data;
                if(this.table) {
                    this.table.clear();
                    this.table.destroy();
                }
                this.table = this.initDt(this.columns, this.stocks);
            } catch (err) {
                console.log(err);
            }
        },
        displayStock() {
            let result;
            if(this.entryData === 'composant') {
                result = this.stocks.find(s => s.componentId === this.detail.component.componentId);
            }else {
                result = this.stocks.find(s => s.goodiesId === this.detail.goodies.goodiesId);
            }
            this.quantity = result === undefined ? 0 : result.quantity;
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                ordering: true,
                info: false,
                rowReorder: {
                    enabled: true
                },
                filter: false,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json'
                },
                paging: false,
                dom: 't',
                order: [[6, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        const cell = vm.table.cell(this).data();
                        if (row && row.stockId !== cell) {
                            console.log(row);
                            vm.detail.place = row.placeId;
                            if(vm.entryData === 'composant') {
                                vm.detail.component.componentTypeId = row.componentTypeId;
                                vm.detail.component.brandId = row.brandId;
                                vm.detail.component.productTypeId = row.models[0].productTypeId;
                                vm.detail.component.modelId = row.models[0].modelId;
                                vm.detail.component.componentId = row.componentId;
                            }else{
                                vm.detail.goodies.goodiesId = row.goodiesId;
                                vm.detail.goodies.goodiesTypeId = row.goodiesTypeId;
                                vm.detail.goodies.genericColorId = row.genericColorId;
                                vm.detail.goodies.sign = row.sign === 'C&R' ? 'C%26R' : row.sign;
                                vm.detail.goodies.gender = row.gender;
                                vm.detail.goodies.size = row.size;
                            }

                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        loader: useLoader()
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                quantity: { required },
                action: { required }
            }
        };
        if(this.entryType === 'livraison') {
            base.detail.supplier = { required };
            base.detail.unitPrice = { required };

        }
        if(this.entryData === 'composant') {
            if(this.entrySearch === 'filter') {
                base.detail.component = {
                    componentTypeId: { required },
                    brandId: { required },
                    productTypeId: { required },
                    modelId: { required }
                };
            }
        }else {
            base.detail.goodies = {
                goodiesTypeId: { required },
                sign: { required },
                goodiesId: { required }
            };
        }
        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
