<template>
    <div class='backoffice-layout theme-bookstore' :class='classEnv'>
        <div id='sidebar' :class="{ 'sidebar--active': menuDisplayed }">
            <div class='container-fluid p-0'>
                <nav id='navBar' style='height: 800px; overflow: auto;'>
                    <ul>
                        <li v-for='m in menu'
                            :class="{ 'active' : m.active }"
                            :key='m'
                            :hidden='!moduleRight(m.name, "Lecture") && !m.subMenu'>
                            <a href
                               @click='select(m, $event)'
                               class='btn border-0 d-flex justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <svg-icon :icon='m.icon' />
                                    <span>{{ m.name }}</span>
                                </div>
                                <slot v-if='m.subMenu && m.active'>
                                    <svg-arrow-up />
                                </slot>
                                <slot v-if='m.subMenu && !m.active'>
                                    <svg-arrow-down />
                                </slot>
                            </a>
                            <ul v-if='m.subMenu && m.subMenu.length > 0 && m.active'>
                                <li :class="{ 'active' : s.active }"
                                    v-for='s in m.subMenu'
                                    :key='s'>
                                    <a href
                                       @click='select(s, $event)'
                                       class='btn border-0'>
                                        <svg-icon :icon='s.icon' />
                                        <span>{{ s.name }}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <header id='mainHeader'>
            <div class='container-fluid row align-items-center justify-content-between m-0  p-0 h-100'>
                <div id='mainLogo' class='col-lg-auto d-none d-lg-block p-0'>
                    <a ui-sref='css-home' href='/dashboard'>
                        <img src='../../assets/grapp/dist/assets/images/cerebro-removebg-preview6.png'
                             alt='CEREBRO Back-office'>
                    </a>
                </div>
                <div class='col-auto p-0 justify-content-start'>
                    <button type='button' @click='toggleDisplay' class='btn btn--menu btn--color-black py-1 mx-2 px-2'>
                        <svg-menu />
                        <span class='d-none d-sm-block'>Menu</span>
                    </button>
                </div>
                <div class='col col-xl-4 p-2'>
                    <h1 class='mt-n1'><small><strong>CEREBRO ({{ version }})</strong></small></h1>
                    <h2>{{ $route.meta.subtitle }}</h2>
                </div>
                <div class='col col-xl-4 text-center d-none d-lg-block'>
                    <h3>{{ date }}<br>{{ time }}</h3>
                </div>
                <div class='col-auto d-flex align-items-center justify-content-end'>
                    <h2 class='d-none d-lg-block pr-2'>{{ authStore.getDisplayName.toUpperCase() }}</h2>
                    <button @click='logout'>
                        <svg-logout />
                    </button>
                </div>
            </div>
        </header>
        <grapp-loader :active="loader.is('load')"
                      :message="'Chargement en cours...'"/>
        <section id='mainContent'>
            <div class='container-fluid'>
                <div class='row'>
                    <div class='col-sm-12 h100'>
                        <div class='card shadow-sm'>
                            <div class='card-body'>
                                <h3 class='card-title'>Tableau de bord</h3>
                                <div class='row align-items-center'>
                                    <div class='col-lg-4 col-12'>
                                        <button class='btn'
                                                @click='dayBefore'
                                                style='background: transparent; border: black 1px solid; color: black'>
                                            <svg-arrow-left />
                                        </button>
                                        <datepicker v-model='salesDate'
                                                    name='firstDay'
                                                    @input='selectDate'
                                                    language='fr'
                                                    :monday-first='true'
                                                    style='margin: 0 5px;' />
                                        <button @click='dayAfter'
                                                style='background: transparent; border: black 1px solid; color: black'>
                                            <svg-arrow-right />
                                        </button>
                                    </div>
                                    <div class='col-lg-4 col-12'>
                                        <select class='form-control'
                                                v-model='sign'
                                                @change='filterSign'
                                                :disabled="oneSign"
                                                required>
                                            <option :value=null>Sélectionner une marque</option>
                                            <option value='BMP'>BeeMyPhone</option>
                                            <option value='C&R'>Cash & Repair</option>
                                        </select>
                                    </div>
                                    <div class='col-lg-4 col-12'>
                                        <select class='form-control'
                                                v-model='place'
                                                @change='filterSale'
                                                required>
                                            <option :value=null>Sélectionner un lieu</option>
                                            <option v-for='(place, key) in places'
                                                    :key='key'
                                                    :value='place'>
                                                {{ place.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class='row mt-4' v-if='!place'>
                                    <div class='col-md-12'>
                                        <h2>Chiffres du {{ selectedDay }}</h2>
                                        <div>
                                            <Bar ref='day'
                                                 :data='salesDayData'
                                                 :options='chartDayOptions'
                                                 :style='myStyles'
                                                 :plugins='chartPlugins'/>
                                        </div>
                                    </div>
                                </div>
                                <div class='row mt-4' v-if='place'>
                                    <div class='col-md-12'>
                                        <div class='card'>
                                            <h2 class='card-header m-0'>Facturation du {{ selectedDay }}</h2>
                                            <div class='card-body'>
                                                <div class='card-deck'>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 class='card-title'>Moyens de paiement (TTC)</h3>
                                                            <div v-for='(salePayment, key) in salesDayPayment'
                                                                 :key='key'>
                                                                <ul>
                                                                    <li v-for='(payement, key2) in salePayment.payements'
                                                                        :key='key2'>
                                                                        {{ payement.label }} :
                                                                        {{ payement.amount.toFixed(2) }}€
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 v-if='!this.isBmp' class='card-title'>Réparations</h3>
                                                            <h3 v-if='this.isBmp' class='card-title'>Ventes</h3>
                                                            <ul v-if='salesPlace'>
                                                                <li>
                                                                    CA HT : {{ salesPlace.day.CA }}€
                                                                </li>
                                                                <li>
                                                                    Panier Moyen HT :
                                                                    {{ !isBmp ? salesPlace.day.averageRepair : salesPlace.day.averageSale
                                                                    }}€
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Initiées : {{ salesPlace.day.initiatedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Terminées : {{ salesPlace.day.finishedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    SAV : {{ salesPlace.day.sav || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Nb remboursement : {{ salesPlace.day.nbRefund || 0
                                                                    }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Remboursement HT :
                                                                    {{ salesPlace.day.amountRefund || 0 }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 class='card-title'>Rachats</h3>
                                                            <ul v-if='salesPlace'>
                                                                <li v-if='!this.isBmp'>
                                                                    Quantité : {{ salesPlace.day.nbPurchase || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Montant : {{ salesPlace.day.amountPurchase || 0 }}€
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Quantité : {{ salesPlace.day.nbRefund || 0 }}
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Montant : {{ salesPlace.day.amountRefund * -1 || 0
                                                                    }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='row mt-4' v-if='place'>
                                    <div class='col-md-6'>
                                        <div class='card'>
                                            <h2 class='card-header m-0'>Chiffres de la semaine</h2>
                                            <div class='card-body'>
                                                <div class='card-deck'>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 v-if='!this.isBmp' class='card-title'>Réparations</h3>
                                                            <h3 v-if='this.isBmp' class='card-title'>Ventes</h3>
                                                            <ul>
                                                                <li>
                                                                    CA HT : {{ salesPlace.week.CA }}€
                                                                </li>
                                                                <li>
                                                                    Panier Moyen HT :
                                                                    {{ !isBmp ? salesPlace.week.averageRepair : salesPlace.week.averageSale
                                                                    }}€
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Initiées : {{ salesPlace.week.initiatedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Terminées : {{ salesPlace.week.finishedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    SAV : {{ salesPlace.week.sav || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Nb remboursement : {{ salesPlace.week.nbRefund || 0
                                                                    }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Remboursement HT :
                                                                    {{ salesPlace.week.amountRefund * -1 || 0 }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 class='card-title'>Rachats</h3>
                                                            <ul>
                                                                <li v-if='!this.isBmp'>
                                                                    Quantité : {{ salesPlace.week.nbPurchase || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Montant : {{ salesPlace.week.amountPurchase || 0 }}€
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Quantité : {{ salesPlace.week.nbRefund || 0 }}
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Montant : {{ salesPlace.week.amountRefund * -1 || 0
                                                                    }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-md-6'>
                                        <div class='card'>
                                            <h2 class='card-header m-0'>Chiffres du mois</h2>
                                            <div class='card-body'>
                                                <div class='card-deck'>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 v-if='!this.isBmp' class='card-title'>Réparations</h3>
                                                            <h3 v-if='this.isBmp' class='card-title'>Ventes</h3>
                                                            <ul>
                                                                <li>
                                                                    CA HT : {{ salesPlace.month.CA }}€
                                                                </li>
                                                                <li>
                                                                    Panier Moyen HT :
                                                                    {{ !isBmp ? salesPlace.month.averageRepair : salesPlace.month.averageSale
                                                                    }}€
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Initiées : {{ salesPlace.month.initiatedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Terminées : {{ salesPlace.month.finishedRepairs }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    SAV : {{ salesPlace.month.sav || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Nb remboursement : {{ salesPlace.month.nbRefund || 0
                                                                    }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Remboursement HT :
                                                                    {{ salesPlace.month.amountRefund * -1 || 0 }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class='card'>
                                                        <div class='card-body'>
                                                            <h3 class='card-title'>Rachats</h3>
                                                            <ul>
                                                                <li v-if='!this.isBmp'>
                                                                    Quantité : {{ salesPlace.month.nbPurchase || 0 }}
                                                                </li>
                                                                <li v-if='!this.isBmp'>
                                                                    Montant : {{ salesPlace.month.amountPurchase || 0
                                                                    }}€
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Quantité : {{ salesPlace.month.nbRefund || 0 }}
                                                                </li>
                                                                <li v-if='this.isBmp'>
                                                                    Montant : {{ salesPlace.month.amountRefund * -1 || 0
                                                                    }}€
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='row mt-4' v-if='place'>
                                    <div class='col-md-12'>
                                        <div class='card-deck'>
                                            <div class='card'>
                                                <h2 v-if='!this.isBmp' class='card-header m-0'>Réparations</h2>
                                                <h2 v-if='this.isBmp' class='card-header m-0'>Ventes</h2>
                                                <div class='card-body'>
                                                    <chartLine ref='yearRepair'
                                                               :data='salesYearRepair'
                                                               :options='chartYearOptions' />
                                                </div>
                                            </div>
                                            <div class='card'>
                                                <h2 class='card-header m-0'>Rachats</h2>
                                                <div class='card-body'>
                                                    <chartLine ref='yearPurchase' :data='salesYearPurchase' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import svgIcon from '@/components/SvgIcon.vue';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import { DateTime } from 'luxon';
import Datepicker from 'vuejs3-datepicker';
import svgComponents from '../../_svg/svgComponents';
import svgs from '../../_svg/svgComponents';
import grappLoader from '@/components/Loader.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line as chartLine } from 'vue-chartjs';

ChartJS.register(...registerables);


const dateOpts = { weekday: 'long', month: 'long', day: 'numeric' };
const timeOpts = { hour: '2-digit', minute: '2-digit' };

export default defineComponent({
    name: 'DashBoardOld',
    components: { grappLoader, svgIcon, Bar, Datepicker, chartLine, ...svgComponents },
    data() {
        return {
            menu: [
                {
                    icon: svgs.svgLayoutDashboard.raw,
                    name: 'Tableau de bord',
                    stateName: 'tableau-de-bord',
                },
                {
                    icon: svgs.svgLayoutDashboard.raw,
                    name: 'Rapport d\'activité',
                    stateName: 'admin-report-activity',
                },
                {
                    name: 'Catalogues',
                    subMenu: [{
                        name: 'Administration des composants',
                        stateName: 'admin-components',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des réparations',
                        stateName: 'admin-repairs',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des produits',
                        stateName: 'admin-products',
                        icon: svgs.svgListBullets.raw,
                    }, {
                        name: 'Administration des modèles',
                        stateName: 'admin-models',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des appareils',
                        stateName: 'admin-devices',
                        icon: svgs.svgListBullets.raw,
                    },],
                    icon: svgs.svgListBullets.raw,
                },

                {
                    name: 'Administration des groupes',
                    stateName: 'admin-groups',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Administration des stocks',
                    stateName: 'admin-stocks',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Administration des types de composant',
                    stateName: 'admin-component-type',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Administration des types de goodies',
                    stateName: 'admin-goodies-type',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Administration des goodies',
                    stateName: 'admin-goodies',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Administration des marques',
                    stateName: 'admin-brands',
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Paramètres',
                    icon: svgs.svgCog.raw,
                    subMenu: [
                        {
                            name: 'Administration des objectifs',
                            stateName: 'admin-goals',
                            icon: svgs.svgBusinessClimbTop1.raw
                        },{
                            name: 'Administration des lieux',
                            stateName: 'admin-places',
                            icon: svgs.svgPinLocation.raw,
                        },{
                            name: 'Administration des utilisateurs',
                            stateName: 'admin-users',
                            icon: svgs.svgSingleNeutralCircle.raw,
                        },
                    ]
                }
            ],
            selectedMenu: null,
            date: new Date().toLocaleDateString('fr-FR', dateOpts),
            time: new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h'),
            classEnv: null,
            displayName: '',
            userModules: [],
            salesDate: DateTime.now().minus({ days: 1 }).toHTTP(),
            salesPlace: { day: { CA: null }, week: { CA: null }, month: { CA: null } },
            salesDay: [],
            salesDayPayment: [],
            salesCurrentWeek: [],
            salesCurrentMonth: [],
            salesCurrentYear: [],
            salesByPayment: [],
            salesDayData: {
                labels: [],
                datasets: []
            },
            salesYearRepair: {
                labels: [],
                datasets: [],
            },
            salesYearPurchase: {
                labels: [],
                datasets: [],
            },
            loaded: false,
            delayed: false,
            chartPlugins: [ChartDataLabels],
            chartDayOptions: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    onComplete: () => {
                        this.delayed = true;
                    },
                    delay: (context) => {
                        let delay = 0;
                        if (context.type === 'data' && context.mode === 'default' && !this.delayed) {
                            delay = context.dataIndex * 300 + context.datasetIndex * 100;
                        }
                        return delay;
                    },
                },
                plugins: {
                    layout: {
                        padding: {
                            top: 0,
                            bottom: 0
                        }
                    },
                    datalabels: {
                        color: function(ctx) {
                            return ctx.dataset.borderColor;
                        },
                        anchor: 'end',
                        align: 'end',
                        display: 'auto',
                        rotation: 45,
                        clip: true,
                        offset: 0,
                        formatter: (value, _context) => {
                            return `${this.$filters.decimal(value)} €`;
                        }
                    },
                    legend: {
                        display: false
                    }
                },
            },
            chartYearOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            selectedDay: DateTime.now().minus({ days: 1 }).toHTTP(),
            places: [],
            allPlaces: [],
            place: null,
            isBmp: false,
            sign: null,
            oneSign: false,
            menuDisplayed: false,
            version: process.env.VUE_APP_VERSION,
        };
    },
    watch: {
        '$route': function(route) {
            this.menu.map((m) => {
                m.active = false;

                if(m.stateName === route.name) {
                    m.active = true;
                }

                if(m.subMenu) {
                    m.subMenu.map((sub) => {
                        sub.active = false;

                        if(sub.stateName === route.name) {
                            m.active = true;
                            sub.active = true;
                        }
                    });
                }
            });
        }
    },
    created() {
        if (!this.authStore.getUser) this.logout();
        setInterval(() => {
            this.date = new Date().toLocaleDateString('fr-FR', dateOpts);
            this.time = new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h');
        }, 5000);
    },
    computed: {
        myStyles() {
            return {
                height: '800px',
                position: 'relative',
            };
        },
    },
    async mounted() {
        this.getEnv();
        await this.getPlaces();
        await this.filterSign();
        this.displayName = this.authStore.getUser ? this.authStore.getUser.firstName ? this.authStore.getUser.firstName + ' ' + this.authStore.getUser.lastName
            : this.authStore.getUser.login : null;
        this.userModules = this.authStore.getUser ? this.authStore.getUser.modules : [];
    },
    methods: {
        select(menu, event) {
            if (event)
                event.preventDefault();
            this.menu.map((m) => m !== menu ? m.active = false : m.active = !m.active);
            if (!menu.stateName) return;
            this.$router.push({ name: menu.stateName });
            this.menuDisplayed = false;
        },
        logout() {
            return this.authStore.logout();
        },
        getEnv() {
            if (document.location.href.search('localhost') !== -1) {
                this.classEnv = 'is-dev';
            } else if (document.location.href.search('ready2dev') !== -1) {
                this.classEnv = 'is-preprod';
            } else {
                this.classEnv = 'is-prod';
            }
        },
        moduleRight(moduleName, rightName) {
            let module = this.userModules.find(mod => mod.name === moduleName);
            if (module) {
                const right = module.rights.find(r => r.name === rightName);
                if (right)
                    return right.value;
                return false;
            }
            return false;
        },
        async getSales() {
            try {
                this.loader.show('load');
                this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).toFormat('yyyy-MM-dd');
                this.selectedDay = DateTime.fromISO(this.salesDate).toFormat('dd/MM/yyyy');
                let res = await this.axios.get(
                    `${window.location.origin}/api/crd/sales/${this.salesDate}/day`,
                );
                this.salesDay = res.data.sales;
                this.salesByPayment = res.data.salesByPayment;
                this.salesDayPayment = res.data.salesByPayment;
                this.salesDayData.labels = [];
                this.salesDayData.datasets = [];
                if (this.salesDay.length > 0) {
                    let CAs = [];
                    this.salesDay.forEach(data => {
                        if (data.name.indexOf(this.sign) !== -1 || !this.sign) {
                            CAs.push(data.CA);
                            this.salesDayData.labels.push(data.name);
                        }
                    });
                    this.salesDayData.datasets.push(
                        {
                            data: CAs,
                            //barThickness: 10,
                            label: 'CA HT',
                            borderColor: 'rgb(159,198,59)',
                            backgroundColor: 'rgb(159,198,59,0.1)',
                            borderWidth: 2,
                        }
                    );
                }
                this.loaded = true;
                const chartInstance = this.$refs.day.chart;
                chartInstance.data = this.salesDayData;
                chartInstance.options.plugins.title.text = 'Chiffre d\'affaire du ' + this.salesDate;
                chartInstance.update();
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async filterSale() {
            let res;
            this.salesDayData.datasets = [];
            this.salesDayData.labels = [];
            try {
                this.loader.show('load');
                if (this.place) {
                    this.isBmp = ['3', '4', '6'].indexOf(this.place.placeTypeId) !== -1;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/${this.salesDate}/week/${this.place.placeId}`,
                    );
                    this.salesCurrentWeek = res.data.sales;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/${this.salesDate}/month/${this.place.placeId}`,
                    );
                    this.salesCurrentMonth = res.data.sales;
                    res = await this.axios.get(
                        `${window.location.origin}/api/crd/sales/year/${this.salesDate}/${this.place.placeId}`,
                    );
                    this.salesCurrentYear = res.data;
                    this.salesDayPayment = this.salesByPayment.reduce((curr, sp) => {
                        if (sp.idLieu === this.place.placeId) {
                            curr.push(sp);
                        }
                        return curr;
                    }, []);
                    if (this.salesDay.length > 0) {
                        this.salesDay.forEach(data => {
                            if (data.idLieu === this.place.placeId) {
                                this.salesPlace.day = data;
                            }
                        });
                        if (!this.salesDay.find(sale => sale.idLieu === this.place.placeId)) {
                            this.salesPlace.day.CA = 0;
                            this.salesPlace.day.averageRepair = 0;
                            this.salesPlace.day.averageSale = 0;
                            this.salesPlace.day.amountPurchase = 0;
                            this.salesPlace.day.amountRefund = 0;
                            this.salesPlace.day.finishedRepair = 0;
                            this.salesPlace.day.initiatedRepair = 0;
                            this.salesPlace.day.nbPurchase = 0;
                            this.salesPlace.day.nbRefund = 0;
                            this.salesPlace.day.sav = 0;
                            this.salesPlace.day.initiatedRepairs = 0;
                            this.salesPlace.day.finishedRepairs = 0;
                        }
                    } else {
                        this.salesPlace = { day: { CA: null }, week: { CA: null }, month: { CA: null } };
                    }
                    let week = this.salesCurrentWeek.reduce((curr, sale) => {
                        if (sale.idLieu === this.place.placeId)
                            curr = sale;
                        return curr;
                    }, {});
                    let month = this.salesCurrentMonth.reduce((curr, sale) => {
                        if (sale.idLieu === this.place.placeId)
                            curr = sale;
                        return curr;
                    }, {});
                    let CAs = [];
                    this.salesYearRepair.datasets = [];
                    this.salesYearRepair.labels = [];
                    this.salesCurrentYear.map(month => {
                        Object.keys(month).map(key => {
                            month[key] = month[key].reduce((curr, sale) => {
                                if (sale.idLieu === this.place.placeId)
                                    curr = sale;
                                return curr;
                            }, []);
                            this.salesYearRepair.labels.push(key);
                            let CA = month[key].CA ? month[key].CA : 0;
                            CAs.push(CA);
                        });
                    });
                    this.salesYearRepair.datasets.push({
                        data: CAs,
                        label: 'Chiffre d\'affaires mensuel',
                        borderColor: 'rgb(159,198,59)',
                        backgroundColor: 'rgb(159,198,59,0.1)',
                        fill: true,
                        tension: 0.3,
                    });
                    let chartInstance = this.$refs.yearRepair.chart;
                    chartInstance.data = this.salesYearRepair;
                    chartInstance.update();
                    this.salesPlace.week = week;
                    this.salesPlace.month = month;
                } else {
                    this.salesDayPayment = this.salesByPayment;
                    if (this.salesDay.length > 0) {
                        let CAs = [];
                        this.salesDay.forEach(data => {
                            if (data.name.indexOf(this.sign) !== -1 || !this.sign) {
                                CAs.push(data.CA);
                                this.salesDayData.labels.push(data.name);
                            }
                        });
                        this.salesDayData.datasets.push({
                            data: CAs,
                            label: 'CA HT',
                            borderColor: 'rgb(159,198,59)',
                            backgroundColor: 'rgb(159,198,59,0.1)',
                            borderWidth: 2,
                        });
                    }
                    this.salesPlace = { day: { CA: null }, week: { CA: null }, month: { CA: null } };
                    this.salesCurrentMonth = [];
                    this.salesCurrentWeek = [];
                    let chartInstance = this.$refs.day.chart;
                    chartInstance.data = this.salesDayData;
                    chartInstance.options.plugins.title.text = 'Chiffre d\'affaire du ' + this.salesDate;
                    chartInstance.update();
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.loader.hide('load');
            }

        },
        async filterSign() {
            if (this.sign === 'BMP') {
                this.places = this.allPlaces.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) !== -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === 'C&R') {
                this.places = this.allPlaces.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) === -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === null) {
                this.places = this.allPlaces;
            }
            this.place = null;
            await this.getSales();
        },
        async filterUser() {
            if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                this.place = this.authStore.getUser.places[0];
                await this.filterSale();
            }
        },
        async dayAfter() {
            this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).plus({ days: 1 }).toFormat('yyyy-MM-dd');
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async dayBefore() {
            this.salesDate = DateTime.fromMillis(Date.parse(this.salesDate)).minus({ days: 1 }).toFormat('yyyy-MM-dd');
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async selectDate() {
            // await this.getPlaces();
            await this.getSales();
            if(this.place)
                await this.filterSale();
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else if (this.authStore.getUser.places[0].id !== '2') {
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if(BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    }else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        toggleDisplay() {
            this.menuDisplayed = !this.menuDisplayed;
        },
    },
    setup() {
        return {
            authStore: useAuthStore(),
            loader: useLoader(),
        };
    },

});
</script>

<style scoped>
img {
    max-width: 155px;
    height: auto;
}

.sidebar--active {
    transition: all .3s ease-in-out 0s !important;
    transform: translateX(0) !important;
    opacity: 1 !important;
}

.menus-nav-layout #sidebar {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 72px;
    transform: translateX(-150%);
    transform-origin: left;
    width: 350px;
    z-index: 3;
}

.backoffice-layout #sidebar #navBar ul li {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    padding: 0;
    border-left: .125rem solid transparent;
    overflow: hidden;
}

.backoffice-layout {
    grid-template-columns: 0;
}

.menus-nav-layout #sidebar #navBar .btn {
    background-color: transparent;
}

.btn span {
    color: #333 !important;
}

.btn svg + span, button svg + span {
    margin-left: .5rem;
    text-align: left;
}

.backoffice-layout #mainHeader h1, .backoffice-layout #mainHeader h2 {
    color: #333!important;
    font-size: 1rem;
}

.btn.btn--menu {
    border: 1px solid black !important;
    color: #333 !important;

}

</style>
