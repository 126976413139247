import { defineStore } from 'pinia';

export const useLoader = defineStore('loader', {
    state: () => ({
        current: null
    }),
    getters: {
        is: (state) => {
            return (field) => {
                return state.current === field;
            };
        }
    },
    actions: {
        show(loader) {
            this.current = loader;
        },
        hide() {
            this.current = null;
        }
    }
});