<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.modelId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un lieu
                            </h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Lieu</legend>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom<abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.name.$error}"
                                                       v-model='detail.name' >
                                                <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                    un nom</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ABREVIATION -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Abréviation</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.shortName' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TYPE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <select :class="{'is-invalid': v$.detail.placeTypeId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.placeTypeId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(placeType, key) in placeTypes'
                                                            :key='key'
                                                            :value='placeType.placeTypeId'>
                                                        {{ placeType.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.placeTypeId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner un type
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col pl-4'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid'>
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ADRESSE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Adresse <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.address.$error}"
                                                       v-model='detail.address' >
                                                <p v-if='v$.detail.address.$error' class='invalid-feedback'>Veuillez saisir
                                                    une adresse</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TELEPHONE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Téléphone <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.phone.$error}"
                                                       v-model='detail.phone' >
                                                <p v-if='v$.detail.phone.$error' class='invalid-feedback'>Veuillez saisir
                                                    un numéro de téléphone</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Email <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.mail.$error}"
                                                       v-model='detail.mail' >
                                                <p v-if='v$.detail.address.$error' class='invalid-feedback'>Veuillez saisir
                                                    une adresse mail</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SITE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Site internet</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.website' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SIRET -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>N° Siret</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='number'
                                                       class='form-control'
                                                       v-model='detail.siret' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TVA -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>N° TVA</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.tva' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- DATE OUVERTURE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Date ouverture</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='date'
                                                       class='form-control'
                                                       v-model='detail.openDate' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- GOOGLE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Id Google my business</label>
                                        <div class='col-6'>
                                            <div class='col-6'>
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='detail.idGoogleMyBusiness' >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CHARTE BMP -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Charte BMP</label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col pl-4'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='charte'
                                                               :id="'charte_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.charte'>
                                                        <label class='custom-control-label'
                                                               :for="'charte_'+s.value"
                                                               :class="detail.charte === s.value ? s.class : ''">{{ s.label2 }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class='col-auto'>
                                        <button v-if='!loading && detail.placeId && modification'
                                                class='btn--color-success'
                                                @click='createPlace'>
                                            <span>Créer un nouveau lieu</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';
import { DateTime } from 'luxon';

export default {
    name: 'PlaceDetail',
    components: {
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            placeTypes: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des lieux', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des lieux', 'Lecture');
        if (!this.lecture)
            this.$router.push('/admin/place');
        await this.getPlacesTypes();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/place');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                placeId: null,
                name: null,
                address: null,
                phone: null,
                mail: null,
                status: true,
                website: null,
                placeTypeId: null,
                siret: null,
                tva: null,
                shortName: null,
                openDate: null,
                idGoogleMyBusiness: null
            };
        },
        createPlace() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/place/${this.$route.params.id}`,
                );
                this.detail = res.data;
                this.detail.openDate = this.detail.openDate ? DateTime.fromISO(this.detail.openDate).toISODate() : null;
            } catch (err) {
                console.log(err);
            }
        },
        async getPlacesTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/placeType`,
                );
                this.placeTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/place`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.placeId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                name: { required },
                address: { required },
                phone: { required },
                status: { required },
                placeTypeId: { required },
                mail: { required }
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
