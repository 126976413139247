<template>
    <div class="alert" :class="classNames" role="alert">
        <div v-if="!title" class="row no-gutters align-items-center">
            <div class="col-auto">
                <svg-icon :icon="svg" />
            </div>
            <div class="col">
                <p>{{ text }}</p>
                <p v-if="message">{{ message }}</p>
            </div>
        </div>
        <div v-if="title" class="row justify-content-center">
            <div class="col-auto">
                <figure class="w-20 mb-2">
                    <svg-icon :icon="svg" />
                </figure>
            </div>
        </div>
        <div v-if="title" class="row justify-content-center">
            <div class="col-auto">
                <h1 class="mt-2 text-center">{{ title }}</h1>
                <p v-if="text" class="text-center">{{ text }}</p>
                <p v-if="message" class="text-center">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import svgIcon from './SvgIcon.vue';
import svgs from '../_svg/svgComponents';

export default {
    name: 'BmAlert',
    components: {
        svgIcon
    },
    props: {
        type: {
            type: String,
            default: 'success'
        },
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        message: {
            type: String,
            default: null
        },
        shake: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        }
    },
    computed: {
        classNames: function() {
            const shake = this.shake ? ' shake--horizontal' : '';
            return {
                success: 'alert--success',
                info: 'alert--info',
                error: 'alert--danger',
                warning: 'alert--warning',
            }[this.type] + shake;
        },
        svg: function() {
            return this.icon || {
                success: svgs.svgCheckBadge.raw,
                info: svgs.svgInformationCircle.raw,
                error: svgs.svgAlertTriangle.raw,
                warning: svgs.svgAlertDiamond.raw,
            }[this.type];
        }
    }
};
</script>
