<template>
    <div></div>
</template>

<script>
// Creates an svg element
export default {
    name: 'SvgIcon',
    props: {
        // raw svg or promise from import() statement
        icon: {
            type: [String, Object],
            default: null
        },
        class: {
            type: String,
            default: ''
        }
    },
    async mounted() {
        await this.loadIcon(this.icon);
    },
    watch: {
        icon: {
            immediate: false,
            handler: async function(value) {
                await this.loadIcon(value);
            }
        },
    },
    methods: {
        async loadIcon(svg) {
            if (this.icon && this.icon.then) {
                const { default: imported } = await this.icon;
                svg = imported;
            }

            if (svg) {
                this.$el.innerHTML = svg;
                if (this.class) {
                    this.$el.children[0].setAttribute('class', this.class);
                }
                this.$el.replaceWith(this.$el.children[0]);
            }
        }
    }
};
</script>
