<template>
    <div>
        <div class="spinner"
             v-if="!embed"
             tabindex="-1"
             role="status"
             v-bind:class="{'spinner--active': active, 'spinner--noBackdrop': noBackdrop, 'spinner--transparent': transparent, 'spinner--small': small}">
            <div class="spinner-content ">
                <div class="spinner-content-icon"></div>
                <p class="spinner-content-msg">{{ message }}</p>
            </div>
        </div>
        <div class="spinner--embed row justify-content-center align-items-center"
             v-if="embed">
            <div class="spin--infinite">
                <svg-loader />
            </div>
            <p class="ml-2">{{ message }}</p>
        </div>
    </div>
</template>

<script>
import svgs from '../_svg/svgComponents';

export default {
    name: 'BmLoader',
    components: {
        ...svgs
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: null
        },
        noBackdrop: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        embed: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style>
.spinner--embed svg {
    height: 2rem;
}
.spinner--embed p {
    margin-bottom: 0rem;
}
</style>
