<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card shadow-sm">
                <div class="card-body">
                    <h3 class="card-title"
                        v-if='lecture'>Saisie des objectifs</h3>
                    <notif type="error"
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if="!lecture"/>
                    <div class="row">
                        <div class='col-md-2'>
                            <select class='form-control'
                                    v-model='sign'
                                    @change='filterSign'
                                    :disabled="oneSign"
                                    required>
                                <option :value=null>Sélectionner une marque</option>
                                <option value='BMP'>BeeMyPhone</option>
                                <option value='C&R'>Cash & Repair</option>
                            </select>
                        </div>
                        <div class='col-md-3'>
                            <select class='form-control'
                                    v-model='place'
                                    @change='filterPlace'
                                    required>
                                <option :value=null>Sélectionner un lieu</option>
                                <option v-for='(place, key) in places'
                                        :key='key'
                                        :value='place'>
                                    {{ place.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="place">
                        <div class="row mt-4 p-2">
                            <datepicker v-model='detail.year'
                                        name='minDateValidity'
                                        :minimum-view="'year'"
                                        :maximum-view="'year'"
                                        language='fr'
                                        style='margin: 0 5px;' />
                        </div>
                        <div class="row">
                            <div class="col-sm-6 mt-4">
                                <form>
                                    <fieldset>
                                        <legend>CA</legend>
                                        <div class='row form-group'
                                             v-for='(goal, key) in detail.goals'
                                             :key='key'>
                                            <label class='col-6 col-form-label' v-if="goal.type==='sale'">{{ goal.month }}</label>
                                            <div class='col-6' v-if="goal.type==='sale'">
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='goal.value'
                                                       :disabled="!modification || !isMyPlace">
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div class="col-sm-6 mt-4">
                                <form>
                                    <fieldset>
                                        <legend>Rachat</legend>
                                        <div class='row form-group'
                                             v-for='(goal, key) in detail.goals'
                                             :key='key'>
                                            <label class='col-6 col-form-label' v-if="goal.type==='purchase'">{{ goal.month }}</label>
                                            <div class='col-6' v-if="goal.type==='purchase'">
                                                <input type='text'
                                                       class='form-control'
                                                       v-model='goal.value'
                                                       :disabled="!modification || !isMyPlace">
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='card-footer'>
                    <div class='row justify-content-center'>
                        <div class='col-auto'>
                            <notif type='success'
                                   text='Sauvegarde effectuée'
                                   v-if='success'/>
                            <notif type='error'
                                   text='Un problème est survenu durant la sauvegarde'
                                   :message='this.msgError'
                                   v-if='error'/>
                        </div>
                    </div>
                    <div class='row is-animated d-flex justify-content-end' v-if="this.place">
                        <div class='col-auto'>
                            <button
                                v-if='!loading && modification && isMyPlace'
                                class='btn--color-success'
                                @click='submit'
                                :disabled='v$.$error'>
                                <span>Sauvegarder</span>
                                <svg-floppy-disk />
                            </button>
                            <button
                                v-if='loading'
                                class='btn--color-success'
                                type='button'
                                disabled>
                                <span>Sauvegarde en cours...</span>
                                <figure class='spin--infinite mr-2'>
                                    <svg-floppy-disk />
                                </figure>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import svgComponent from '@/_svg/svgComponents';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import notif from '@/components/Notif.vue';
import Datepicker from 'vuejs3-datepicker';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { DateTime } from 'luxon';

export default {
    name: 'CrGoals',
    components: {
        Datepicker,
        ...svgComponent,
        notif,
    },
    data() {
        return {
            lecture: false,
            modification: false,
            detail: {},
            places: [],
            allPlaces: [],
            place: null,
            sign: null,
            oneSign: false,
            loading: false,
            success: false,
            error:false,
            isMyPlace: false,
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des objectifs', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des objectifs', 'Lecture');
        await this.getPlaces();
        await this.filterSign();
    },
    methods: {
        initDate() {
            let year;
            if(typeof (this.detail.year) === 'undefined') {
                year = DateTime.now().toFormat('yyyy');
            }else{
                year = this.detail.year;
            }
            let date = `${year}-01-01`;
            this.detail.year = DateTime.fromISO(date).toHTTP();
        },
        createGoals() {
            this.detail.goals = [
                { goalId: null, month: 'Janvier', value: null, type: 'sale' },
                { goalId: null, month: 'Février', value: null, type: 'sale' },
                { goalId: null, month: 'Mars', value: null, type: 'sale' },
                { goalId: null, month: 'Avril', value: null, type: 'sale' },
                { goalId: null, month: 'Mai', value: null, type: 'sale' },
                { goalId: null, month: 'Juin', value: null, type: 'sale' },
                { goalId: null, month: 'Juillet', value: null, type: 'sale' },
                { goalId: null, month: 'Août', value: null, type: 'sale' },
                { goalId: null, month: 'Septembre', value: null, type: 'sale' },
                { goalId: null, month: 'Octobre', value: null, type: 'sale' },
                { goalId: null, month: 'Novembre', value: null, type: 'sale' },
                { goalId: null, month: 'Décembre', value: null, type: 'sale' },
                { goalId: null, month: 'Janvier', value: null, type: 'purchase' },
                { goalId: null, month: 'Février', value: null, type: 'purchase' },
                { goalId: null, month: 'Mars', value: null, type: 'purchase' },
                { goalId: null, month: 'Avril', value: null, type: 'purchase' },
                { goalId: null, month: 'Mai', value: null, type: 'purchase' },
                { goalId: null, month: 'Juin', value: null, type: 'purchase' },
                { goalId: null, month: 'Juillet', value: null, type: 'purchase' },
                { goalId: null, month: 'Août', value: null, type: 'purchase' },
                { goalId: null, month: 'Septembre', value: null, type: 'purchase' },
                { goalId: null, month: 'Octobre', value: null, type: 'purchase' },
                { goalId: null, month: 'Novembre', value: null, type: 'purchase' },
                { goalId: null, month: 'Décembre', value: null, type: 'purchase' },
            ];
            this.detail.placeId = this.place.placeId;

        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999', '3'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else if (this.authStore.getUser.places[0].id !== '2') {
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if(BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    }else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async filterSign() {
            if (this.sign === 'BMP') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) !== -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === 'C&R') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) === -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === null) {
                this.places = this.allPlaces;
            }
            this.place = null;
        },
        async filterPlace() {
            this.initDate();
            let res = await this.getDetail();
            this.detail.year = res.year;
            if(res.goals.length === 0) {
                this.createGoals();
            }else{
                this.createGoals();
                this.detail.goals.map(d => {
                    res.goals.forEach(r => {
                        if( d.month === r.month && d.type === r.type) {
                            d.goalId = r.goalId;
                            d.value = r.value;
                        }
                    });
                });
                this.detail.placeId = res.placeId;
            }
            let ids = this.authStore.getUser.places.reduce((curr, place) => {
                curr.push(place.id);
                return curr;
            }, []);
            this.isMyPlace = ids.indexOf(this.place.placeId) !== -1 || this.authStore.getUser.places[0].id === '2';
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goals/place/${this.place.placeId}/date/${DateTime.fromHTTP(this.detail.year).toFormat('yyyy')}`
                );
                return res.data;
            } catch (err) {
                this.error = true;
                this.loading = false;
                this.msgError = err.response.data.message;
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/goals`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        loader: useLoader()
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                year: { required },
                goals : [
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                    {
                        month : { required },
                        value: { required }
                    },
                ]
            },
        };

        return base;
    }
};

</script>

<style>
.card {
    min-height: 100% !important;
}
</style>
