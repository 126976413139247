<template>
    <section id='mainContent' class="h-100">
        <div class='container-fluid h-100'>
            <div class='row h-100'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm h-100'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='lecture'>
                                Documents
                            </h3>
                            <notif type="error"
                                   text="Vous n'êtes pas autorisé à consulter ce module."
                                   v-if="!lecture"/>
                            <div class="row align-items-center" v-if="lecture">
                                <div class='col-auto'>
                                    <datepicker v-model='date'
                                                @input='selectDate'
                                                name='minDateValidity'
                                                :minimum-view="'month'"
                                                :maximum-view="'year'"
                                                :full-month-name="true"
                                                :format = "'MMMM yyyy'"
                                                language='fr'/>
                                </div>
                                <div class='col-md-2'>
                                    <select class='form-control'
                                            v-model='sign'
                                            @change='filterSign'
                                            :disabled="oneSign"
                                            required>
                                        <option :value=null>Sélectionner une marque</option>
                                        <option value='BMP'>BeeMyPhone</option>
                                        <option value='C&R'>Cash & Repair</option>
                                    </select>
                                </div>
                                <div class='col-md-3'>
                                    <select class='form-control'
                                            v-model='place'
                                            @change='filterPlace'
                                            required>
                                        <option :value=null>Sélectionner un lieu</option>
                                        <option v-for='(place, key) in places'
                                                :key='key'
                                                :value='place'>
                                            {{ place.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="container mt-3">
                                <notif type="info"
                                       text="Veuillez sélectionner un lieu."
                                       v-if="!place && lecture"/>
                                <notif type="error"
                                       text="Aucun document disponible"
                                       v-if="(!list.documents || list.documents.length === 0) && place"/>
                                <div class="row">
                                    <div class="col">
                                        <table class="table">
                                            <tbody>
                                                <tr v-for="(doc, key) in list.documents"
                                                    :key='key'>
                                                    <td>{{ doc.name }}</td>
                                                    <td><button type="button" class="btn--outline btn--color--danger btn--small" @click="openDocument(doc.name)" >
                                                        <svg-eye />
                                                    </button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <grapp-loader :active="loader.is('loadDoc')"
                                                  :message="'Chargement en cours...'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import notif from '@/components/Notif.vue';
import svgComponent from '@/_svg/svgComponents';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import Datepicker from 'vuejs3-datepicker';
import { DateTime } from 'luxon';
import grappLoader from '@/components/Loader.vue';

export default {
    name: 'DocumentList',
    components: { grappLoader, Datepicker, notif,  ...svgComponent, },
    data() {
        return {
            list: {},
            lecture: false,
            places: [],
            allPlaces: [],
            place: null,
            sign: null,
            oneSign: false,
            date: DateTime.now().minus({ month: 1 }).startOf('month').toHTTP(),
        };
    },
    async mounted() {
        this.lecture = await this.authStore.moduleRight('Documents', 'Lecture');
        if(this.lecture) {
            await this.getPlaces();
            // await this.filterSign();
        }
    },
    methods: {
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999', '3'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                    this.places = this.places.reduce((curr, pl) => {
                        if(pl.placeId === this.authStore.getUser.places[0].id)
                            curr.push(pl);
                        return curr;
                    }, []);
                } else if (this.authStore.getUser.places.length > 1) {
                    this.places = this.places.reduce((curr, pl) => {
                        if(this.authStore.getUser.places.find(p => p.id === pl.placeId)) {
                            curr.push(pl);
                        }
                        return curr;
                    }, []);
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if (BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    } else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    } else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async filterSign() {
            if (this.sign === 'BMP') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) !== -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === 'C&R') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) === -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === null) {
                this.places = this.allPlaces;
            }
            this.place = null;
        },
        async filterPlace() {
            this.list.month = DateTime.fromMillis(Date.parse(this.date)).toFormat('yyyy-MM-dd');
            this.list.documents = await this.getList();
        },
        async selectDate() {
            if(this.place) {
                await this.filterPlace();
            }
        },
        async getList() {
            try {
                this.loader.show('loadDoc');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/documents/place/${this.place.placeId}/date/${this.list.month}`
                );
                return res.data;
            } catch (err) {
                console.error(err);
            }finally {
                this.loader.hide('loadDoc');
            }
        },
        openDocument(fileName) {
            const date = this.list.month.split('-');
            const year = date[0];
            const month = date[1];
            window.open(`https://cerebro.margueritegroup.fr/assets/documents/${year}/${month}/${this.place.placeId}/${fileName}`);
        }
    },
    setup: () => ({
        authStore: useAuthStore(),
        loader: useLoader()
    })
};
</script>

<style scoped>
    .vuejs3-datepicker__value {
        padding: 6px 15px !important;
    }
</style>
