<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.productId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un produit
                            </h3>
                            <div class='row'>
                                <div class='col-sm-6 mt-6'>
                                    <form>
                                        <fieldset>
                                            <legend>Produit</legend>
                                            <!-- MARQUE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Marque <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.brandId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(brand, key) in brands'
                                                                    :key='key'
                                                                    :value='brand.brandId'>
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.brandId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une marque
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TYPE PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Type <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.productTypeId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(productType, key) in productTypes'
                                                                    :key='key'
                                                                    :value='productType.productTypeId'>
                                                                {{ productType.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.productTypeId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un Type d'appareil
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MODELE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Modèle <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.modelId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.modelId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(model, key) in models'
                                                                    :key='key'
                                                                    :value='model.modelId'>
                                                                {{ model.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.modelId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un modèle
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix de vente TBE TTC <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               :class="{'is-invalid': v$.detail.price.$error}"
                                                               v-model='detail.price' >
                                                        <p v-if='v$.detail.price.$error' class='invalid-feedback'>
                                                            Veuillez saisir prix</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COULEUR -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Couleur <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select class='form-control'
                                                                :class="{'is-invalid': v$.detail.colorId.$error}"
                                                                v-model='detail.colorId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(color, key) in colors'
                                                                    :key='key'
                                                                    :value='color.colorId'>
                                                                {{ color.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.colorId.$error' class='invalid-feedback'>
                                                            Veuillez sélectionner une couleur</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- CAPACITE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Capacité</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.capacityId'
                                                                    required
                                                                    :disabled='!detail.romUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in capacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.romUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in units'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- RAM -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>RAM</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramId'
                                                                    :disabled='!detail.ramUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in ramCapacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in ramUnits'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- CARTE GRAPHIQUE -->
                                            <div class='row form-group' v-if="detail.productTypeId == 4">
                                                <label class='col-6 col-form-label'>Carte graphique</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input class='form-control'
                                                               type='text'
                                                               v-model='detail.graphicCard' >
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PROCESSEUR -->
                                            <div class='row form-group' v-if="detail.productTypeId == 4">
                                                <label class='col-6 col-form-label'>Processeur</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.processor'>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Puissance -->
                                            <div class='row form-group' v-if="detail.productTypeId == 4">
                                                <label class='col-6 col-form-label'>Puissance (en GHz)</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.processorPower'>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- OS -->
                                            <div class='row form-group' v-if="detail.productTypeId == 4">
                                                <label class='col-6 col-form-label'>Système d'exploitation</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='text'
                                                               class='form-control'
                                                               v-model='detail.operatingSystem'>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- ETAT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Etat <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col'>
                                                            <div
                                                                class='custom-control custom-radio custom-control-inline'
                                                                v-for='s in status'
                                                                :key='s.value'>
                                                                <input class='custom-control-input'
                                                                       type='radio'
                                                                       name='status'
                                                                       :class="{'is-invalid': v$.detail.status.$error}"
                                                                       :id="'status_'+s.value"
                                                                       :value='s.value'
                                                                       v-model='detail.status'>
                                                                <label class='custom-control-label'
                                                                       :for="'status_'+s.value"
                                                                       :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                                       }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='row' v-if='v$.detail.status.$error'>
                                                        <div class='col'>
                                                            <input type='hidden' class='form-control is-invalid' >
                                                            <p class='invalid-feedback'>
                                                                Veuillez sélectionner un état
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class='col-sm-6 mt-6' v-if='detail.productId'>
                                    <fieldset>
                                        <legend>Informations supplémentaires</legend>
                                        <!-- CHENAI -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix Chenaï</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.chenaiPrice'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDV BE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de vente BE</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdvBe'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDV BE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de vente EC</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdvEc'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDR TBE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de rachat TBE</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdrTbe'
                                                           :readonly='!detail.productId'>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDR BE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de rachat BE</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdrBe'
                                                           :readonly='!detail.productId' >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDR EC -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de rachat EC</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdrEc'
                                                           :readonly='!detail.productId' >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PDR PIECES -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Prix de rachat Pour Pièces</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='number'
                                                           min='0'
                                                           class='form-control'
                                                           v-model='detail.pdrPieces'
                                                           :readonly='!detail.productId' >
                                                </div>
                                            </div>
                                        </div>
                                        <div class='row form-group'>
                                            <button
                                                v-if='!loading && modification'
                                                class='btn--color-success'
                                                @click='getInitialPurchasePrice'
                                                :disabled='v$.$error'>
                                                <span>Réinitialiser les prix de rachat</span>
                                                <svg-move-back />
                                            </button>
                                            <button
                                                v-if='loading'
                                                class='btn--color-success'
                                                type='button'
                                                disabled>
                                                <span>Récupération en cours...</span>
                                                <figure class='spin--infinite mr-2'>
                                                    <svg-move-back />
                                                </figure>
                                            </button>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row is-animated d-flex justify-content-between'>
                                <div class="col-auto">
                                    <button v-if="!loading && detail.productId && modification"
                                            class="btn--color-success"
                                            @click="createProduct">
                                        <span>Créer un nouveau produit</span>
                                        <svg-add />
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row mt-3' v-if='detail.productId'>
                <div class='col-sm-6'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image face</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image && !image.error'>
                                    <img class='img-fluid' :src='detail.image' >
                                </div>
                                <div class='col-auto' v-if='(!detail.image || image.error) && !image.loading'>
                                    <notif type='error'
                                           :text='image.errorText'/>
                                </div>
                                <div class='col-auto' v-if="!detail.image && image.loading">
                                    <notif type='info'
                                           text="'Chargement en cours'"/>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-end'>
                                <div class='col-auto is-animated d-flex'>
                                    <input type='file'
                                           id='fileUpload1'
                                           name='fileUpload'
                                           @change='handleImage'
                                           hidden >
                                    <button v-if='!image.loading && modification'
                                            class='btn--outline'
                                            @click='selectFile'>
                                        <span>Modifier l'image</span>
                                        <svg-download />
                                    </button>
                                    <button v-if='image.loading'
                                            class=''
                                            type='button'
                                            disabled>
                                        <span>Upload en cours...</span>
                                        <figure class='spin--infinite ml-2'>
                                            <svg-download />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>
                                <small>Format: JPG/PNG - Max: 300Ko</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-sm-6'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image dos</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image_2 && !image2.error'>
                                    <img class='img-fluid' :src="detail.image_2" >
                                </div>
                                <div class='col-auto' v-if='(!detail.image_2 || image2.error) && !image2.loading'>
                                    <notif type='error'
                                           :text='image2.errorText'/>
                                </div>
                                <div class='col-auto' v-if="!detail.image2 && image2.loading">
                                    <notif type='info'
                                           text="'Chargement en cours'"/>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-end'>
                                <div class='col-auto is-animated d-flex'>
                                    <input type='file'
                                           id='fileUpload2'
                                           name='fileUpload'
                                           @change='handleImage'
                                           hidden >
                                    <button v-if='!image2.loading && modification'
                                            class='btn--outline'
                                            @click='selectFile2'>
                                        <span>Modifier l'image</span>
                                        <svg-download />
                                    </button>
                                    <button v-if='image2.loading'
                                            class=''
                                            type='button'
                                            disabled>
                                        <span>Upload en cours...</span>
                                        <figure class='spin--infinite ml-2'>
                                            <svg-download />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>
                                <small>Format: JPG/PNG - Max: 300Ko</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { useProductStore } from '@/stores/product.store';
import { useAuthStore } from '@/stores/auth.store';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import svgComponent from '../../_svg/svgComponents';
import notif from '@/components/Notif.vue';

export default {
    name: 'ProductDetail',
    components: { ...svgComponent, notif },
    data() {
        return {
            detail: [],
            models: [],
            productTypes: [],
            colors: [],
            units: [],
            ramUnits: [],
            brands: [],
            capacities: [],
            ramCapacities: [],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            isCreate: false,
            image: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
                number: 1,
            },
            image2: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
            },
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des produits', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des produits', 'Lecture');
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/product');
            this.isCreate = true;
            this.createNewDetail();
        } else {
            if (this.productStore.getList.length === 0) {
                await this.productStore.getProducts();
            } else {
                const res = await this.productStore.getLastProducts();
                if (res.data.length > 0) {
                    let lastProducts = res.data;
                    lastProducts.forEach(lastProd => {
                        const index = this.productStore.getList.findIndex(prod => prod.productId === lastProd.productId);
                        if (index !== -1) {
                            this.productStore.update(lastProd, index);
                        } else {
                            this.productStore.set(lastProd);
                        }
                    });
                    this.productStore.sort();
                }
            }
            this.detail = await this.productStore.getProduct(this.$route.params.id);
            this.detail.name = (this.detail.brandName + ' ' + this.detail.modelName + ' ' + this.detail.color + ' ' + this.detail.rom).toLowerCase();
            this.detail.name = this.detail.name.charAt(0).toUpperCase() + this.detail.name.slice(1);
        }
        await this.getBrands();
        await this.getProductTypes();
        await this.getColors();
        await this.getUnits();
        await this.getRamUnits();

        if(!this.lecture)
            this.$router.push("/admin/product");
    },
    watch: {
        'detail.brandId': async function(val, oldVal) {
            if (val !== oldVal && this.detail.productTypeId)
                await this.getModels();
        },
        'detail.productTypeId': async function(val, oldVal) {
            if (val !== oldVal && this.detail.brandId)
                await this.getModels();
        },
        'detail.romUnitId': async function(val, oldVal) {
            if (val !== oldVal && val !== null)
                await this.getCapacities();
        },
        'detail.ramUnitId': async function(val, oldVal) {
            if (val !== oldVal && val !== null)
                await this.getRamCapacities();
        },
        'detail.modelId': async function(val, oldVal) {
            if (val !== oldVal && this.detail.modelId) {
                let model = this.models ? this.models.find(mod => mod.modelId === val) : {};
                this.detail.releaseDate = model ? model.releaseDate : this.detail.releaseDate;
            }
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                productId: null,
                price: null,
                image: null,
                image_2: null,
                ramId: null,
                modelId: null,
                capacityId: null,
                colorId: null,
                brandId: null,
                romUnitId: null,
                ramUnitId: null,
                graphicCard: null,
                processor: null,
                processorPower: null,
                operatingSystem: null,
                releaseDate: null
            };
        },
        createProduct() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getModels() {
            try {
                if (this.detail.brandId && this.detail.productTypeId) {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.productTypeId}`,
                    );
                    this.models = res.data.filter(m => m.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },async getRamUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.ramUnits = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.romUnitId}`,
                    );
                    this.capacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getRamCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.ramUnitId}`,
                    );
                    this.ramCapacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        selectFile() {
            this.image.number = 1;
            document.getElementById('fileUpload1').click();
        },
        selectFile2() {
            this.image.number = 2;
            document.getElementById('fileUpload2').click();
        },
        handleImage(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                if (this.image.number === 1) {
                    this.image.error = true;
                    return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                } else {
                    this.image2.error = true;
                    return this.image2.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                }
            }

            if (img.size > 300000) {
                if (this.image.number === 1) {
                    this.image.error = true;
                    return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                } else {
                    this.image2.error = true;
                    return this.image2.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                }
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadImage(e.target.result);
            };

            reader.readAsDataURL(img);
        },
        async uploadImage(image) {
            if (this.image.number === 1) {
                this.image.error = false;
                this.image.loading = true;
            } else {
                this.image2.error = false;
                this.image2.loading = true;
            }

            try {
                const data = {
                    newImage: image,
                    oldImage: this.detail.image,
                    number : this.image.number
                };
                if(this.image.number === 1) {
                    this.detail.image = null;
                }else{
                    this.detail.image_2 = null;
                }

                const res = await this.axios.post(`${window.location.origin}/api/crd/product/${this.detail.productId}/image`, data);
                this.image.loading = false;
                this.image2.loading = false;

                this.detail.image = res.data.image;
                this.detail.image_2 = res.data.image_2;
            } catch (err) {
                if (this.image.number === 1) {
                    this.image.error = true;
                    this.image.loading = false;
                } else {
                    this.image2.error = true;
                    this.image2.loading = false;
                }
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getInitialPurchasePrice() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/product/initial-purchase-price?price=${this.detail.price}
                    &releaseDate=${this.detail.releaseDate}&productTypeId=${this.detail.productTypeId}&brandId=${this.detail.brandId}`,
                );
                this.detail.pdrTbe = res.data.tbe;
                this.detail.pdrBe = res.data.be;
                this.detail.pdrEc = res.data.ec;
                this.detail.pdrPieces = parseInt(res.data.pieces);
            } catch (err) {
                console.log(err);
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/product`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.detail = res.data[0];
                    // if(this.isCreate){
                    //     this.productStore.update(this.detail);
                    // }else{
                    //     this.productStore.set(this.detail)
                    //     this.goTo(this.detail.productId)
                    // }
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
            productStore: useProductStore(),
            authStore: useAuthStore()
        };
    },
    validations() {
        return {
            detail: {
                $autoDirty: true,
                brandId: { required },
                modelId: { required },
                productTypeId: { required },
                price: { required },
                colorId: { required },
                status: { required }
            },
        };
    },

};
</script>

<style scoped>

</style>
