<script>
import $ from 'jquery';
import _ from 'lodash';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons/js/buttons.html5';
import { useProductStore } from '@/stores/product.store';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';

export default {
    name: 'ProductList',
    components: { ...svgComponent, notif },
    data() {
        return {
            products: [],
            columns: [
                {
                    data: 'productId',
                    title: 'id',
                },{
                    data: 'productTypeName',
                    title: 'Type Produit'
                },{
                    data: 'modelName',
                    title: 'Modèle',
                },{
                    data: 'brandName',
                    title: 'Marque',
                }, {
                    data: 'color',
                    title: 'Couleur',
                },{
                    data: 'rom',
                    title : 'Capacité'
                }, {
                    data: 'ram',
                    title: 'RAM'
                }, {
                    data: 'image',
                    title: 'Image face',
                    render: (data) => {
                        return data ? `<img src="${data}" style="max-height: 48px"/>` : null;
                    }
                }, {
                    data: 'image_2',
                    title: 'Image dos',
                    render: (data) => {
                        return data ? `<img src="${data}" style="max-height: 48px"/>` : null;
                    }
                },{
                    data: 'chenaiPrice',
                    title: 'Prix Chenaï'

                },{
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class='${data ? this.status[data].class : this.status['false'].class}'>${data ? this.status[data].label : this.status['false'].label}</span>`;
                    },
                },{
                    data: 'price',
                    title: 'Prix de vente conseillé TBE',
                },{
                    data: 'pdvBe',
                    title: 'Prix de vente conseillé BE',
                }, {
                    data: 'pdvEc',
                    title: 'Prix de vente conseillé EC',
                }, {
                    data: 'pdrTbe',
                    title: 'Prix de rachat conseillé TBE',
                }, {
                    data: 'pdrBe',
                    title: 'Prix de rachat conseillé BE',
                }, {
                    data: 'pdrEc',
                    title: 'Prix de rachat conseillé EC',
                }, {
                    data: 'pdrPieces',
                    title: 'Prix de rachat conseillé pour pièces',
                },{
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    }
                },
            ],
            loading: false,
            error: false,
            table: null,
            status: {
                'true': {
                    label: 'Actif',
                    class: 'text-success',
                },
                'false': {
                    label: 'Inactif',
                    class: 'text-danger',
                },
            },
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des produits', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des produits', 'Lecture');
        this.loader.show('load');
        if(this.productStore.getList.length === 0) {
            await this.productStore.getProducts();
        }else{
            const res = await this.productStore.getLastProducts();
            if(res.data.length > 0 ) {
                let lastProducts = res.data;
                lastProducts.forEach(lastProd => {
                    const index = this.productStore.getList.findIndex(prod => prod.productId === lastProd.productId);
                    if(index !== -1) {
                        this.productStore.update(lastProd, index);
                    }else {
                        this.productStore.set(lastProd);
                    }
                });
                this.productStore.sort();
            }
            // await this.productStore.getProducts()
        }

        this.products = this.productStore.getList;
        this.loader.hide('load');
        this.table = this.initDt(this.columns, this.products);
    },
    methods: {
        goTo(product) {
            this.$router.push(`product/${product.productId}`);
        },
        createProduct() {
            this.$router.push("product/create");
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                buttons: ['searchBuilder'],
                deferRender: true,
                stateSave: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageLength: 100,
                scrollY: 100,
                scrollX: true,
                order: [[18, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bflpti',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo(row);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            productStore : useProductStore(),
            authStore: useAuthStore(),
            loader: useLoader()
        };
    }
};
</script>

<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card h-100 shadow-sm">
                <div class="card-body d-flex flex-column h-100">
                    <h3 class="card-title" v-if="lecture">Liste des produits</h3>
                    <div class="flex-fill" :hidden="!lecture">
                        <table
                            ref="table"
                            class="table table-striped table-hover table-handyRows"></table>
                    </div>
                    <notif type='error'
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if='!lecture'/>
                </div>
                <div class="card-footer">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <notif type="error"
                                   text="Un problème est survenu pendant la création du produit"
                                   v-if="error"/>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto is-animated d-flex">
                            <button v-if="!loading && modification"
                                    class="btn--color-success"
                                    @click="createProduct">
                                <span>Créer un nouveau produit</span>
                                <svg-add />
                            </button>
                            <button v-if="loading"
                                    class="btn--color-success"
                                    disabled>
                                <figure class="spin--infinite mr-2">
                                    <svg-add />
                                </figure>
                                <span>Sauvegarde en cours...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
