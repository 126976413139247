<template>
    <div class="backoffice-layout theme-bookstore" :class="classEnv">
        <div id='sidebar' :class="{ 'sidebar--active': menuDisplayed }">
            <div class='container-fluid p-0'>
                <nav id='navBar' style='height: 800px; overflow: auto;'>
                    <ul>
                        <li v-for='m in menu'
                            :class="{ 'active' : m.active }"
                            :key='m'
                            :hidden='!moduleRight(m.name, "Lecture") && !m.subMenu'>
                            <a href
                               @click='select(m, $event)'
                               class='btn border-0 d-flex justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <svg-icon :icon='m.icon' />
                                    <span>{{ m.name }}</span>
                                </div>
                                <slot v-if='m.subMenu && m.active'>
                                    <svg-arrow-up />
                                </slot>
                                <slot v-if='m.subMenu && !m.active'>
                                    <svg-arrow-down />
                                </slot>
                            </a>
                            <ul v-if='m.subMenu && m.subMenu.length > 0 && m.active'>
                                <li :class="{ 'active' : s.active }"
                                    v-for='s in m.subMenu'
                                    :key='s'
                                    :hidden='!moduleRight(s.name, "Lecture")'>
                                    <a href
                                       @click='select(s, $event)'
                                       class='btn border-0'>
                                        <svg-icon :icon='s.icon' />
                                        <span>{{ s.name }}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <header id='mainHeader'>
            <div class='container-fluid row align-items-center justify-content-between m-0  p-0 h-100' >
                <div id='mainLogo' class='col-lg-auto d-none d-lg-block p-0'>
                    <button ui-sref='css-home' @click="this.$router.push({ name: 'tableau-de-bord' })">
                        <img src='../assets/grapp/dist/assets/images/cerebro-removebg-preview6.png'
                             alt='CEREBRO Back-office'>
                    </button>
                </div>
                <div class='col-auto p-0 justify-content-start'>
                    <button type='button' @click='toggleDisplay' class='btn btn--menu btn--color-black py-1 mx-2 px-2'>
                        <svg-menu />
                        <span class='d-none d-sm-block'>Menu</span>
                    </button>
                </div>
                <div class='col col-xl-3 p-2'>
                    <h1 class='mt-n1'><small><strong>CEREBRO ({{ version }})</strong></small></h1>
                    <h2>{{ $route.meta.subtitle }}</h2>
                </div>
                <div class='col col-xl-5 text-center d-none d-lg-block'>
                    <h3>{{ date }}<br>{{ time }}</h3>
                </div>
                <div class='col-auto d-flex align-items-center justify-content-end'>
                    <h2 class='d-none d-lg-block pr-2'>{{ authStore.getDisplayName.toUpperCase() }}</h2>
                    <button @click='logout'>
                        <svg-logout />
                    </button>
                </div>
            </div>
        </header>
        <grapp-loader :active="loader.is('load')"
                      :message="'Chargement en cours...'"/>
        <router-view/>
    </div>
</template>

<script>

import { useAuthStore } from '@/stores/auth.store';
import svgComponents from '../_svg/svgComponents';
import svgs from '../_svg/svgComponents';
import svgIcon from '@/components/SvgIcon.vue';
import grappLoader from '@/components/Loader.vue';
import { useLoader } from '@/stores/loader';

const dateOpts = { weekday: 'long', month: 'long', day: 'numeric' };
const timeOpts = { hour: '2-digit', minute: '2-digit' };

export default {
    // eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
    name: 'Main',
    components: {
        grappLoader,
        ...svgComponents, svgIcon
    },
    data() {
        return {
            menu: [
                {
                    icon: svgs.svgLayoutDashboard.raw,
                    name: 'Tableau de bord',
                    stateName: 'tableau-de-bord',

                },
                {
                    icon: svgs.svgTaskListStar.raw,
                    name: 'Rapport d\'activité',
                    stateName: 'admin-report-activity',
                },
                {
                    icon: svgs.svgOfficeFileAdobe.raw,
                    name: 'Documents',
                    stateName: 'admin-document',
                },
                {
                    name: 'Catalogues',
                    subMenu: [{
                        name: 'Administration des composants',
                        stateName: 'admin-components',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des réparations',
                        stateName: 'admin-repairs',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des produits',
                        stateName: 'admin-products',
                        icon: svgs.svgListBullets.raw,
                    }, {
                        name: 'Administration des modèles',
                        stateName: 'admin-models',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des appareils',
                        stateName: 'admin-devices',
                        icon: svgs.svgListBullets.raw,
                    },
                    {
                        name: 'Administration des goodies',
                        stateName: 'admin-goodies',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des accessoires',
                        stateName: 'admin-accessory',
                        icon: svgs.svgListBullets.raw,
                    },],
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Stocks',
                    subMenu: [
                        {
                            name: 'Administration des stocks',
                            stateName: 'admin-stocks',
                            icon: svgs.svgPerformancePhoneIncrease.raw,
                        },{
                            name: 'Transfert de stock',
                            stateName: 'admin-stocks-transfer',
                            icon: svgs.svgPerformancePhoneIncrease.raw,
                        },
                    ],
                    icon: svgs.svgPerformancePhoneIncrease.raw,
                },
                {
                    name: 'Exports',
                    subMenu: [
                        {
                            name: 'Export des ventes',
                            stateName: 'admin-export-vente',
                            icon: svgs.svgFileCsv.raw,
                        },
                        {
                            name: 'Export des rachats',
                            stateName: 'admin-export-rachat',
                            icon: svgs.svgFileCsv.raw,
                        },
                        {
                            name: 'Export des stocks',
                            stateName: 'admin-export-stock',
                            icon: svgs.svgFileCsv.raw,
                        },
                    ],
                    icon: svgs.svgFileCsv.raw,
                },
                {
                    name: 'Essentiels',
                    subMenu: [
                        {
                            name: 'Administration des marques',
                            stateName: 'admin-brands',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des groupes',
                            stateName: 'admin-groups',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types de composant',
                            stateName: 'admin-component-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types de goodies',
                            stateName: 'admin-goodies-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types d\'accessoire',
                            stateName: 'admin-accessory-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des textures',
                            stateName: 'admin-texture',
                            icon: svgs.svgListBullets.raw,
                        },
                    ],
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Paramètres',
                    icon: svgs.svgCog.raw,
                    subMenu: [
                        {
                            name: 'Administration des objectifs',
                            stateName: 'admin-goals',
                            icon: svgs.svgBusinessClimbTop1.raw
                        },{
                            name: 'Administration des lieux',
                            stateName: 'admin-places',
                            icon: svgs.svgPinLocation.raw,
                        },{
                            name: 'Administration des utilisateurs',
                            stateName: 'admin-users',
                            icon: svgs.svgSingleNeutralCircle.raw,
                        },
                    ]
                }
            ],
            selectedMenu: null,
            date: new Date().toLocaleDateString('fr-FR', dateOpts),
            time: new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h'),
            classEnv: null,
            displayName: '',
            userModules: [],
            menuDisplayed: false,
            version: process.env.VUE_APP_VERSION,
        };
    },
    watch: {
        '$route': function(route) {
            this.menu.map((m) => {
                m.active = false;

                if(m.stateName === route.name) {
                    m.active = true;
                }

                if(m.subMenu) {
                    m.subMenu.map((sub) => {
                        sub.active = false;

                        if(sub.stateName === route.name) {
                            m.active = true;
                            sub.active = true;
                        }
                    });
                }
            });
        }
    },
    created() {
        setInterval(() => {
            this.date = new Date().toLocaleDateString('fr-FR', dateOpts);
            this.time = new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h');
        }, 5000);
    },
    mounted() {
        this.getEnv();
        this.displayName = this.authStore.getUser.firstName ? this.authStore.getUser.firstName + ' ' + this.authStore.getUser.lastName : this.authStore.getUser.login;
        this.userModules = this.authStore.getUser.modules;
        // this.version = process.env.VERSION
    },
    methods: {
        select(menu, event) {
            if (event)
                event.preventDefault();
            this.menu.map((m) => m !== menu ? m.active = false : m.active = !m.active);
            if (!menu.stateName) return;
            this.$router.push({ name: menu.stateName });
            this.menuDisplayed = false;
        },
        logout() {
            return this.authStore.logout();
        },
        getEnv() {
            if (document.location.href.search('localhost') !== -1) {
                this.classEnv = 'is-dev';
            } else if (document.location.href.search('ready2dev') !== -1) {
                this.classEnv = 'is-preprod';
            } else {
                this.classEnv = 'is-prod';
            }
        },
        moduleRight(moduleName, rightName) {
            let module = this.userModules.find(mod => mod.name === moduleName);
            if (module) {
                const right = module.rights.find(r => r.name === rightName);
                if (right)
                    return right.value;
                return false;
            }
            return false;
        },
        toggleDisplay() {
            this.menuDisplayed = !this.menuDisplayed;
        },
    },
    setup() {
        return {
            authStore : useAuthStore(),
            loader: useLoader(),
        };
    }
};
</script>

<style scoped>
img {
    max-width: 155px;
    height: auto;
}
.sidebar--active {
    transition: all .3s ease-in-out 0s !important;
    transform: translateX(0) !important;
    opacity: 1 !important;
}

.menus-nav-layout #sidebar {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 72px;
    transform: translateX(-150%);
    transform-origin: left;
    width: 390px;
    z-index: 3;
}

.backoffice-layout #sidebar #navBar ul li {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    padding: 0;
    border-left: .125rem solid transparent;
    overflow: hidden;
}

.backoffice-layout {
    grid-template-columns: 0;
}

.menus-nav-layout #sidebar #navBar .btn {
    background-color: transparent;
}

.btn span {
    color: #333 !important;
}

.btn svg + span, button svg + span {
    margin-left: .5rem;
    text-align: left;
}

.backoffice-layout #mainHeader h1, .backoffice-layout #mainHeader h2 {
    color: #333!important;
    font-size: 1rem;
}

.btn.btn--menu {
    border: 1px solid black !important;
    color: #333 !important;

}
</style>
