<template>
    <section id='mainContent' class='h-100'>
        <div class='container-fluid h-100'>
            <div class='card h-100 shadow-sm'>
                <div class='card-body d-flex flex-column h-100'>
                    <h3 class='card-title'  v-if="lecture">Export des ventes</h3>
                    <div class='row mb-2 align-items-center'>
                        <div class='col-md-2'>
                            <select class='form-control'
                                    v-model='place'
                                    @change='getList'
                                    required>
                                <option :value=null>Sélectionner un lieu</option>
                                <option v-for='(place, key) in places'
                                        :key='key'
                                        :value='place.placeId'>
                                    {{ place.name }}
                                </option>
                            </select>
                        </div>
                        <div class='col-auto text-right'>Du</div>
                        <div class="col-auto">
                            <datepicker v-model='start'
                                        name='firstDay'
                                        language='fr'
                                        :monday-first='true'
                                        input-class='wrapperClass'
                                        :full-month-name="true"
                                        :format = "'dd MMMM yyyy'"
                                        :disabled-dates="{from: new Date()}"/>
                        </div>
                        <div class='col-auto text-right'>Au</div>
                        <div class="col-md-2">
                            <datepicker v-model='end'
                                        name='lastDay'
                                        language='fr'
                                        :monday-first='true'
                                        input-class='wrapperClass'
                                        :full-month-name="true"
                                        :format = "'dd MMMM yyyy'"
                                        :disabled-dates="{to: new Date(start), from: new Date()}"/>
                        </div>
                        <div class='col-auto'>
                            <button type="button"
                                    class="btn--icon btn--outline btn--small move-left"
                                    @click='getList'><svg-search /></button>
                        </div>
                    </div>
                    <div class='flex-fill' :hidden="!lecture">
                        <table
                            ref='table'
                            class='table table-striped table-hover table-handyRows w-100'></table>
                    </div>
                    <notif type='error'
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if='!lecture'/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';

import notif from '@/components/Notif.vue';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import { useSearchStore } from '@/stores/search.store';
import Datepicker from 'vuejs3-datepicker';
import svgComponents from '../../_svg/svgComponents';
export default {
    name: 'ExportVenteList.vue',
    components: { notif, Datepicker, ...svgComponents },
    data() {
        return {
            columns: [
                {
                    data: 'idLieu',
                    title: 'Id lieu',
                },
                {
                    data: 'idLineVente',
                    title: 'Id ligne'
                },
                {
                    data: 'gencode',
                    title: 'GenCode',
                },
                {
                    data: 'idHeadVente',
                    title: 'Id Facture',
                },
                {
                    data: 'quantiteVendu',
                    title: 'Quantité vendu',
                },
                {
                    data: 'prixVendu',
                    title: 'Prix vendu',
                },
                {
                    data: 'repairPrice',
                    title: 'Prix catalogue'
                },
                {
                    data: 'marge',
                    title: 'Marge',
                },
                {
                    data: 'remise',
                    title: 'Remise',
                },
                {
                    data: 'refFamille',
                    title: 'Type produit',
                },
                {
                    data: 'idTypeProduit',
                    title: 'Id type produit'
                },
                {
                    data: 'idProduit',
                    title: 'Id produit',
                },
                {
                    data: 'idEtat',
                    title: 'Grade',
                },
                {
                    data: 'ean',
                    title: 'Ean'
                },
                {
                    data: 'dateVente',
                    title: 'Date Vente',
                    render: (data) => {
                        return data ? DateTime.fromISO(data, { setZone: "Europe/Paris" }).toFormat('yyyy-MM-dd HH:mm:ss') : '';
                    }
                },
                {
                    data: 'descriptionArticle',
                    title: 'Description article',
                },
                {
                    data: 'prixVenteUnitaireHt',
                    title: 'Prix vente unitaire HT',
                },
                {
                    data: 'tauxTva',
                    title: 'Taux TVA'
                },
                {
                    data: 'montantTvaUnitaire',
                    title: 'Montant TVA unitaire'
                },
                {
                    data: 'regimeTva',
                    title: 'Régime TVA'
                },
                {
                    data: 'idRep',
                    title: 'Id catalogue Réparation',
                    render: (data) => {
                        return data == 0 ? null : data;
                    }
                },
                {
                    data:'motifRetour',
                    title: 'Motif retour'
                },
                {
                    data: 'montantRegl',
                    title: 'Montant règlement'
                },
                {
                    data: 'libelleRegl',
                    title: 'Type règlement'
                }
            ],
            rows: [],
            places: [],
            allPlaces: [],
            sign: null,
            table: null,
            error: false,
            loading: false,
            place: null,
            start: null,
            end: null,
            init: false,
            lecture: false,
            modification: false,
            filename: DateTime.now().toISO({
                suppressSeconds: true, suppressMilliseconds: true, includeOffset: false, extendedZone: false })
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Export des ventes', 'Modification');
        this.lecture = await this.authStore.moduleRight('Export des ventes', 'Lecture');
        if(this.lecture) {
            await this.getPlaces();
            if(this.search.getSearch('exportVente')) {
                this.start = this.search.getSearch('exportVente').start;
                this.end = this.search.getSearch('exportVente').end;
                this.place = this.search.getSearch('exportVente').place;
            }
            await this.getList();
        }
    },
    methods: {
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else if (this.authStore.getUser.places[0].id !== '2') {
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if(BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    }else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getList() {
            this.start = this.start ? DateTime.fromMillis(Date.parse(this.start)).toISODate() : DateTime.now().startOf('month').toISODate();
            this.end = this.end ? DateTime.fromMillis(Date.parse(this.end)).toISODate() : DateTime.now().toISODate();
            let url = `${window.location.origin}/api/cashmaster/feedback/export/veli?start=${this.start}&end=${this.end}`;
            url += this.place ? `&place=${this.place}` : '';
            try {
                if(this.place) {
                    this.search.set('exportVente', { start: this.start, end: this.end, place: this.place });
                    this.loader.show('load');
                    const res = await this.axios.get(url);
                    this.rows = res.data;

                    if (this.table) {
                        this.table.clear();
                        this.table.rows.add(this.rows).draw();
                    } else {
                        this.table = this.initDt(this.columns, this.rows);
                    }
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);
            const dtOptions = {
                data,
                columns,
                buttons: [{ extend: 'csv', text: 'Export CSV', filename: this.filename },'colvis'],
                deferRender: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageLength: 100,
                scrollY: '50vh',
                scrollX: true,
                order: [[14, 'asc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Blpti',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);
                    // Restore state saved values
                    var state = vm.table.state.loaded();
                    if (state) {
                        console.log(state);
                    }
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            authStore: useAuthStore(),
            loader: useLoader(),
            search: useSearchStore()
        };
    }
};
</script>

<style scoped>
.wrapperClass.vuejs3-datepicker__inputvalue {
    padding: 6px 12px;
    border: #666666 1px solid;
}
@import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
</style>
