<template>
    <div class='backoffice-layout'>
        <section id='mainContent'>
            <div class='container-fluid'>
                <div class='row justify-content-md-center'>
                    <div class='col-12 col-md-4 text-center'>
                        <img
                            src='../assets/grapp/dist/assets/images/cerebro-removebg-preview6.png'
                            alt='Cerebro Back-office'>
                    </div>
                </div>
                <div class='row justify-content-md-center mt-5'>
                    <div class='col-12 col-md-6'>
                        <div class='card shadow-sm'>
                            <div class='card-body'>
                                <h5 class='card-title'>Admin CEREBRO</h5>
                                <h6 class='card-subtitle mb-2 text-muted'>
                                    Authentification
                                </h6>
                                <hr >
                                <span class='card-text'>
                                    <em>Veuillez saisir vos identifiants</em>
                                </span>
                                <hr >
                                <form id='loginForm' @submit.prevent='onSubmit'>
                                    <div class='mb-3 form-group'>
                                        <label for='login' class='form-label'>Identifiant</label>
                                        <input
                                            type='text'
                                            class='form-control w-50'
                                            v-model='login'
                                            id='login'>
                                    </div>
                                    <div class='mb-3 form-group'>
                                        <label for='password' class='form-label'>
                                            Mot de passe
                                        </label>
                                        <input
                                            type='password'
                                            class='form-control w-50'
                                            v-model='password'
                                            id='password'>
                                    </div>
                                </form>
                            </div>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <notif
                                        :type="'error'"
                                        :text="'Vous avez été déconnecté ! Merci de re-saisir vos identifiants'"
                                        v-if='authStore.isLoged'/>
                                    <notif
                                        :type="'error'"
                                        :text="'Impossible de se connecter ! (identifiant/mot de passe incorrect ?)'"
                                        v-if='authStore.error'/>
                                </div>
                                <div class='row justify-content-end'>
                                    <div class='col-auto is-animated d-flex '>
                                        <button v-if='!loading'
                                                class='btn--color-success'
                                                type='submit'
                                                form='loginForm'>
                                            <span>S'authentifier</span>
                                            <svg-login />
                                        </button>
                                        <button v-if='loading'
                                                class='btn--color-success'
                                                disabled>
                                            <span class='spinner-border spinner-border-sm'
                                                  role='status'
                                                  aria-hidden='true'></span>
                                            <span>En cours...</span>
                                            <figure class='spin--infinite ml-2'>
                                                <svg-login />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import notif from '../components/Notif.vue';
import svgs from '../_svg/svgComponents';
import { useAuthStore } from '@/stores/auth.store';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'CrAuth',
    components: {
        ...svgs,
        notif,
    },
    data() {
        return {
            login: null,
            password: null,
            error: null,
            deconnectedError: null,
            loading: false,
            loginSvg: '01-Interface%20Essential/04-Login%3ALogout/login.svg',
        };
    },
    created() {
        // this.deconnectedError = this.authStore.isLoged
        this.error = this.authStore.error;
    },
    methods: {
        async onSubmit(e) {
            e.preventDefault();

            this.deconnectedError = null;
            this.error = null;
            this.loading = true;
            try {
                return this.authStore.login(this.login, this.password);
            } catch (err) {
                console.log(err);
            }finally {
                this.loading = false;
            }
        },
    },
    setup() {
        return {
            authStore : useAuthStore()
        };
    }
};
</script>

<style scoped>
.backoffice-layout {
    grid-template-columns: 0;
}
img{
    max-width: 100%;
}
</style>
