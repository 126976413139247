import _ from 'lodash';

const obj = {};
const formatCamelCase = (s) => {
    s = _.camelCase(s);
    return s.charAt(0).toUpperCase() + s.slice(1);
};

[
    'add', 'alarm-bell', 'alert-diamond', 'alert-triangle', 'arrow-down', 'arrow-left', 'arrow-right', 'arrow-up', 'auditory-disability', 'accounting-document',
    'bear', 'bimedia', 'bin', 'book-flip-page', 'breadcrumb', 'button-click', 'business-climb-top-1',
    'calculator-app', 'cards', 'cash-payment-bag', 'cash-payment-bill', 'cash-payment-coins', 'cat', 'check', 'check-badge', 'close', 'cog', 'cog-double', 'check-square',
    'cash-payment-wallet', 'charger', 'credit-card-give',
    'delete', 'dog', 'download',
    'empty', 'eye', 'eye-off',
    'file-csv', 'file-text-info', 'filter', 'floppy-disk',
    'hand', 'hotline', 'house',
    'image-file', 'information-circle',
    'keyboard-shift',
    'layout-dashboard',
    'list-add', 'list-bullets', 'loader', 'lock', 'logout', 'login',
    'menu', 'move-back', 'move-left-right',
    'navigation-menu',
    'office-file-adobe',
    'pencil', 'phone', 'physical-disability', 'pin', 'pin-location', 'plus', 'print-text', 'psychic-disability', 'phone-broken', 'phone-action-data-transfer', 'performance-phone-increase',
    'phone-action-warning',
    'receipt', 'receipt-register-print', 'restaurant-eating-set', 'room-service-bring-plate', 'round-list-bullets',
    'safestore', 'search', 'single-neutral-circle', 'single-neutral-id-card', 'subtract', 'synchronize-arrow', 'shopping-cart-1',
    'table', 'tags-double', 'task-list-approve', 'task-list-cash', 'text-input-area', 'two-arrows', 'task-list-star',
    'view', 'visual-disability'
].forEach((svg) => {
    obj[`svg${formatCamelCase(svg)}`] = require(`./regular/${svg}.svg`).default;
});

export default obj;
