<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card shadow-sm">
                <div class="card-body">
                    <h3 class="card-title"
                        v-if='lecture'>Rapport d'activité</h3>
                    <notif type="error"
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if="!lecture"/>
                    <div class="row" v-if="lecture">
                        <div class='col-md-2'>
                            <select class='form-control'
                                    v-model='sign'
                                    @change='filterSign'
                                    :disabled="oneSign"
                                    required>
                                <option :value=null>Sélectionner une marque</option>
                                <option value='BMP'>BeeMyPhone</option>
                                <option value='C&R'>Cash & Repair</option>
                            </select>
                        </div>
                        <div class='col-md-3'>
                            <select class='form-control'
                                    v-model='place'
                                    @change='filterPlace'
                                    required>
                                <option :value=null>Sélectionner un lieu</option>
                                <option v-for='(place, key) in places'
                                        :key='key'
                                        :value='place'>
                                    {{ place.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="place">
                        <div class="row mt-4 p-2">
                            <div class='col-auto'>
                                De&nbsp;
                                <datepicker v-model='date'
                                            @input='selectDate'
                                            :disabled-dates="{from: new Date()}"
                                            :prevent-disable-date-selection="true"
                                            name='minDateValidity'
                                            :minimum-view="'month'"
                                            :maximum-view="'year'"
                                            :full-month-name="true"
                                            :format = "'MMMM yyyy'"
                                            language='fr'/>
                            </div>
                            <div class='col-auto'>
                                Au&nbsp;
                                <datepicker v-model='dateEnd'
                                            @input='selectDate'
                                            :disabled-dates="{to: new Date(date), from: new Date()}"
                                            :prevent-disable-date-selection="true"
                                            name='maxDateValidity'
                                            :minimum-view="'day'"
                                            :maximum-view="'year'"
                                            :full-month-name="true"
                                            :format = "'dd MMMM yyyy'"
                                            language='fr'/>
                            </div>
                            <div class='col-auto'>
                                <button @click='openAdminModal' class="btn btn--large">
                                    <svg-cog />
                                </button> <span style='font-size: small; font-style: italic;' v-if="!detail.reportActivityId && !this.detail.lastMonth">
                                    Résultat obtenu à partir des données par défaut. <strong>Veuillez enregistrer les données exactes.</strong></span>
                                <span style='font-size: small; font-style: italic;' v-if="!detail.reportActivityId && this.detail.lastMonth">
                                    Résultat obtenu à partir des données du mois dernier. <strong>Veuillez enregistrer les données exactes.</strong></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 mt-4">
                                <table class="table text-right">
                                    <thead>
                                        <tr><td></td><td></td><td></td><td>Moyenne Réseau</td><td>Moyenne En Propre</td></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ isBmp ? 'CA HT' : 'CA réparation HT' }}</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.CA) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.CA): $filters.decimal(average.cr.CA) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.CA): $filters.decimal(average.crP.CA) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Achats de pièces</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.parts) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.parts): $filters.decimal(average.cr.parts) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.parts): $filters.decimal(average.crP.parts) }}€</td>
                                        </tr>
                                        <tr v-if="isBmp">
                                            <td>Achats de Marchandises</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.purchases) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.purchases): $filters.decimal(average.cr.purchases) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.purchases): $filters.decimal(average.crP.purchases) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Marge</td>
                                            <td>{{ $filters.decimal(detail.marginRate) }}%</td>
                                            <td>{{ $filters.decimal(detail.margin) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.margin): $filters.decimal(average.cr.margin) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.margin): $filters.decimal(average.crP.margin) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Services extérieurs et autres charges externes</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.externalCharges) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.manualData.externalCharges): $filters.decimal(average.cr.manualData.externalCharges) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.manualData.externalCharges): $filters.decimal(average.crP.manualData.externalCharges) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Masse salariale</td>
                                            <td>{{ $filters.decimal(detail.payrollRate) }}%</td>
                                            <td>{{ $filters.decimal(detail.payroll) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.payroll): $filters.decimal(average.cr.payroll) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.payroll): $filters.decimal(average.crP.payroll) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Rémunérations nettes totales</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.totalNetGain) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.manualData.totalNetGain): $filters.decimal(average.cr.manualData.totalNetGain) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.manualData.totalNetGain): $filters.decimal(average.crP.manualData.totalNetGain) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Cotisations sociales et patronales</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(this.detail.employerContribution) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.employerContribution): $filters.decimal(average.cr.employerContribution) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.employerContribution): $filters.decimal(average.crP.employerContribution) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Remboursement emprunt</td>
                                            <td>&nbsp;</td>
                                            <td>{{ $filters.decimal(detail.loanRepayment) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.manualData.loanRepayment): $filters.decimal(average.cr.manualData.loanRepayment) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmpP.manualData.loanRepayment): $filters.decimal(average.crP.manualData.loanRepayment) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td class='text-right'><strong>Résultat d'exploitation</strong></td>
                                            <td :class="detail.operatingProfit > 0 ? 'text-success' : 'text-danger'">
                                                <strong>{{ $filters.decimal(detail.operatingProfit) }}€</strong></td>
                                            <td :class="(isBmp && average.bmp.operatingProfit > 0) || (!isBmp && average.cr.operatingProfit > 0)  ? 'text-success' : 'text-danger'">
                                                <strong>{{ isBmp ? $filters.decimal(average.bmp.operatingProfit): $filters.decimal(average.cr.operatingProfit) }}€</strong></td>
                                            <td :class="(isBmp && average.bmpP.operatingProfit > 0) || (!isBmp && average.crP.operatingProfit > 0)  ? 'text-success' : 'text-danger'">
                                                <strong>{{ isBmp ? $filters.decimal(average.bmpP.operatingProfit): $filters.decimal(average.crP.operatingProfit) }}€</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 mt-4">
                                <table class="table text-right">
                                    <tbody>
                                        <tr>
                                            <td>Nombre d'heures mensuelles rémunérées</td>
                                            <td> </td>
                                            <td>{{ detail.nbHours }}</td>
                                            <td>{{ isBmp ? Math.round(average.bmp.manualData.nbHours) : Math.round(average.cr.manualData.nbHours) }}</td>
                                        </tr>
                                        <tr>
                                            <td>Chiffre d'affaires par heure rémunérée (CA / Nb heures)</td>
                                            <td> </td>
                                            <td>{{ $filters.decimal(detail.CA / detail.nbHours) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.CA /average.bmp.manualData.nbHours) : $filters.decimal(average.cr.CA / average.cr.manualData.nbHours) }}€</td>
                                        </tr>
                                        <tr>
                                            <td>Prix de revient moyen (Total Masse Salariale / Nb heures)</td>
                                            <td> </td>
                                            <td>{{ $filters.decimal(detail.payroll / detail.nbHours) }}€</td>
                                            <td>{{ isBmp ? $filters.decimal(average.bmp.payroll /average.bmp.manualData.nbHours) : $filters.decimal(average.cr.payroll / average.cr.manualData.nbHours) }}€</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='card-footer'>
                    <div class='row justify-content-center'>
                        <div class='col-auto'>
                            <notif type='success'
                                   text='Sauvegarde effectuée'
                                   v-if='success'/>
                            <notif type='error'
                                   text='Un problème est survenu durant la sauvegarde'
                                   :message='msgError'
                                   v-if='error'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <modal title="Données Manuelles" :display="adminModal" @close="closeAdminModal" >
        <div class="modal-body">
            <form>
                <div class="row">
                    <div class="col-12">
                        <fieldset>
                            <legend>Données</legend>
                            <div class='row form-group'>
                                <label class='col-6 col-form-label'>Services extérieurs et autres charges externes</label>
                                <div class='col-6'>
                                    <input type='text'
                                           class='form-control'
                                           v-model='detail.externalCharges'
                                           :disabled="!modification || !isMyPlace">
                                </div>
                            </div>
                            <div class='row form-group'>
                                <label class='col-6 col-form-label'>Rémunérations nettes totales</label>
                                <div class='col-6'>
                                    <input type='text'
                                           class='form-control'
                                           v-model='detail.totalNetGain'
                                           :disabled="!modification || !isMyPlace">
                                </div>
                            </div>
                            <div class='row form-group'>
                                <label class='col-6 col-form-label'>Remboursement emprunt</label>
                                <div class='col-6'>
                                    <input type='text'
                                           class='form-control'
                                           v-model='detail.loanRepayment'
                                           :disabled="!modification || !isMyPlace">
                                </div>
                            </div>
                            <div class='row form-group'>
                                <label class='col-6 col-form-label'>Nombre de salariés</label>
                                <div class='col-6'>
                                    <input type='text'
                                           class='form-control'
                                           v-model='detail.nbEmployee'
                                           :disabled="!modification || !isMyPlace">
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn--outline btn--color--danger btn--small" @click="closeAdminModal" >
                <svg-close />
                <span>Annuler</span>
            </button>
            <button type="button" class="btn--color-danger btn--small" @click="submit" :disabled='v$.$error || !sameMonth'>
                <span>Enregistrer</span>
                <svg-floppy-disk />
            </button>
        </div>
    </modal>
</template>

<script>
import svgComponent from '@/_svg/svgComponents';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import notif from '@/components/Notif.vue';
import Datepicker from 'vuejs3-datepicker';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { DateTime } from 'luxon';
import modal from '@/components/Modal.vue';

export default {
    name: 'CrReportActivity',
    components: {
        modal,
        Datepicker,
        ...svgComponent,
        notif,
    },
    data() {
        return {
            lecture: false,
            modification: false,
            detail: {},
            places: [],
            allPlaces: [],
            place: null,
            sign: null,
            oneSign: false,
            loading: false,
            success: false,
            msgError: null,
            error:false,
            isMyPlace: false,
            adminModal: false,
            isBmp: false,
            average: null,
            date: DateTime.now().startOf('month').toHTTP(),
            dateEnd : DateTime.now().toHTTP(),
            sameMonth: true,
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Rapport d\'activité', 'Modification');
        this.lecture = await this.authStore.moduleRight('Rapport d\'activité', 'Lecture');
        if(this.lecture) {
            await this.getPlaces();
            await this.filterSign();
            await this.getAverages();
        }
    },
    watch : {
        'detail.nbEmployee' : async function (val, oldVal) {
            if(val !== oldVal) {
                this.detail.nbHours = val * 151;
            }
        },
        'date' : async function (val, oldVal) {
            if(val !== oldVal) {
                let dateStart = DateTime.fromMillis(Date.parse(this.date)).endOf('month').toISODate();
                let dateEnd = DateTime.fromMillis(Date.parse(this.dateEnd)).endOf('month').toISODate();
                this.sameMonth = dateStart === dateEnd;
                if(DateTime.fromISO(dateStart) > DateTime.fromISO(dateEnd))
                    this.dateEnd = DateTime.fromISO(dateEnd).plus({ months: 1 }).endOf('month').toHTTP();
            }
        },
        'dateEnd' : async function (val, oldVal) {
            if(val !== oldVal) {
                let dateStart = DateTime.fromMillis(Date.parse(this.date)).endOf('month').toISODate();
                let dateEnd = DateTime.fromMillis(Date.parse(this.dateEnd)).endOf('month').toISODate();
                this.sameMonth = dateStart === dateEnd;
            }
        }
    },
    methods: {
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['2', '6', '999', '3'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.places = this.places.filter(place => place.placeId === this.authStore.getUser.places[0].id);
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else if (this.authStore.getUser.places.length > 1) {
                    this.places = this.places.reduce((curr, place) => {
                        this.authStore.getUser.places.forEach((pl) => {
                            if(place.placeId === pl.id) curr.push(place);
                        });
                        return curr;
                    },[]);
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if(BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    }else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async filterSign() {
            if (this.sign === 'BMP') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) !== -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            if (this.sign === 'C&R') {
                this.places = this.places.reduce((curr, place) => {
                    if (['3', '4', '6'].indexOf(place.placeTypeId) === -1)
                        curr.push(place);
                    return curr;
                }, []);
            }
            // if (this.sign === null) {
            //     this.places = this.allPlaces;
            // }
            this.place = null;
        },
        async filterPlace() {
            if(this.place) {
                this.detail.month = DateTime.fromMillis(Date.parse(this.date)).toFormat('yyyy-MM-dd');
                this.detail.dateEnd = DateTime.fromMillis(Date.parse(this.dateEnd)).toFormat('yyyy-MM-dd');
                this.detail.placeId = this.place.placeId;
                this.isBmp = ['3', '4', '6'].indexOf(this.place.placeTypeId) !== -1;
                let res = await this.getDetail();
                if(res) {
                    this.detail.reportActivityId = res.manualData.reportActivityId;
                    this.detail.externalCharges = res.manualData.externalCharges;
                    this.detail.totalNetGain = res.manualData.totalNetGain;
                    this.detail.loanRepayment = res.manualData.loanRepayment;
                    this.detail.nbEmployee = res.manualData.nbEmployee;
                    this.detail.nbHours = res.manualData.nbHours;
                    this.detail.lastMonth = res.manualData.lastMonth ? res.manualData.lastMonth : false;
                    this.detail.CA = res.CA;
                    this.detail.parts = res.parts;
                    this.detail.purchases = res.purchases;
                    this.detail.margin = res.margin;
                    this.detail.marginRate = res.marginRate;
                    this.detail.employerContribution = res.employerContribution;
                    this.detail.payroll = res.payroll;
                    this.detail.payrollRate = res.payrollRate;
                    this.detail.operatingProfit = res.operatingProfit;
                }
                // await this.getMonthCA();
                // await this.getPartsPurchases();
                // if(this.isBmp)
                //     await this.getPurchases();
                // this.detail.margin = this.isBmp ? this.detail.CA - this.detail.parts - this.detail.purchases : this.detail.CA - this.detail.parts;
                // this.detail.marginRate = this.detail.margin / this.detail.CA * 100;
                // this.detail.totalNetGain = this.detail.netGain * this.detail.nbEmployee;
                // this.detail.employerContribution = this.detail.totalNetGain * 0.59;
                // this.detail.payroll = this.detail.totalNetGain + this.detail.employerContribution;
                // this.detail.payrollRate = this.detail.payroll / this.detail.CA * 100;
                // this.detail.operatingProfit = this.detail.margin - this.detail.externalCharges - this.detail.payroll - this.detail.loanRepayment;
                let ids = this.authStore.getUser.places.reduce((curr, place) => {
                    curr.push(place.id);
                    return curr;
                }, []);
                this.isMyPlace = ids.indexOf(this.place.placeId) !== -1 || this.authStore.getUser.places[0].id === '2';
            }
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/report-activity/place/${this.place.placeId}/date?start=${this.detail.month}&end=${this.detail.dateEnd}`
                );
                return res.data;
            } catch (err) {
                this.loading = false;
            }
        },
        async getAverages() {
            try {
                this.loader.show('load');
                let start = DateTime.fromMillis(Date.parse(this.date)).toFormat('yyyy-MM-dd');
                let end = DateTime.fromMillis(Date.parse(this.dateEnd)).toFormat('yyyy-MM-dd');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/report-activity/average/date?start=${start}&end=${end}`
                );
                this.average = res.data;
            } catch (err) {
                this.loading = false;
            }finally {
                this.loader.hide('load');
            }
        },
        async selectDate() {
            if(this.place) {
                await this.getAverages();
                await this.filterPlace();
            }
        },
        openAdminModal() {
            this.adminModal = true;
        },
        async closeAdminModal() {
            this.adminModal = false;
            if(this.place)
                await this.filterPlace();
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/report-activity`,
                        this.detail,
                    );
                    this.detail.reportActivityId = res.reportActivityId;
                    await this.getAverages();
                    this.success = true;
                    this.loading = false;
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                } finally {
                    await this.closeAdminModal();
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        loader: useLoader()
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                month: { required }
            },
        };

        return base;
    }
};

</script>

<style>
.card {
    min-height: 100% !important;
}
</style>
