import { defineStore } from 'pinia';
import router from '@/router';
import axios from 'axios';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: JSON.parse((localStorage.getItem('user'))) || null,
        returnUrl: null,
        isError: null,
        isDeconnected: true,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        isLoged(state) {
            return !state.isDeconnected;
        },
        error(state) {
            return state.isError;
        },
        getDisplayName(state) {
            return state.user ? state.user.firstName ? state.user.firstName + ' ' + state.user.lastName : state.user.login : '';
        }
    },
    actions: {
        async login(username, password) {
            try {
                const res = await axios.get(
                    `${window.location.origin}/api/auth/authenticate`, {
                        auth: {
                            username: username,
                            password: password,
                        },
                    });
                // On stocke le jwt dans le localStorage pour pouvoir le réutiliser dans chaque requête (voir main.js)
                this.user = res.data;
                localStorage.jwt = res.data.token;
                localStorage.user = JSON.stringify(this.user);
                this.isError = false;
                this.isDeconnected = false;

                await router.push(this.returnUrl || '/dashboard');
            } catch (err) {
                console.log(err);
                this.isError = true;
                this.isDeconnected = true;
            }
        },
        async logout() {
            localStorage.removeItem('jwt');
            localStorage.removeItem('user');
            this.user = null;
            this.isDeconnected = false;
            await router.push({
                name: 'auth',
            });
        },
        async moduleRight(moduleName, rightName) {
            let module = this.getUser ? this.getUser.modules.find(mod => mod.name === moduleName) : null;
            if (module) {
                const right = module.rights.find(r => r.name === rightName);
                if (right)
                    return right.value;
                return false;
            }
            return false;
        },
        async set(user) {
            this.user = user;
            localStorage.user = JSON.stringify(this.user);
        }
    },
});
