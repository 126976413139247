<script>
import notif from '@/components/Notif.vue';
import svgComponent from '../../_svg/svgComponents';
import $ from 'jquery';
import _ from 'lodash';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-datetime/dist/dataTables.dateTime';

import { useAuthStore } from '@/stores/auth.store';
import { DateTime } from 'luxon';

export default {
    name: 'UserList',
    components: { notif, ...svgComponent },
    data() {
        return {
            columns: [
                {
                    data: 'userId',
                    title: 'id',
                }, {
                    data: 'login',
                    title: 'Login',
                },{
                    data: 'firstName',
                    title: 'Prénom',
                },{
                    data: 'lastName',
                    title: 'Nom',
                },/*{
                    data: 'places',
                    title: 'Lieux',
                    render: (data) => {
                        let res = '';
                        data.forEach(mod => {
                            if (mod && mod.name)
                                res += `<span class="multiselect__tag" style="font-size: 12px">${mod.name}</span>`
                        });
                        return res;
                    }
                },*/{
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class="${ data ? this.status[data].class : this.status['false'].class}">${ data ? this.status[data].label : this.status['false'].label}</span>`;
                    }
                },{
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    }
                }
            ],
            rows: [],
            status: {
                'true': {
                    label: 'Actif',
                    class: 'text-success'
                },
                'false': {
                    label: 'Inactif',
                    class: 'text-danger'
                }
            },
            error: false,
            loading: false,
            table: null,
            lecture: false,
            modification: false,
        };
    },
    async mounted() {
        await this.getList();
        this.modification = await this.authStore.moduleRight('Administration des utilisateurs', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des utilisateurs', 'Lecture');
    },
    methods: {
        async getList() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/users`
                );
                let places = this.authStore.getUser.places;
                if(places.length === 1 && places[0].id === '2') {
                    this.rows = res.data;
                }else {
                    this.rows = res.data.reduce((curr, row) => {
                        for(let place of places) {
                            if (row.places.find(p => p.id === place.id) && !curr.find(u => u.userId === row.userId)) {
                                curr.push(row);
                            }
                        }
                        return curr;
                    }, []);
                }
                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
                this.error = true;
            }
        },
        goTo(user) {
            this.$router.push(`user/${user.userId}`);
        },
        createUser() {
            this.$router.push("user/create");
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                buttons: ['searchBuilder'],
                deferRender: true,
                stateSave: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: true,
                scrollResize: true,
                pageLength: 100,
                scrollY: 100,
                scrollX: true,
                order: [[3, 'asc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bflpti',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo(row);
                        }
                    });
                },
            };
            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            authStore : useAuthStore()
        };
    }
};
</script>

<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card h-100 shadow-sm">
                <div class="card-body d-flex flex-column h-100">
                    <h3 class="card-title"
                        v-if='lecture'>Liste des utilisateurs</h3>
                    <div class="flex-fill"
                         :hidden="!lecture">
                        <table
                            ref="table"
                            class="table table-striped table-hover table-handyRows w-100"></table>
                    </div>
                    <notif type="error"
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if="!lecture"/>
                </div>
                <div class="card-footer">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <notif type="error"
                                   text="Un problème est survenu pendant la création du composant"
                                   v-if="error"/>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto is-animated d-flex">
                            <button v-if="!loading && modification"
                                    class="btn--color-success"
                                    @click="createUser">
                                <span>Créer un nouvel utilisateur</span>
                                <svg-add />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
