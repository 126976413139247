import { defineStore } from 'pinia';

export const useTransferStore = defineStore({
    id: 'transfer',
    state: () => ({
        transfer: {
            components:[],
            goodies: [],
            placeFrom: null,
            placeTo: null,
            type: 'out',
            comment: 'Transfert depuis module'
        }
    }),
    getters:{
        getTransfer(state) {
            return state.transfer;
        }
    },
    actions: {
        get() {
            return this.transfer;
        },
        set(transfer) {
            this.transfer = transfer;
        },
        setPlaceTo(place) {
            this.transfer.placeTo = place;
        },
        setPlaceFrom(place) {
            this.transfer.placeFrom = place;
        },
        addComponent(component) {
            this.transfer.components.push(component);
        },
        addGoodie(goodie) {
            this.transfer.goodies.push(goodie);
        }
    }
});
