import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router/index';
import axios from 'axios';
import VueAxios from 'vue-axios';
import $filters from 'vue-grapp-components/src/utils/filters';

import './assets/grapp/dist/grapp.css';

axios.interceptors.request.use((request) => {
    if ((localStorage.jwt && request.url.search('.svg') === -1) || request.url.search('model-components') !== -1) {
        request.headers = { Authorization: `Bearer ${localStorage.jwt}` };
    }
    return request;
});

// Interceptor pour pouvoir gérer les 401 et 403 (perte d'auth)
axios.interceptors.response.use((response) => {
    return response;
},
async (error) => {
    if ([403, 401].indexOf(error.response.status) !== -1) {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        await router.push({
            name: 'auth',
        });
    }
    return Promise.reject(error);
}
);

const app = createApp(App);
app.config.globalProperties.$filters = $filters;

app.use(VueAxios, axios).use(router).use(createPinia()).mount('body');
