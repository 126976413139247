<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div :class="detail.groupId ? 'col-sm-6' : 'col-sm-12'">
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.groupId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un groupe
                            </h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Groupe</legend>
                                    <!-- MARQUE -->
                                    <div class='row form-group'>
                                        <label class='col-form-label col-sm-3'>Marque <abbr>*</abbr></label>
                                        <div class="col-sm-6">
                                            <div :class="detail.groupId ? 'col-sm-12' : 'col-sm-6'">
                                                <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                        class='form-control'
                                                        v-model='detail.brandId'
                                                        required>
                                                    <option :value="undefined"></option>
                                                    <option v-for='(brand, key) in brands'
                                                            :key='key'
                                                            :value='brand.brandId'>
                                                        {{ brand.name }}
                                                    </option>
                                                </select>
                                                <p v-if='v$.detail.brandId.$error'
                                                   class='invalid-feedback'>
                                                    Veuillez sélectionner une marque
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom<abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <div :class="detail.groupId ? 'col-sm-12' : 'col-sm-6'">
                                                <input type='text'
                                                       class='form-control'
                                                       :class="{'is-invalid': v$.detail.name.$error}"
                                                       v-model='detail.name' >
                                                <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                    un nom</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SEO -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>SEO <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='seo'
                                                               :class="{'is-invalid': v$.detail.isSeo.$error}"
                                                               :id="'seo_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.isSeo'>
                                                        <label class='custom-control-label'
                                                               :for="'seo_'+s.value"
                                                               :class="detail.isSeo === s.value ? s.class : ''">{{ s.label2
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.isSeo.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class='col-auto'>
                                        <button v-if='!loading && detail.groupId && modification'
                                                class='btn--color-success'
                                                @click='createGroup'>
                                            <span>Créer un nouveau group</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="detail.groupId ? 'col-sm-6' : 'col-sm-12'" v-if='detail.groupId'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>
                                Ajouter des modèls
                            </h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Modèles</legend>
                                    <!-- TYPE PRODUIT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Type</label>
                                        <div class='col-6'>
                                            <select class='form-control'
                                                    v-model='productTypeId'
                                                    required>
                                                <option :value="undefined"></option>
                                                <option v-for='(productType, key) in productTypes'
                                                        :key='key'
                                                        :value='productType.productTypeId'>
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- MODELE -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Modèle</label>
                                        <div class='col-6'>
                                            <VueMultiselect v-model='modelsGroup'
                                                            :options='models'
                                                            :multiple="true"
                                                            :taggable="true"
                                                            placeholder="Chercher un model"
                                                            label="name"
                                                            track-by="modelId"/>
                                            <pre class='language-json'><code>{{ value }}</code></pre>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row is-animated d-flex justify-content-end'>
                                    <div class='col-auto '>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='add'
                                            :disabled='v$.$error'>
                                            <span>Ajouter</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Ajout en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row' v-if='detail.groupId'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm mt-3'>
                        <div class='card-body'>
                            <h3 class='card-title'>
                                Modèles du groupe {{ detail.name }}
                            </h3>
                            <div class='flex-fill'>
                                <table
                                    ref='table'
                                    class='table table-striped table-hover table-handyRows'></table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';
import { DateTime } from 'luxon';
import _ from 'lodash';
import $ from 'jquery';
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'ModelDetail',
    components: {
        ...svgComponent,
        notif,
        VueMultiselect,
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            productTypes: [],
            productTypeId: null,
            modelsGroup: [],
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            status2:{
                'true': {
                    label: 'Actif',
                    class: 'text-success',
                },
                'false': {
                    label: 'Inactif',
                    class: 'text-danger',
                },
            },
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            columns: [
                {
                    data: 'modelId',
                    title: 'Id',
                    render: (data) => {
                        return `<a href="/admin/model/${data}">${data}</a>`;
                    }
                },
                {
                    data: 'name',
                    title: 'Nom',
                },
                {
                    data: 'manufacturerRef',
                    title: 'Ref Fournisseur',
                },
                {
                    data: 'brandName',
                    title: 'Marque',
                },
                {
                    data: 'productTypeName',
                    title: 'Type de produit',
                },
                {
                    data: 'status',
                    title: 'Etat',
                    render: (data) => {
                        return `<span class='${data ? this.status2[data].class : this.status2['false'].class}'>${data ? this.status2[data].label : this.status2['false'].label}</span>`;
                    },
                },
                {
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return DateTime.fromISO(data).toFormat('yyyy-MM-dd HH:mm:ss');
                    },
                },
            ],
            rows: [],
            table: null,
        };
    },
    watch: {
        'detail.brandId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
        'productTypeId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des groupes', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des groupes', 'Lecture');
        await this.getBrands();
        await this.getProductTypes();
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/group');
            this.createNewDetail();
        } else {
            await this.getDetail();
            await this.getModels();
            await this.getModelsGroup();

        }
        if (!this.lecture)
            this.$router.push('/admin/group');
    },
    methods: {
        createNewDetail() {
            this.detail = {
                groupId: null,
                name: null,
                brandId: null,
                isSeo: false,
                status: true
            };
            this.modelsGroup = [];
        },
        createGroup() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/group/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModelsGroup() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/group/${this.$route.params.id}/models`,
                );
                this.rows = res.data;
                this.modelsGroup = res.data;

                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                Object.keys(this.detail).forEach(key => {
                    this.detail[key] = this.detail[key] === "" ? null : this.detail[key];
                });
                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/group`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.detail.groupId = res.data.groupId;
                    this.goTo('/admin/group/'+res.data.groupId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        async add(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                this.modelsGroup.map(model => {
                    model.groupId = this.detail.groupId;
                });
                try {
                    let res = await this.axios.post(
                        `${window.location.origin}/api/crd/group/models`,
                        this.modelsGroup,
                    );
                    this.success = true;
                    this.loading = false;
                    this.table.clear();
                    this.table.destroy();
                    this.table = this.initDt(this.columns, res.data);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                ordering: true,
                info: false,
                rowReorder: {
                    enabled: true
                },
                filter: false,
                language: {
                    url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json'
                },
                paging: false,
                dom: 't',
                order: [[2, 'asc']],
                select: {
                    style: 'api',
                    toggleable: false
                },
                stateLoadParams: function(settings, data) {
                    if (!this.init) {
                        var state = this.table.state.loaded();
                        data.searchBuilder = state.searchBuilder;
                    }
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);
                    // Restore state saved values
                    var state = vm.table.state.loaded();
                    if (state) {
                        console.log(state);
                    }

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo('/admin/model/'+row.modelId);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
    }),
    validations() {
        let base = {
            detail: {
                $autoDirty: true,
                name: { required },
                brandId: { required },
                isSeo: { required },
                status: { required },
            },
        };

        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
