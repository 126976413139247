import { defineStore } from 'pinia';
import axios from 'axios';

export const useProductStore = defineStore({
    id: 'product',
    state: () => ({
        products: []
    }),
    getters:{
        getList(state) {
            return state.products;
        },
        lastProduct(state) {
            return state.products[0];
        }
    },
    actions: {
        async getProducts() {
            const res = await axios.get(
                `${window.location.origin}/api/crd/products`
            );
            this.products = res.data;
        },
        async getLastProducts() {
            return await axios.get(
                `${window.location.origin}/api/crd/products?date=${this.products[0].updatedDate}`
            );
        },
        async getProduct (id)  {
            return this.products.find(prod => prod.productId === id);
        },
        set(product) {
            this.products.push(product);
        },
        update(product, index) {
            this.products[index] = product;
        },
        sort() {
            this.products.sort(function compare(a, b) {
                if (a.updatedDate < b.updatedDate)
                    return 1;
                if (a.updatedDate > b.updatedDate)
                    return -1;
                return 0;
            });
        }
    }
});
