<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-12'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.goodiesTypeId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>Création d'un type de goodies</h3>
                            <form class='mt-6'>
                                <fieldset>
                                    <legend>Type de goodies</legend>
                                    <!-- NOM -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Nom <abbr>*</abbr></label>
                                        <div class='col-6'>
                                            <input type='text'
                                                   class='form-control'
                                                   v-model='detail.name'>
                                            <p v-if='v$.detail.name.$error' class='invalid-feedback'>Veuillez saisir
                                                un nom</p>
                                        </div>
                                    </div>
                                    <!-- ETAT -->
                                    <div class='row form-group'>
                                        <label class='col-3 col-form-label'>Etat <abbr>*</abbr></label>
                                        <div class='col-9'>
                                            <div class='row'>
                                                <div class='col'>
                                                    <div class='custom-control custom-radio custom-control-inline'
                                                         v-for='s in status'
                                                         :key='s.value'>
                                                        <input class='custom-control-input'
                                                               type='radio'
                                                               name='status'
                                                               :class="{'is-invalid': v$.detail.status.$error}"
                                                               :id="'status_'+s.value"
                                                               :value='s.value'
                                                               v-model='detail.status'>
                                                        <label class='custom-control-label'
                                                               :for="'status_'+s.value"
                                                               :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                               }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row' v-if='v$.detail.status.$error'>
                                                <div class='col'>
                                                    <input type='hidden' class='form-control is-invalid' >
                                                    <p class='invalid-feedback'>
                                                        Veuillez sélectionner un état
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div class='card-footer'>
                                <div class='row justify-content-center'>
                                    <div class='col-auto'>
                                        <notif type='success'
                                               text='Sauvegarde effectuée'
                                               v-if='success'/>
                                        <notif type='error'
                                               text='Un problème est survenu durant la sauvegarde'
                                               :message='this.msgError'
                                               v-if='error'/>
                                    </div>
                                </div>
                                <div class='row is-animated d-flex justify-content-between'>
                                    <div class="col-auto">
                                        <button v-if="!loading && modification"
                                                class="btn--color-success"
                                                @click="createGoodiesType">
                                            <span>Créer un nouveau type de goodies</span>
                                            <svg-add />
                                        </button>
                                    </div>
                                    <div class='col-auto'>
                                        <button
                                            v-if='!loading && modification'
                                            class='btn--color-success'
                                            @click='submit'
                                            :disabled='v$.$error'>
                                            <span>Sauvegarder</span>
                                            <svg-floppy-disk />
                                        </button>
                                        <button
                                            v-if='loading'
                                            class='btn--color-success'
                                            type='button'
                                            disabled>
                                            <span>Sauvegarde en cours...</span>
                                            <figure class='spin--infinite mr-2'>
                                                <svg-floppy-disk />
                                            </figure>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import notif from '@/components/Notif.vue';
import svgComponent from '../../_svg/svgComponents';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/stores/auth.store';

export default defineComponent({
    name: 'GoodiesTypeDetail',
    components: { notif, ...svgComponent },
    data() {
        return {
            detail: null,
            status: [
                {
                    value: true,
                    label: 'Actif',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            modules: [],
            rights: [],
            checkedRights: [],
            places: []
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des types de goodies', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des types de goodies', 'Lecture');
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/goodies-type');
            this.createNewDetail();
        } else {
            await this.getDetail();
        }
        if(!this.lecture)
            this.$router.push("/admin/goodies-type");
    },
    methods: {
        createNewDetail() {
            this.detail = {
                goodiesTypeId: null,
                name: null,
                status: true
            };
        },
        createGoodiesType() {
            this.createNewDetail();
            this.$router.push("create");
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goodiesType?goodiesTypeId=${this.$route.params.id}`,
                );
                this.detail = res.data[0];
            } catch (err) {
                console.log(err);
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/goodiesType`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.goTo(res.data.goodiesTypeId);
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
            authStore: useAuthStore()
        };
    },
    validations() {
        return {
            detail: {
                $autoDirty: true,
                name: { required },
                status: { required },
            },
        };
    }
});
</script>

<style scoped>

</style>
