<template>
    <section id="mainContent">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h3 class="card-title">Transfert du stock</h3>
                            <notif
                                type="error"
                                text="Vous n'êtes pas autorisé à consulter ce module."
                                v-if="!lecture"/>
                            <div v-else>
                                <fieldset>
                                    <legend>Données</legend>
                                    <div class="row form-group">
                                        <label class="col-3 col-form-label">Type</label>
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="custom-control custom-radio custom-control-inline"
                                                        v-for="t in types"
                                                        :key="t.value">
                                                        <input
                                                            class="custom-control-input"
                                                            type="radio"
                                                            name="type"
                                                            :id="'type_' + t.value"
                                                            :value="t.value"
                                                            v-model="entryType">
                                                        <label
                                                            class="custom-control-label"
                                                            :for="'type_' + t.value"
                                                            :class="types === t.value ? t.class : ''">{{ t.label }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" v-if="entryType === 'out'">
                                        <label class="col-3 col-form-label">Type de données</label>
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="custom-control custom-radio custom-control-inline"
                                                        v-for="d in datas"
                                                        :key="d.value">
                                                        <input
                                                            class="custom-control-input"
                                                            type="radio"
                                                            name="datas"
                                                            :id="'datas_' + d.value"
                                                            :value="d.value"
                                                            v-model="entryData"
                                                            disabled>
                                                        <label
                                                            class="custom-control-label"
                                                            :for="'datas_' + d.value"
                                                            :class="datas === d.value ? d.class : ''">
                                                            {{ d.label }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row form-group" v-if="entryData === 'composant' && entryType === 'out'">
                                        <label class="col-3 col-form-label">Type de recherche</label>
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        class="custom-control custom-radio custom-control-inline"
                                                        v-for="s in search"
                                                        :key="s.value">
                                                        <input
                                                            class="custom-control-input"
                                                            type="radio"
                                                            name="search"
                                                            :id="'search_' + s.value"
                                                            :value="s.value"
                                                            v-model="entrySearch">
                                                        <label
                                                            class="custom-control-label"
                                                            :for="'search_' + s.value"
                                                            :class="search === s.value ? s.class : ''">
                                                            {{ s.label }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <fieldset>
                                                <legend>Lieux</legend>
                                                <div class="form-group row" v-if="entryType === 'out'">
                                                    <label class="col-3 col-form-label">Lieu de départ</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="detail.placeFrom"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(place, key) in places"
                                                                :key="key"
                                                                :value="place.placeId">
                                                                {{ place.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-3 col-form-label">Lieu de destination</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="detail.placeTo"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(place, key) in allPlaces"
                                                                :key="key"
                                                                :value="place.placeId">
                                                                {{ place.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset v-if="entryData === 'composant' && entryType === 'out'">
                                                <legend>Composant</legend>
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'name'">
                                                    <label class="col-3 col-form-label">Composant <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <VueMultiselect
                                                            v-model="component"
                                                            :options="stocks"
                                                            placeholder="Chercher un composant"
                                                            label="name"
                                                            track-by="componentId"
                                                            @change="displayStock"/>
                                                    </div>
                                                    <div v-if="component && component.componentId" class="col-3">
                                                        {{ quantity }} en stock
                                                    </div>
                                                </div>
                                                <!-- TYPE PRODUIT -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Type produit <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="filter.productTypeId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(productType, key) in productTypes"
                                                                :key="key"
                                                                :value="productType.productTypeId">
                                                                {{ productType.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- MARQUE -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Marque <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="filter.brandId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(brand, key) in brands"
                                                                :key="key"
                                                                :value="brand.brandId">
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- MODELE -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Modèle <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="filter.modelId"
                                                            :disabled="models.length === 0"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(model, key) in models"
                                                                :key="key"
                                                                :value="model.modelId">
                                                                {{ model.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- TYPE COMPOSANT -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Type composant</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="filter.componentTypeId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(componentType, key) in componentTypes"
                                                                :key="key"
                                                                :value="componentType.componentTypeId">
                                                                {{ componentType.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- COULEUR -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Couleur</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="filter.colorId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(color, key) in colors"
                                                                :key="key"
                                                                :value="color.colorId">
                                                                {{ color.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- COMPOSANT -->
                                                <div
                                                    class="row form-group"
                                                    v-if="entrySearch === 'filter'">
                                                    <label class="col-3 col-form-label">Composant <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            v-model="component"
                                                            class="form-control"
                                                            required
                                                            @change="displayStock">
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(component, key) in stocks"
                                                                :key="key"
                                                                :value="component">
                                                                {{ component.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div v-if="component && component.componentId" class="col-3">
                                                        {{ quantity }} en stock
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset v-if="entryData === 'goodies' && entryType === 'out'">
                                                <legend>Goodies</legend>
                                                <!-- TYPE GOODIES -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Type goodies <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="goodie.goodiesTypeId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(goodiesType, key) in goodiesTypes"
                                                                :key="key"
                                                                :value="goodiesType.goodiesTypeId">
                                                                {{ goodiesType.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- MARQUE -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Marque <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="goodie.sign"
                                                            required>
                                                            <option :value="null"></option>
                                                            <option :value="'BeeMyPhone'">BeeMyPhone</option>
                                                            <option :value="'C%26R'">C&R</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- SEXE -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Sexe</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="goodie.gender"
                                                            required>
                                                            <option :value="null"></option>
                                                            <option :value="'Femme'">Femme</option>
                                                            <option :value="'Homme'">Homme</option>
                                                            <option :value="'Mixte'">Mixte</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- TAILLE -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Taille</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="goodie.size"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option :value="'XS'">XS</option>
                                                            <option :value="'S'">S</option>
                                                            <option :value="'M'">M</option>
                                                            <option :value="'L'">L</option>
                                                            <option :value="'XL'">XL</option>
                                                            <option :value="'XXL'">XXL</option>
                                                            <option :value="'XXXL'">XXXL</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- COULEUR -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Couleur</label>
                                                    <div class="col-6">
                                                        <select
                                                            class="form-control"
                                                            v-model="goodie.genericColorId"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(genericColor, key) in genericColors"
                                                                :key="key"
                                                                :value="genericColor.genericColorId">
                                                                {{ genericColor.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- GOODIES -->
                                                <div class="row form-group">
                                                    <label class="col-3 col-form-label">Goodies <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <select
                                                            v-model="goodie"
                                                            class="form-control"
                                                            @change="displayStock"
                                                            required>
                                                            <option :value="undefined"></option>
                                                            <option
                                                                v-for="(g, key) in goodies"
                                                                :key="key"
                                                                :value="g">
                                                                {{ g.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div v-if="goodie.goodiesId" class="col-3">
                                                        {{ quantity }} en stock
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset v-if="entryType === 'out'">
                                                <legend>Informations</legend>
                                                <div class="form-group row">
                                                    <label class="col-3 col-form-label">Quantité <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <input
                                                            class="form-control"
                                                            type="number"
                                                            min="0"
                                                            v-model="detail.quantity"
                                                            required>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-3 col-form-label">Prix unitaire <abbr>*</abbr></label>
                                                    <div class="col-6">
                                                        <input
                                                            class="form-control"
                                                            type="text"
                                                            v-model="detail.unitPrice"
                                                            required>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-1 d-flex flex-rox align-items-center" v-if="entryType === 'out'">
                                            <button class="btn btn--color-bookstore"
                                                    @click="addContent"
                                                    :disabled="!detail.placeTo || !detail.quantity || !detail.unitPrice">
                                                <svg-arrow-right />
                                            </button>
                                        </div>
                                        <div class="col-md-5" v-if="entryType === 'out'">
                                            <grapp-simple-table :table-class="'table-striped table-noCellFocus table-hover'"
                                                                :loading-message="'Chargement...'"
                                                                :loading-smaller="true"
                                                                :display-loader="false"
                                                                :order="order"
                                                                :no-result-message="'Liste de transfert vide'"
                                                                :columns="cols"
                                                                :data="detail.components"
                                                                :reactive="false"
                                                                :default-selected="detail.components ? detail.components[0] : null"
                                                                :actions="['edit', 'remove']"
                                                                :actions-width="'20%'"
                                                                :actions-label="'actions'"
                                                                :custom-sort="true"
                                                                @action:remove="onRemove"
                                                                @action:edit="onEdit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <notif
                                        type="success"
                                        text="Sauvegarde effectuée"
                                        v-if="success"/>
                                    <notif
                                        type="error"
                                        text="Un problème est survenu durant la sauvegarde"
                                        :message="this.msgError"
                                        v-if="error"/>
                                </div>
                            </div>
                            <div class="row is-animated d-flex justify-content-end">
                                <div class="col-auto" v-if="entryType === 'out'">
                                    <button
                                        v-if="!loading && modification"
                                        class="btn--color-success"
                                        @click="openModal"
                                        :disabled="v$.$error || !detail || (detail.components.length === 0 && detail.goodies.length === 0) ">
                                        <span>Ajouter</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if="loading"
                                        class="btn--color-success"
                                        type="button"
                                        disabled>
                                        <span>Ajout en cours...</span>
                                        <figure class="spin--infinite mr-2">
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6" v-if="entryType === 'out'">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h3 class="card-title">Stock</h3>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <table
                                        ref="table"
                                        class="table table-striped table-hover table-handyRows"></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="entryType === 'out' ? 'col-md-6' : 'col-md-12'">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h3 class="card-title">Transfert</h3>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <grapp-simple-table :table-class="'table-striped table-noCellFocus table-hover'"
                                                        :loading-message="'Chargement...'"
                                                        :loading-smaller="true"
                                                        :display-loader="false"
                                                        :order="order"
                                                        :no-result-message="'Aucun transfert'"
                                                        :columns="entryType === 'out' ? transfersColsOut : transfersColsIn"
                                                        :data="transfers"
                                                        :reactive="false"
                                                        :default-selected="null"
                                                        :actions="['show']"
                                                        :actions-width="'10%'"
                                                        :actions-label="'actions'"
                                                        :custom-sort="true"
                                                        @action:show="onshow" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <grapp-modal v-model:show="showModal"
                         :title="'Transfert de stock'"
                         :type="'warning'"
                         :content="'Êtes-vous sûr de vouloir valider le transfert ?<br/>Les produits seront aussitôt destockés.'"
                         :confirm-label="'Valider'"
                         :background-svg="backgroundSvg"
                         :background-svg-flip="'horizontal'"
                         :error-message="'Petit problème je crois !'"
                         :isErrorOnBottom="true"
                         @cancel="onCancel"
                         @confirm="onConfirm" />
            <grapp-modal v-model:show="showModalValidate"
                         :title="'Transfert de stock'"
                         :type="'warning'"
                         :content="'Êtes-vous sûr de vouloir valider le transfert ?<br/>Les produits seront aussitôt ajoutés au stock.'"
                         :confirm-label="'Valider'"
                         :background-svg="backgroundSvg"
                         :background-svg-flip="'horizontal'"
                         :error-message="'Petit problème je crois !'"
                         :isErrorOnBottom="true"
                         @cancel="onCancelTransfer"
                         @confirm="onValidateTransfer" />
            <!-- Modal vue transfert -->
            <modal title="Transfert" :display="showModalTransfer" @close="closeModalTransfert" >
                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <grapp-simple-table :table-class="'table-striped table-noCellFocus table-hover'"
                                                :loading-message="'Chargement...'"
                                                :loading-smaller="true"
                                                :display-loader="false"
                                                :order="order"
                                                :no-result-message="'Aucun transfert'"
                                                :columns="entryType === 'out' ? transfersViewColsOut : transfersViewColsIn"
                                                :data="view.transfer"
                                                :reactive="false"
                                                :default-selected="null"
                                                :custom-sort="true"
                                                @action:changeQuantity="onChangeQuantity"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-end">
                    <button type="button" class="btn--color-danger" @click="closeModalTransfert" v-if="entryType === 'out'">
                        <span>Fermer</span>
                        <svg-close />
                    </button>
                    <button type="button" class="btn--color-success" @click="openModalValidateTransfert" v-if="entryType === 'in' && this.view.status === 'sent'">
                        <span>Valider</span>
                        <svg-check />
                    </button>
                </div>
            </modal>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-buttons/js/buttons.html5';
import { useAuthStore } from '@/stores/auth.store';
import { DateTime } from 'luxon';
import { useLoader } from '@/stores/loader';
import VueMultiselect from 'vue-multiselect';
import { useTransferStore } from '@/stores/transfert.store';
import grappSimpleTable from 'vue-grapp-components/src/components/SimpleTable.vue';
import grappModal from 'vue-grapp-components/src/components/Modal.vue';
import svgs from '../../_svg/svgComponents';
import modal from '../../components/Modal.vue';
import $modal from 'vue-grapp-components/src/utils/modal';

export default {
    name: 'StockTransfer',
    components: {
        ...svgComponent,
        notif,
        VueMultiselect,
        grappSimpleTable,
        grappModal,
        modal
    },
    data() {
        return {
            detail: null,
            view: null,
            component: {
                componentTypeId: null,
                brandId: null,
                modelId: null,
                colorId: null,
                componentId: null },
            goodie: null,
            allComponents: [],
            componentTypes: [],
            colors: [],
            brands: [],
            productTypes: [],
            components: [],
            models: [],
            goodiesTypes: [],
            genericColors: [],
            transfers: [],
            places: [],
            stocks: [],
            allStocks: [],
            goodies:[],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            quantity: null,
            quantityReceived: null,
            types: [
                {
                    value: 'out',
                    label: 'Sortie',
                    class: 'text-info',
                },
                {
                    value: 'in',
                    label: 'Entrée',
                    class: 'text-info',
                },
            ],
            datas: [
                {
                    value: 'composant',
                    label: 'Composant',
                    class: 'text-info',
                },
                {
                    value: 'goodies',
                    label: 'Goodies',
                    class: 'text-info',
                },
            ],
            search: [
                {
                    value: 'name',
                    label: 'Par nom',
                    class: 'text-info',
                },
                {
                    value: 'filter',
                    label: 'Par filtres',
                    class: 'text-info',
                },
            ],
            entryType: 'out',
            entryData:'composant',
            entrySearch: null,
            columns: [
                {
                    data: 'stockId',
                    title: 'Id',
                    render: function(data) {
                        return data ? '<a href="/admin/stock/' + data + '">' + data + '</a>' : '';
                    }
                },{
                    data: 'ugs',
                    title: 'UGS'
                }, {
                    data: 'name',
                    title: 'Nom',
                }, {
                    data: 'quantity',
                    title: 'Quantité',
                }, {
                    data: 'supplierPrice',
                    title: 'Prix fournisseur',
                },{
                    data: 'updatedDate',
                    title: 'Mise à jour',
                    render: (data) => {
                        return data ? DateTime.fromISO(data, { setZone: true }).toFormat('f') : null;
                    }
                }
            ],
            suppliers: [
                { id:'MOBILAX', value:'MOBILAX' },
                { id:'UTOPYA', value:'UTOPYA' },
                { id:'EBAY', value:'EBAY' },
                { id:'MACINFO', value:'MACINFO' },
                { id:'CRD', value:'CRD' },
                { id:'AUTRE', value:'AUTRE' }
            ],
            table: null,
            lecture: false,
            modification: false,
            create: false,
            cols: [
                {
                    title: 'Quantité',
                    data: 'quantitySent',
                    width: '10%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Nom',
                    data: 'name',
                    width: '50%',
                    sortable: true,
                    orderData: 1
                },
                {
                    title: 'Prix',
                    data: 'unitPrice',
                    width: '10%',
                    sortable: true,
                    orderData: 2
                },
            ],
            transfersViewColsOut: [
                {
                    title: 'Quantité',
                    data: 'quantitySent',
                    width: '10%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Nom',
                    data: 'name',
                    width: '50%',
                    sortable: true,
                    orderData: 1
                },
                {
                    title: 'Prix',
                    data: 'unitPrice',
                    width: '10%',
                    sortable: true,
                    orderData: 2
                },
            ],
            transfersViewColsIn: [
                {
                    title: 'Quantité envoyé',
                    data: 'quantitySent',
                    width: '10%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Quantité reçue',
                    data: 'quantityReceived',
                    width: '10%',
                    sortable: true,
                    orderData: 1,
                    type: 'input',
                    input: {
                        classes: 'text-center',
                        method: 'changeQuantity',
                        value: this.quantityReceived
                    },
                },
                {
                    title: 'Nom',
                    data: 'name',
                    width: '50%',
                    sortable: true,
                    orderData: 2
                },
                {
                    title: 'Prix',
                    data: 'unitPrice',
                    width: '10%',
                    sortable: true,
                    orderData: 3
                },
            ],
            transfersViewColsInReceived: [
                {
                    title: 'Quantité envoyé',
                    data: 'quantitySent',
                    width: '10%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Quantité reçue',
                    data: 'quantityReceived',
                    width: '10%',
                    sortable: true,
                    orderData: 1
                },
                {
                    title: 'Nom',
                    data: 'name',
                    width: '50%',
                    sortable: true,
                    orderData: 2
                },
                {
                    title: 'Prix',
                    data: 'unitPrice',
                    width: '10%',
                    sortable: true,
                    orderData: 3
                },
            ],
            transfersColsOut: [
                {
                    title: 'Vers',
                    data: 'placeToName',
                    width: '20%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Créer le',
                    data: 'createdDate',
                    width: '30%',
                    sortable: true,
                    orderData: 1,
                    type: 'text',
                    render: (data) => {
                        return data ? DateTime.fromISO(data, { setZone: true }).toFormat('f') : null;
                    }
                },
                {
                    title: 'Par',
                    data: 'userSent',
                    width: '20%',
                    sortable: true,
                    orderData: 2
                },
                {
                    title: 'Statut',
                    data: 'status',
                    width: '20%',
                    sortable: true,
                    orderData: 3,
                    render: (data) => {
                        return data === 'sent' ? 'Envoyé' : 'Reçu';
                    }
                }
            ],
            transfersColsIn: [
                {
                    title: 'Depuis',
                    data: 'placeFromName',
                    width: '20%',
                    sortable: true,
                    orderData: 0
                },
                {
                    title: 'Créer le',
                    data: 'createdDate',
                    width: '30%',
                    sortable: true,
                    orderData: 1,
                    type: 'text',
                    render: (data) => {
                        return data ? DateTime.fromISO(data, { setZone: true }).toFormat('f') : null;
                    }
                },
                {
                    title: 'Par',
                    data: 'userSent',
                    width: '20%',
                    sortable: true,
                    orderData: 2
                },
                {
                    title: 'Statut',
                    data: 'status',
                    width: '20%',
                    sortable: true,
                    orderData: 3,
                    render: (data) => {
                        return data === 'sent' ? 'Envoyé' : 'Reçu';
                    }
                }
            ],
            order: [[1, 'asc']],
            showModal: false,
            showModalValidate: false,
            showModalTransfer: false,
            backgroundSvg: svgs.svgAlertTriangle.raw,
            filter:{
                productTypeId: null,
                brandId: null,
                modelId: null,
                componentTypeId: null,
                colordId: null
            }
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Transfert de stock', 'Modification');
        this.lecture = await this.authStore.moduleRight('Transfert de stock', 'Lecture');
        // this.places = await this.authStore.getUser.places;
        // this.detail.place = this.places[0].id;
        this.detail = this.transfer.getTransfer;
        await this.getPlaces();
        this.entrySearch = 'filter';
        // await this.getDatas();
        // await this.getTransfers();

    },
    watch: {
        'filter.productTypeId' : async function (val, oldVal) {
            if (val !== oldVal && this.filter && this.filter.brandId && this.entrySearch === 'filter')
                await this.getModels();
            if (val === undefined) {
                this.filter = {
                    productTypeId: null,
                    brandId: null,
                    modelId: null,
                    componentTypeId: null,
                    colordId: null
                };
                this.stocks = this.allStocks;
            }
        },
        'filter.brandId': async function (val, oldVal) {
            if(val !== oldVal && val !== undefined && this.filter && this.entrySearch === 'filter') {
                await this.getModels();
                this.stocks = this.allStocks.reduce((curr,comp) => {
                    if (this.filter.componentTypeId) {
                        if(comp.brandId ===  this.filter.brandId && comp.componentTypeId === this.filter.componentTypeId)
                            curr.push(comp);
                    }else {
                        if(comp.brandId ===  this.filter.brandId)
                            curr.push(comp);
                    }
                    return curr;
                }, []);
            }
            if (val === undefined) {
                this.stocks = this.allStocks;
                this.models = [];
                this.filter.modelId = null;
                if (this.filter.componentTypeId) {
                    this.stocks = this.allStocks.reduce((curr, comp) => {
                        if(comp.componentTypeId === this.filter.componentTypeId)
                            curr.push(comp);
                        return curr;
                    }, []);
                }
            }
        },
        'filter.modelId' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined && this.entrySearch === 'filter')
                this.stocks = this.allStocks.reduce((curr,comp) => {
                    if(comp.models.find(m => m.modelId === this.filter.modelId))
                        curr.push(comp);
                    return curr;
                }, []);
            if (val === undefined) {
                this.stocks = this.allStocks;
                if (this.filter.brandId || this.filter.componentTypeId) {
                    this.stocks = this.allStocks.reduce((curr, comp) => {
                        if (this.filter.brandId && this.filter.componentTypeId) {
                            if (comp.brandId === this.filter.brandId && comp.componentTypeId === this.filter.componentTypeId)
                                curr.push(comp);
                        } else if (this.filter.brandId) {
                            if (comp.brandId === this.filter.brandId)
                                curr.push(comp);
                        } else {
                            if(comp.componentTypeId === this.filter.componentTypeId)
                                curr.push(comp);
                        }
                        return curr;
                    }, []);
                }
            }
        },
        'filter.componentTypeId' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined && this.filter && this.entrySearch === 'filter') {
                this.stocks = this.allStocks.reduce((curr, comp) => {
                    if (this.filter.modelId) {
                        if(comp.models.find(m => m.modelId === this.filter.modelId) && comp.componentTypeId === this.filter.componentTypeId) {
                            curr.push(comp);
                        }
                    } else if (this.filter.brandId) {
                        if (comp.brandId === this.filter.brandId && comp.componentTypeId === this.filter.componentTypeId) {
                            curr.push(comp);
                        }
                    } else {
                        if (comp.componentTypeId === this.filter.componentTypeId)
                            curr.push(comp);
                    }
                    return curr;
                }, []);
            }
            if (val === undefined) {
                this.stocks = this.allStocks;
                if (this.filter.brandId || this.filter.modelId) {
                    this.stocks = this.allStocks.reduce((curr, comp) => {
                        if (this.filter.modelId) {
                            if(comp.models.find(m => m.modelId === this.filter.modelId))
                                curr.push(comp);
                        } else {
                            if(comp.brandId === this.filter.brandId)
                                curr.push(comp);
                        }
                        return curr;
                    }, []);
                }
            }
        },
        'filter.colorId' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined && this.filter && this.filter.modelId && this.entrySearch === 'filter')
                this.stocks = this.stocks.reduce((curr,comp) => {
                    if(comp.colorId ===  this.filter.colorId)
                        curr.push(comp);
                    return curr;
                }, []);
        },
        'component.componentId' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined) {
                this.displayStock();
                // this.detail.quantity = this.component.quantity;
            }
        },
        'detail.placeFrom' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined && val !== null) {
                this.transfer.setPlaceFrom(val);
                if (this.entryData === 'composant') {
                    await this.getStockComponentsByPlace();
                } else {
                    await this.getStockGoodiesByPlace();
                }
                await this.getTransfers();
            }
        },
        'detail.placeTo' : async function (val, oldVal) {
            if (val !== oldVal && val !== undefined && val !== null) {
                this.transfer.setPlaceTo(val);
                await this.getTransfers();
            }
        },
        'entryType': async function(val, oldVal) {
            if(val !== oldVal && val === 'out') {
                this.detail.placeTo = null;
            }else{
                this.detail.placeFrom = null;
            }
            await this.getPlaces();
        },
        'entryData': async function(val, oldVal) {
            if(val !== oldVal) {
                await this.getDatas();
            }
        },
        'entrySearch': async function(val, oldVal) {
            if(val !== oldVal) {
                this.component = {
                    componentTypeId: null,
                    brandId: null,
                    modelId: null,
                    colorId: null,
                    componentId: null
                };
                this.detail.quantity = null;
                this.detail.unitPrice = null;

                await this.getDatas();
            }
        },
        'goodie.goodiesTypeId' : async function(val, oldVal) {
            if(val !== oldVal && this.goodie.sign)
                await this.getGoodies();
        },
        'goodie.sign' : async function(val, oldVal) {
            if(val !== oldVal && this.goodie.goodiesTypeId)
                await this.getGoodies();
        },
        'goodie.gender' : async function(val, oldVal) {
            if(val !== oldVal && this.goodie.goodiesTypeId && this.goodie.sign)
                await this.getGoodies();
        },
        'goodie.size' : async function(val, oldVal) {
            if(val !== oldVal && this.goodie.goodiesTypeId && this.goodie.sign)
                await this.getGoodies();
        },
        'goodie.genericColorId' : async function(val, oldVal) {
            if(val !== oldVal && this.goodie.goodiesTypeId && this.goodie.sign)
                await this.getGoodies();
        }
    },
    methods: {
        goTo(id) {
            this.$router.push(`${id}`);
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['6', '999', '3'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id === '2') {
                    if(!this.transfer.getTransfer.placeFrom)
                        this.detail.placeFrom = this.entryType === 'out' ? '2' : null;
                    if(!this.transfer.getTransfer.placeTo)
                        this.detail.placeTo = this.entryType === 'in' ? '2' : null;
                } else {
                    this.places = this.authStore.getUser.places;
                    this.places.map(p => {
                        p.placeId = p.id;
                    });
                    if(!this.transfer.getTransfer.placeFrom)
                        this.detail.placeFrom = this.entryType === 'out' ? this.places[0].id : null;
                    if(!this.transfer.getTransfer.placeTo)
                        this.detail.placeTo = this.entryType === 'in' ? this.places[0].id : null;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/models/brand/${this.filter.brandId}/productType/${this.filter.productTypeId}`,
                );
                this.models = res.data.filter(m => m.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getComponentTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/componentType`,
                );
                this.componentTypes = res.data.filter(ct => ct.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data.filter(b => b.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getGoodiesTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/goodiesType`,
                );
                this.goodiesTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGenericColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/genericColor`,
                );
                this.genericColors = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGoodies() {
            this.detail.goodies.sign.replace('&', '%26');
            let url = `${window.location.origin}/api/crd/goodies/query?goodiesTypeId=${this.detail.goodies.goodiesTypeId}&sign=${this.detail.goodies.sign}`;
            if(this.detail.goodies.gender) url+= `&gender=${this.detail.goodies.gender}`;
            if(this.detail.goodies.size) url+= `&size=${this.detail.goodies.size}`;
            if(this.detail.goodies.genericColorId) url+= `&genericColorId=${this.detail.goodies.genericColorId}`;
            try {
                const res = await this.axios.get(url);
                this.goodies = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getDatas() {
            try {
                this.loader.show('load');
                if (this.entryData === 'composant') {
                    if(this.entrySearch === 'filter') {
                        await this.getBrands();
                        await this.getProductTypes();
                        await this.getComponentTypes();
                        await this.getColors();
                    }
                    // else {
                    //     await this.getAllComponents();
                    // }
                    // if(this.detail && this.detail.placeFrom && this.stocks.length === 0 )
                    //     await this.getStockComponentsByPlace();
                } else {
                    await this.getGoodiesTypes();
                    await this.getGenericColors();
                    await this.getStockGoodiesByPlace();
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async getTransfers() {
            let place = this.entryType === 'out' ? this.detail.placeFrom : this.detail.placeTo;
            try {
                if(place) {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/stock/transfers/type/${this.entryType}/place/${place}`,
                    );
                    this.transfers = res.data;
                }else {
                    this.transfers = [];
                }

            } catch (err) {
                console.log(err);
            }
        },
        addContent() {
            if(this.entryData === 'composant') {
                this.component.quantitySent = this.detail.quantity;
                this.component.unitPrice = this.detail.unitPrice;
                let ind = this.detail.components.findIndex(comp => comp.componentId === this.component.componentId);
                if(ind > -1) {
                    this.detail.components[ind] = this.component;
                }else {
                    this.detail.components.push(this.component);
                }

                this.detail.quantity = null;
                this.detail.unitPrice = null;
                this.component = null;
                // this.transfer.addComponent(this.component);
            }else {
                this.detail.goodies.push(this.goodie);
                // this.transfer.addGoodie(this.goodie);
            }
        },
        onEdit(row) {
            this.component = row;
            this.detail.quantity = row.quantitySent;
            this.detail.unitPrice = row.unitPrice;
        },
        onRemove(row) {
            let ind = this.detail.components.findIndex(comp => comp.componentId === row.componentId);
            this.detail.components.splice(ind, 1);
        },
        onCancel({ error, close }) {
            close();
        },
        async onConfirm({ error, close }) {
            // simulate async request
            setTimeout(() => {
                // error(); // using :error-message from component declaration (default)
                close(); // or close the modal once request is over and success
            }, 2000);
            await this.submit();
        },
        openModal() {
            this.showModal = true;
        },
        onshow(row) {
            console.log(row);
            if(this.entryData === 'composant') {
                this.view = { transfer: row.components, transferId: row.transferId, placeTo: row.placeTo, placeFrom: row.placeFrom,
                    status: row.status };
            }
            // this.view.transfer.map(t => t.quantityReceived = t.quantitySent);
            this.showModalTransfer = true;
        },
        closeModalTransfert() {
            this.showModalTransfer = false;
        },
        onCancelTransfer({ error, close }) {
            close();
            this.showModalTransfer = true;
        },
        openModalValidateTransfert() {
            this.showModalTransfer = false;
            this.showModalValidate = true;
            // console.log(this.quantityReceived);
        },
        async onValidateTransfer({ error, close }) {
            // simulate async request
            setTimeout(() => {
                // error(); // using :error-message from component declaration (default)
                close(); // or close the modal once request is over and success
            }, 2000);
            let url = `${window.location.origin}/api/crd/stock/transfer`;
            let data = {};
            data.components = this.entryData === 'composant' ? this.view.transfer : [];
            data.goodies = this.entryData === 'goodies' ? this.view.transfer : [];
            data.type = 'in';
            data.transferId = this.view.transferId;
            data.placeFrom = this.view.placeFrom;
            data.placeTo = this.view.placeTo;
            try {
                await this.axios.post(url, data);
                this.success = true;
                await this.getTransfers();
            }catch (err) {
                this.error = true;
                this.loading = false;
                this.msgError = err.response.data.message;
            }
        },
        async onChangeQuantity(row) {
            // console.log(row);
            const input = await $modal.input({
                title: 'Quantité reçue',
                keyboardType: 'money',
                keyboardValue: row.quantitySent
            });
            row.quantityReceived = input ? input : row.quantityReceived;
        },
        async submit() {
            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;
                let url = `${window.location.origin}/api/crd/stock/transfer`;
                let data = {};
                data.components = this.entryData === 'composant' ? this.detail.components : [];
                data.goodies = this.entryData === 'goodies' ? this.detail.goodies : [];
                data.type = this.detail.type;
                data.placeTo = this.detail.placeTo;
                data.placeFrom = this.detail.placeFrom;
                try {
                    await this.axios.post(url, data);
                    this.success = true;
                    this.loading = false;
                    this.component = null;
                    this.goodie = null;
                    this.detail.placeTo = null;
                    this.detail.quantity = null;
                    this.detail.unitPrice = null;
                    this.detail.components = [];
                    this.detail.goodies = [];
                    this.filter = {
                        productTypeId: null,
                        brandId: null,
                        modelId: null,
                        componentTypeId: null,
                        colordId: null
                    };
                    await this.getTransfers();
                    this.success = true;
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
        async getStockComponentsByPlace() {
            try {
                this.loader.show('load');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/components/place/${this.detail.placeFrom}`,
                );
                this.stocks = res.data.filter(d => d.quantity > 0).sort((a, b) => b.quantity - a.quantity);
                this.allStocks = JSON.parse(JSON.stringify(this.stocks));
                if(this.table) {
                    this.table.clear();
                    this.table.destroy();
                }
                this.table = this.initDt(this.columns, this.stocks);
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async getStockGoodiesByPlace() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/goodies/place/${this.detail.placeFrom}`,
                );
                this.stocks = res.data;
                if(this.table) {
                    this.table.clear();
                    this.table.destroy();
                }
                this.table = this.initDt(this.columns, this.stocks);
            } catch (err) {
                console.log(err);
            }
        },
        displayStock() {
            let result;
            if(this.entryData === 'composant' && this.component) {
                result = this.stocks.find(s => s.componentId === this.component.componentId);
                this.detail.unitPrice = this.component.supplierPrice || this.component.price;
            }else if(this.goodie) {
                result = this.stocks.find(s => s.goodiesId === this.goodie.goodiesId);
            }
            this.detail.quantity = result === undefined ? null : (result.quantitySent || result.quantity);
            this.quantity = result === undefined ? null : result.quantity;
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                ordering: true,
                info: false,
                rowReorder: {
                    enabled: true
                },
                filter: false,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json'
                },
                paging: false,
                dom: 'ft',
                order: [[3, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        const cell = vm.table.cell(this).data();
                        if (row && row.stockId !== cell) {
                            console.log(row);
                            vm.detail.placeFrom = row.placeId;
                            if(vm.entryData === 'composant') {
                                vm.component = row;
                                vm.detail.quantity = row.quantity;
                                vm.detail.unitPrice = row.supplierPrice || row.price;
                            }else{
                                vm.goodie = row;
                            }
                        }
                        window.scrollTo(0,0);
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        loader: useLoader(),
        transfer: useTransferStore()
    }),
    validations() {
        let base = {
        };
        return base;
    },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 50% !important;
    }
}

</style>
